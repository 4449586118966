import { useCallback, useMemo } from 'react'
import { useTranslation } from '../../../translation'

// Table
import { CellRendererWrapper } from '../components/CellRenderer/CellRendererWrapper'

// Utils
import { getAggFunctionKey } from '../utils/aggFunctions'
import { getCellClass, getCellStyle, useCellClassRules } from '../utils/stylingUtils'
import {
	getComparator,
	getFilter,
	getFilterParams,
	getFilterValue,
	getQuickFilterText,
	getTooltipValueGetter,
	getValueFormatter,
	suppressKeyboardEvent,
} from '../utils/colDefUtils'
import { isCellRendererNeeded } from '../utils/isCellRendererNeeded'

// Enums and Interfaces
import type { ICellRendererProps, IColumnDef, ITableBase, TData } from '../Table.types'
import { e_RenderType } from '../Table.types'
import type { ColDef, ColGroupDef, GridApi } from '@ag-grid-community/core'
import { e_Interpretation } from '../enums/e_Interpretation'
import { e_DataType } from '../enums/e_DataType'
import { e_FilterOperator } from '../components/CellRenderer/cellRenderers/CellRendererDropdown'
import type { ITableColumnHeaderProps } from '../components/ColumnHeader/ColumnHeader'
import { getExcelStyleId } from '../utils/excelStyles'
import { isAvatarControl, isCheckMarkControl } from '../components/CellRenderer/cellRendererUtils'
import { readColumnStateFromLocalStorage } from '../utils/storageUtils'
import { e_TableColumnSummaryFunction } from '../enums/e_TableColumnSummaryFunction'
import type { MenuItem } from '../../Menu'
import { CellRendererControl } from '../components/CellRenderer/CellRendererControl'
import { e_DateFormat } from '../enums/e_DateFormat'
import type { e_BooleanFormat } from '../enums/e_BooleanFormat'

interface IColDefTableProps {
	id: ITableBase['id']
	columnDefs: NonNullable<ITableBase['columnDefs']>
	onDelete: ITableBase['onDelete']
	contextMenuButton: ITableBase['contextMenuButton']
	cellEditingProps: ITableBase['cellEditingProps']
	singleClickEdit: NonNullable<ITableBase['singleClickEdit']>
	disableColumnHeaderMenu: NonNullable<ITableBase['disableColumnHeaderMenu']>
	disableRowClickSelection: NonNullable<ITableBase['disableRowClickSelection']>
	compact: NonNullable<ITableBase['compact']>
	masterDetail: NonNullable<ITableBase['masterDetail']>
	/**
	 * Disables filtering and sorting for the table.
	 */
	disableFilterAndSorting: NonNullable<ITableBase['disableFilterAndSorting']>
	/**
	 * Disables grouping for the table.
	 */
	enableTableGrouping: NonNullable<ITableBase['enableGrouping']>
	/**
	 * Disables filtering for the table.
	 */
	enableTableFiltering: NonNullable<ITableBase['enableFiltering']>
	/**
	 * Disables sorting for the table.
	 */
	enableTableSorting: NonNullable<ITableBase['enableSorting']>
}

export const useColDefs = (
	gridApi: GridApi<TData> | undefined,
	getContextMenuItems:
		| ((id: string, columnId: string, contextMenuCallback: (contextMenuItems: MenuItem[]) => void) => void)
		| undefined,
	tableProps: IColDefTableProps,
	multiSelect = false,
	verticalHeader = false,
	includesFileData = false
) => {
	const {
		id,
		columnDefs,
		contextMenuButton,
		cellEditingProps,
		singleClickEdit,
		disableColumnHeaderMenu,
		disableRowClickSelection,
		compact,
		disableFilterAndSorting,
		enableTableGrouping,
		enableTableFiltering,
		enableTableSorting,
		masterDetail,
	} = tableProps
	const clearCellOnDelete = !tableProps.onDelete

	const cellClassRules = useCellClassRules()

	const { tcvi } = useTranslation()

	const columnState = id ? readColumnStateFromLocalStorage(id)?.columnState : undefined

	const getColumnDef = useCallback(
		(columnDef: IColumnDef, isFirstColumn: boolean): ColDef<TData> | ColGroupDef<TData> => {
			const columnStateInSessionStorage = columnState?.find((cs) => cs.colId === columnDef.field)
			const isFirstLeafColumn = isFirstColumn && !columnDef.children?.length

			const {
				dataType = e_DataType.string,
				enableFilter: isColumnFilterEnabled = true,
				enableGrouping: isColumnGroupingEnabled = true,
				enableSorting: isColumnSortable = true,
				fillVariant = 'default',
				flashCellsOnNewValue = false,
				initialWidth = 'fitToLabelAndContent',
				interpretation = e_Interpretation.string,
				prefixGroup = false,
				renderType = e_RenderType.text,
			} = columnDef

			const isSortable = !disableFilterAndSorting && enableTableSorting && isColumnSortable
			const isFilterable = !disableFilterAndSorting && enableTableFiltering && isColumnFilterEnabled

			const isAvatarCell = isAvatarControl(renderType)
			const isCheckmarkCell = isCheckMarkControl(renderType)
			const hasContextMenuButton = contextMenuButton === 'firstColumn' || contextMenuButton === 'inline'

			const needsCellRenderer = isCellRendererNeeded({
				isGroup: !!columnDef.rowGroup,
				hasCustomCellRenderer: !!columnDef.onRenderCell,
				hasOnClick: !!columnDef.onCellClick,
				hasFileDrag: isFirstColumn && includesFileData,
				renderType: renderType,
				hasIcon: !!columnDef.iconPlacement,
				multiSelect: isFirstColumn && multiSelect,
				editable: columnDef.readOnly === false && columnDef.renderType !== e_RenderType.checkMark,
				hasContextMenu: columnDef.hasContextMenu || hasContextMenuButton,
				interpretation,
				fillVariant,
				rowDataOnCellClickScope: columnDef.rowDataOnCellClickScope,
			})

			const hasSummaryFunction = ![undefined, e_TableColumnSummaryFunction.none].includes(
				columnDef.columnSummaryFunction
			)

			const excelStyleId = getExcelStyleId(columnDef.numberFormat, columnDef.dataType, columnDef.format)

			const cellRendererParams: ICellRendererProps = {
				renderType,
				hasCustomCellRenderer: needsCellRenderer,
				iconPlacement: columnDef.iconPlacement,
				textAlignment: columnDef.textAlignment,
				hasColumnContextMenu: !!columnDef.hasContextMenu,
				lockColumnContextMenu: !!columnDef.lockContextMenu && !!columnDef.hasContextMenu,
				hasContextMenuButton,
				multiSelect,
				isFirstColumn: isFirstLeafColumn,
				getContextMenuItems,
				fillVariant,
				pillWidth: columnDef.pillWidth,
				singleClickEdit,
				clearCellOnDelete: clearCellOnDelete && !!columnDef.allowNull,
				onRenderCell: columnDef.onRenderCell,
				dataType: dataType,
				interpretation,
				formatOnExport: !!columnDef.formatOnExport,
				readOnly: columnDef.readOnly,
				avatarLabel: columnDef.avatarLabel,
				iconName: columnDef.iconName,
				iconColor: columnDef.iconColor,
				selectable: !disableRowClickSelection,
				compact,
				cellEditingProps,
				filterOperator: columnDef.controlProps?.filterOperator ?? e_FilterOperator.startsWith,
				onClick: columnDef.onCellClick,
				format: fallbackToDefaultFormat(columnDef.format, dataType),
				allowNull: columnDef.allowNull,
				isPercentNumber: columnDef.isPercentNumber,
				formatCellValue: columnDef.formatValue,
				getCellDataOnRender: columnDef.getCellDataOnRender,
				cellClassName: columnDef.cellClassName,
				getCellClassNameOnRender: columnDef.getCellClassNameOnRender,
				getCellStyleOnRender: columnDef.getCellStyleOnRender,
				// Store width (number/fitToContent/fitToLabelAndContent) as own property we can validate against
				widthFromProps: initialWidth,

				// Store min/max width as own properties we can revert to
				minWidthFromProps: columnDef.initialMinWidth,
				maxWidthFromProps: columnDef.initialMaxWidth,
				masterDetail,
				prefixGroup,
				rowDataOnCellClickScope: columnDef.rowDataOnCellClickScope ?? 'cell',
			}

			const headerComponentParams: ITableColumnHeaderProps = {
				...columnDef.headerComponentParams,
				disableMenu: disableColumnHeaderMenu,
				multiSelect,
				isVertical: verticalHeader,
				disableFilter: !!columnDef.lockPosition || !isFilterable,
				disableSorting: !!columnDef.lockPosition || !isSortable,
				screenTip: columnDef.screenTip,
			}

			const initialWidthFromStorage =
				columnStateInSessionStorage?.width ?? (typeof initialWidth === 'number' ? initialWidth : undefined)

			const colDef: ColDef<TData> | ColGroupDef<TData> = {
				autoHeight: columnDef.autoHeight,
				cellRendererParams,
				headerComponentParams,
				colId: columnDef.field,
				field: columnDef.field,
				suppressCellFlash: !flashCellsOnNewValue,
				headerName: columnDef.headerName,
				hide: columnDef.hide || columnDef.rowGroup,
				menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
				aggFunc: getAggFunctionKey(columnDef.columnSummaryFunction, columnDef.dataType),
				initialSort: columnDef.sort,
				initialSortIndex: columnDef.sortIndex,
				rowGroup: columnDef.rowGroup,
				rowGroupIndex: columnDef.rowGroupIndex,
				enableRowGroup: isColumnGroupingEnabled && enableTableGrouping,
				pinned: columnDef.pinned,
				lockPosition: columnDef.lockPosition,
				lockVisible: !!columnDef.lockPosition,
				resizable: !columnDef.lockPosition,
				sortable: !columnDef.lockPosition && isSortable,
				filter: isFilterable ? 'agMultiColumnFilter' : false,
				filterParams: isFilterable
					? getFilterParams(columnDef.dataType, interpretation, isCheckmarkCell, hasSummaryFunction, tcvi)
					: undefined,
				filterValueGetter: (params) => {
					const cellData = params.data?.[columnDef.field]
					if (!cellData) {
						return null
					}
					if (columnDef.dataType === e_DataType.date || columnDef.dataType === e_DataType.dateTime) {
						return cellData
					}
					return getFilterValue(cellData, getFilter(columnDef.dataType, interpretation))
				},
				width: typeof columnDef.width === 'number' ? columnDef.width : undefined,
				initialWidth: initialWidthFromStorage,
				maxWidth: !columnStateInSessionStorage?.width ? columnDef.initialMaxWidth : undefined,
				minWidth: !columnStateInSessionStorage?.width ? columnDef.initialMinWidth : undefined,
				comparator: getComparator(columnDef.dataType),
				tooltipValueGetter: getTooltipValueGetter(
					isAvatarCell,
					isCheckmarkCell,
					columnDef.getTooltip,
					columnDef.cellScreenTip,
					tcvi
				),
				valueFormatter: getValueFormatter(isCheckmarkCell, hasSummaryFunction, tcvi),
				cellRenderer: getCellRenderer(masterDetail, isFirstColumn, needsCellRenderer),
				cellEditor: CellRendererControl,
				cellEditorParams: cellRendererParams,
				editable: columnDef.readOnly === false && columnDef.renderType !== e_RenderType.checkMark,
				cellStyle: getCellStyle(columnDef.textAlignment, columnDef.getCellStyleOnRender),
				cellClassRules,
				cellClass: getCellClass(columnDef.cellClassName, excelStyleId, columnDef.getCellClassNameOnRender),
				suppressKeyboardEvent,
				getQuickFilterText: getQuickFilterText,
				children: columnDef.children?.map((child, index) => ({
					...getColumnDef(child, isFirstColumn && index === 0),
				})),
				// ag-grid sets cellDataType to object automatically if it finds objects in data.
				// Data to the table is often delayed which makes cellDataType undeterministic.
				// Setting it manually makes it easier to understand what happens.
				// Our types require our data to always be objects. If this changes we need to update this.
				cellDataType: 'object',
			}

			return colDef
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	)

	return useMemo(() => {
		if (contextMenuButton === 'lastColumn') {
			columnDefs.push({
				field: 'ContextMenuLastColumn',
				renderType: e_RenderType.none,
				initialWidth: 26,
				textAlignment: 'center',
				headerComponentParams: { disableMenu: true },
				lockPosition: 'right',
			})
		}

		const newColumnDefs = columnDefs.map((colDef, index) => getColumnDef(colDef, index === 0))
		const currentColumnDefs = gridApi?.getColumnDefs()
		if (!currentColumnDefs) {
			return { currentColumnDefs: newColumnDefs, originalColumnDefs: newColumnDefs }
		}
		updateColumnDefsWithValues(newColumnDefs, currentColumnDefs)
		const currentColumnIds = new Set(currentColumnDefs.map(getColumnId).filter((v): v is string => v !== undefined))
		const addedColumnDefs = newColumnDefs.filter((colDef) => {
			const colId = getColumnId(colDef)
			return colId && !currentColumnIds.has(colId)
		})
		const newColumnIds = new Set(newColumnDefs.map(getColumnId).filter((v): v is string => v !== undefined))
		const removedColumnDefs = Array.from(currentColumnIds).filter((colId) => {
			return !newColumnIds.has(colId)
		})
		return {
			currentColumnDefs: currentColumnDefs
				.filter((colDef) => {
					const colId = getColumnId(colDef)
					return colId && !removedColumnDefs.includes(colId)
				})
				.concat(addedColumnDefs),
			originalColumnDefs: newColumnDefs,
		}
	}, [contextMenuButton, columnDefs, gridApi, getColumnDef])
}

/**
 * Updates the current column definitions with the new column definitions.
 * Ag-Grid resets a lot of the column definition properties if certain properties are set, so we selectively update the properties we need.
 * We should try to increase the number of properties we update while making sure they don't result in unwanted behavior.
 *
 * @param newColumnDefs new column definitions that can be a new array
 * @param currentColumnDefs  current column definitions that are already in the grid. This should be a reference to the grid's column definitions
 */
function updateColumnDefsWithValues(
	newColumnDefs: (ColDef<TData, any> | ColGroupDef<TData>)[],
	currentColumnDefs: (ColDef<TData, any> | ColGroupDef<TData>)[]
) {
	const newColDefMap = newColumnDefs.reduce(
		(acc, colDef) => {
			if (!('colId' in colDef) || !colDef.colId) {
				return acc
			}
			acc[colDef.colId] = colDef
			return acc
		},
		{} as Record<string, ColDef<TData>>
	)

	const indexesToRemove: number[] = []

	currentColumnDefs.forEach((currentColDef) => {
		const id = getColumnId(currentColDef)
		if (!id) {
			return
		}
		if ('groupId' in currentColDef) {
			currentColDef.headerName = newColDefMap[id]?.headerName
			return
		}
		if (!('colId' in currentColDef) || !currentColDef.colId) {
			return
		}

		const isFirstColumn = currentColDef.cellRendererParams.isFirstColumn

		const newColDef = newColDefMap[currentColDef.colId]
		if (!newColDef) {
			indexesToRemove.push(currentColumnDefs.indexOf(currentColDef))
			return
		}

		currentColDef.cellRendererParams = newColDef.cellRendererParams || {}
		if (isFirstColumn !== undefined) {
			currentColDef.cellRendererParams.isFirstColumn = isFirstColumn
		}
		currentColDef.cellRenderer = newColDef.cellRenderer
		currentColDef.cellClass = newColDef.cellClass

		currentColDef.tooltipValueGetter = newColDef.tooltipValueGetter
		currentColDef.valueFormatter = newColDef.valueFormatter
		currentColDef.filterParams = newColDef.filterParams

		currentColDef.headerComponentParams = newColDef.headerComponentParams

		currentColDef.headerName = newColDef.headerName
	})

	indexesToRemove.forEach((index) => {
		currentColumnDefs.splice(index, 1)
	})

	Object.keys(newColDefMap).forEach((colId, index) => {
		if (currentColumnDefs.some((currentColDef) => 'colId' in currentColDef && currentColDef.colId === colId)) {
			return
		}
		currentColumnDefs.splice(index, 0, newColDefMap[colId])
	})
}

function getCellRenderer(masterDetail: boolean, isFirstColumn: boolean, needsCellRenderer: boolean) {
	if (masterDetail && isFirstColumn) {
		return 'agGroupCellRenderer'
	}
	if (needsCellRenderer) {
		return CellRendererWrapper
	}
}

const getColumnId = (columnDef: ColDef<TData, any> | ColGroupDef<TData>) => {
	if ('colId' in columnDef) {
		return columnDef.colId
	}
	if ('groupId' in columnDef) {
		return columnDef.groupId
	}
	return ''
}

function fallbackToDefaultFormat(format: e_DateFormat | e_BooleanFormat | undefined, dataType: e_DataType) {
	if (format === e_DateFormat.default) {
		return e_DateFormat.shortDate
	}
	if (!format && dataType === e_DataType.date) {
		return e_DateFormat.shortDate
	}
	if (!format && dataType === e_DataType.dateTime) {
		return e_DateFormat.shortDateTime
	}
	return format
}
