import React, { useState, useRef, useLayoutEffect } from 'react'
import className from 'clsx'
import { getTypedThemeUtils } from '../../theming/themingManager'
import { generateTheme } from '../../theming/generateTheme'
import { createStyle } from '../../theming'
import { e_LabelPosition } from '../../enums/e_LabelPosition'
import { e_FieldControlAlignment } from '../../enums/e_FieldControlAlignment'
import type { IFieldGroupStyle, IFieldItemStyle } from '../MasterDetail/types/Field.types'
import { useResizeObserver } from '../../controls/utils/useResizeObserver'
import { type IGridAreaProps } from '../Grid/gridTypes'
import { FieldContainerPropsContextProvider } from './FieldContainerContext'

const webUiTheme = generateTheme()
const { useDynamicThemeVariation } = getTypedThemeUtils<typeof webUiTheme>()

interface IFieldContainer extends IFieldGroupStyle, IFieldItemStyle, IGridAreaProps {
	header?: string
	children?: React.ReactNode | React.ReactNode[]
	groupId: string
	fieldControlAlignment?: e_FieldControlAlignment
	fieldControlWidth?: number
}

const classes = createStyle((theme) => ({
	containerContent: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
	},
	containerHeader: {
		paddingTop: '4px',
		paddingBottom: '4px',
		fontSize: theme.typography.styles.h4.fontSize,
		fontWeight: theme.typography.styles.h4.fontWeight,
	},
}))

const getFlexStyle = (alignment: e_FieldControlAlignment) => {
	switch (alignment) {
		case e_FieldControlAlignment.stretch:
			return 'stretch'
		case e_FieldControlAlignment.left:
			return 'flex-start'
		case e_FieldControlAlignment.right:
			return 'flex-end'
		default:
			return 'stretch'
	}
}
const generateThemeVariation = (alignment: e_FieldControlAlignment, inputControlWidth?: number) => {
	const themeVariation = {
		controls: {
			input: {
				editControlAlignment: getFlexStyle(alignment) ?? 'stretch',
				editControlWidth: inputControlWidth ? `${inputControlWidth}px` : '100%',
			},
		},
	}

	return themeVariation
}
const WIDTH_THRESHOLD = 500
const computeLabelPlacement = (windowWidth: number) =>
	windowWidth < WIDTH_THRESHOLD ? e_LabelPosition.top : e_LabelPosition.left

export const FieldContainer = (props: IFieldContainer) => {
	const { fieldControlAlignment = e_FieldControlAlignment.left, fieldControlWidth } = props

	const containerRef = useRef<HTMLDivElement>(null)

	const [labelPlacement, setLabelPlacement] = useState(e_LabelPosition.left)

	const onResize = () => {
		const placement = computeLabelPlacement(containerRef.current?.clientWidth ?? 0)
		setLabelPlacement(placement)
	}

	useLayoutEffect(onResize, [])
	useResizeObserver(onResize, containerRef)

	const themeVariation = generateThemeVariation(fieldControlAlignment, fieldControlWidth)
	const dynamicStyle = useDynamicThemeVariation(themeVariation)
	const gap = `${props.gap ?? 0}px`
	const padding = `${props.padding ?? 0}px`
	const style = { ...props.style, ...dynamicStyle, padding, gap }

	const propsValue = {
		labelPosition: labelPlacement,
		syncWidthProps: { groupId: props.groupId },
		labelBold: props.labelBold,
	}

	return (
		<FieldContainerPropsContextProvider value={propsValue}>
			<div style={style} className={className(classes.containerContent)} ref={containerRef}>
				{props.header && <div className={classes.containerHeader}>{props.header}</div>}
				{props.children}
			</div>
		</FieldContainerPropsContextProvider>
	)
}
