import { useCallback, useEffect } from 'react'
import {
	e_RenderType,
	type CellData,
	type ICellRendererProps,
	type ITableContext,
	type TData,
	type Value,
} from '../../Table.types'
import { focusNextCell, isCheckMarkControl } from './cellRendererUtils'
import { tDataToRowData } from '../../utils'
import type { ICellRendererParams, IRowNode } from '@ag-grid-community/core'
import { e_Interpretation } from '../../enums/e_Interpretation'
import { useEventListener } from '../../../utils/useEventListener'

export const useCellRendererEventListeners = (
	nodeId: string,
	node: IRowNode<TData>,
	cellData: CellData | null | undefined,
	value: Value,
	editingMode: boolean,
	editable: boolean,
	props: ICellRendererProps & ICellRendererParams<TData, CellData>,
	pushChanges: (value: Value) => void,
	context: ITableContext
) => {
	const isCheckMarkCell = isCheckMarkControl(props.renderType)

	const stopEditingAndFocusNextCell = useCallback(
		(e: KeyboardEvent, key: 'enter' | 'tab') => {
			if (editingMode) {
				pushChanges(value)
			}

			props.api.stopEditing()
			focusNextCell(props.api, props.column, key, e)
		},
		[editingMode, value, props.api, props.column, pushChanges]
	)

	const onEnterPress = useCallback(
		(e: KeyboardEvent) => {
			if (e.ctrlKey) {
				if (!editingMode) {
					if (isCheckMarkCell) {
						pushChanges(!value)
					} else {
						cellData?.onIconClick?.(nodeId)
					}
				}
				const rowData = tDataToRowData(node.data)
				rowData && context.onClick?.(rowData)
				rowData && context.onDblClick?.(rowData)
				e.stopPropagation()

				return
			}
			e.stopPropagation()

			if (!editingMode) {
				const hasClickableAction =
					!!cellData?.onIconClick || [e_Interpretation.email, e_Interpretation.phone].includes(props.interpretation)
				if (hasClickableAction) {
					if (props.interpretation === e_Interpretation.email && !!cellData?.value) {
						window.location.href = `mailto:${cellData?.value as string}`
					} else if (props.interpretation === e_Interpretation.phone && !!cellData?.value) {
						window.open(`tel:${cellData?.value as string}`)
					}
				}
			}
		},
		[editingMode, node.data, context, isCheckMarkCell, pushChanges, value, cellData, nodeId, props.interpretation]
	)

	const onKeyDown = useCallback(
		(e: KeyboardEvent) => {
			switch (e.key) {
				case 'Enter':
					onEnterPress(e)
					break
				case 'ArrowUp':
				case 'ArrowRight':
				case 'ArrowDown':
				case 'ArrowLeft':
					// Prevent AG Grid cell navigation when in editing mode
					editingMode && e.stopPropagation()
					break
				case 'Escape':
					if (editingMode) {
						e.preventDefault()

						props.api.stopEditing()
						props.eGridCell.focus()
					}
					break
				// TODO PBU 27.02.24: This might have to be included some way
				case 'Delete':
					if (editingMode) {
						props.clearCellOnDelete && pushChanges(undefined)
					}
					break
				case 'Tab':
					stopEditingAndFocusNextCell(e, 'tab')
					break
				// TODO PBU 27.02.24:  Check what this is about
				case 'a':
					if (editingMode) {
						e.stopPropagation()
					}
					break
				case ' ': {
					if (e.ctrlKey) {
						const colKey = props.column?.getColId()
						const rowIndex = props.node.rowIndex
						if (colKey != null && rowIndex != null) {
							props.api.clearFocusedCell()
							props.api.startEditingCell({ colKey, rowIndex })
						}
					}
					break
				}
			}
		},
		[
			editingMode,
			props.node,
			props.api,
			props.eGridCell,
			props.clearCellOnDelete,
			props.column,
			onEnterPress,
			stopEditingAndFocusNextCell,
			pushChanges,
		]
	)

	const onPointerDown = (e: React.PointerEvent) => {
		if (!editable || !editingMode) {
			return
		}

		const isNotInAnchorNode = !props.eGridCell.contains(e.target as Node)

		if (isNotInAnchorNode) {
			props.api.stopEditing()

			if (![e_RenderType.dropdown, e_RenderType.lookup].includes(props.renderType)) {
				pushChanges(value)
			}
		}
	}

	useEventListener('pointerdown', onPointerDown)

	useEffect(() => {
		props.eGridCell.addEventListener('keydown', onKeyDown)
		return () => {
			props.eGridCell.removeEventListener('keydown', onKeyDown)
		}
	}, [props.eGridCell, onKeyDown])
}
