import classNames from 'clsx'
import { createStyle } from '../../../theming'

const classes = createStyle((theme) => {
	return {
		chip: {
			display: 'inline-flex',
			minWidth: 40,
			overflow: 'hidden',
			textDecoration: theme.controls.chip.textDecoration,
			boxShadow: theme.controls.chip.boxShadow,
			background: theme.controls.chip.colors.background,
			color: theme.controls.chip.colors.color,
			borderColor: theme.controls.chip.colors.border,
			padding: theme.controls.chip.padding,
			fontSize: theme.controls.chip.fontSize,
			fontWeight: theme.controls.chip.fontWeight,
			borderRadius: theme.controls.chip.borderRadius,
			'&:not([aria-disabled="true"])': {
				border: `${theme.controls.chip.borderWidth} ${theme.controls.chip.borderStyle} ${theme.controls.chip.colors.border}`,
				'&:focus-visible': {
					borderColor: theme.controls.chip.focusedColors.border,
					borderStyle: theme.controls.chip.focusBorderStyle,
					outlineColor: theme.controls.chip.focusedColors.outline,
					outlineOffset: `calc(0px - ${theme.controls.chip.borderWidth} + ${theme.controls.chip.outlineOffset})`,
					outlineStyle: theme.controls.chip.outlineStyle,
					outlineWidth: theme.controls.chip.outlineWidth,
				},
			},
		},
		clickable: {
			background: theme.controls.chip.colors.background,
			color: theme.controls.chip.colors.color,
			cursor: 'pointer',
			'&:not([aria-disabled="true"])': {
				'&:active': {
					backgroundColor: theme.controls.chip.pressedColors.background,
					color: theme.controls.chip.pressedColors.color,
					borderColor: theme.controls.chip.pressedColors.border,
				},
			},
			'&:hover:not([aria-disabled="true"])': {
				backgroundColor: theme.controls.chip.hoverColors.background,
				color: theme.controls.chip.hoverColors.color,
				borderColor: theme.controls.chip.hoverColors.border,
			},
		},
		checked: {
			backgroundColor: theme.controls.chip.checkedColors.background,
			color: theme.controls.chip.checkedColors.color,
			'&:not([aria-disabled="true"])': {
				borderColor: theme.controls.chip.checkedColors.border,
				'&:active': {
					background: theme.controls.chip.checkedPressedColors.background,
					color: theme.controls.chip.checkedPressedColors.color,
					borderColor: theme.controls.chip.checkedPressedColors.border,
				},
			},
			'&:hover:not([aria-disabled="true"])': {
				background: theme.controls.chip.checkedHoverColors.background,
				color: theme.controls.chip.checkedHoverColors.color,
				borderColor: theme.controls.chip.checkedHoverColors.border,
			},
		},
		collapsed: {
			maxWidth: 96,
		},
		disabled: {
			cursor: 'default',
			pointerEvents: 'none',
			background: theme.controls.chip.disabledColors.background,
			color: theme.controls.chip.disabledColors.color,
			borderColor: theme.controls.chip.disabledColors.border,
		},
		disabledActive: {
			background: theme.controls.chip.disabledColors.background,
			color: theme.controls.chip.disabledColors.color,
			borderColor: theme.controls.chip.disabledColors.border,
		},
		readOnly: {
			background: theme.controls.chip.colors.background,
			color: theme.controls.chip.colors.color,
			borderColor: theme.controls.chip.colors.border,
		},
		readOnlyActive: {
			backgroundColor: theme.controls.chip.checkedColors.background,
			color: theme.controls.chip.checkedColors.color,
			borderColor: theme.controls.chip.checkedColors.border,
		},
		showTextOnly: {
			background: 'transparent',
		},
	}
})

export const useChipClassNames = (
	disabled: boolean | undefined,
	readOnly: boolean | undefined,
	isChecked: boolean | undefined,
	clickable: boolean | undefined,
	showTextOnly: boolean | undefined,
	className: string | undefined,
	isCollapsed: boolean | undefined
) => {
	return classNames(
		classes.chip,
		{
			[classes.clickable]: clickable,
			[classes.checked]: !disabled && !readOnly && isChecked,
			[classes.collapsed]: isCollapsed,
			[classes.disabled]: disabled,
			[classes.disabledActive]: disabled && isChecked,
			[classes.readOnly]: readOnly && !isChecked,
			[classes.readOnlyActive]: readOnly && isChecked,
			[classes.showTextOnly]: showTextOnly,
		},
		className
	)
}
