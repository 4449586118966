import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DetailPanel } from './InternalComponents/DetailPanel'
import { DetailView } from './InternalComponents/DetailView'
import { MasterPanel } from './InternalComponents/MasterPanel'
import { MasterView } from './InternalComponents/MasterView'
import { useWidthChange } from './utils/useWidthChange'

import { CSSTransition, TransitionGroup } from 'react-transition-group'
import type { ISortData } from './types/ISortData'
import { createStyle } from '../../theming'
import type { IDetailFieldDescription, ITableFieldDescription } from './types/IDataPropertyDescription'
import type { IDataWithIdentifier } from './types/IDataWithIdentifier'
import type { IMasterDetailPreakpoint } from './types/IMasterDetailBreakpoint'

import './../../theming/masterdetail.css'
import type { DetailItemSurface } from './types/IDetailItemSurfaceProps'
import type { IActionEntry } from './types/IActionDescription'
import type { IMasterViewColumnItemDescription } from './types/IMasterViewColumnItemDescription'
import { useId } from '../../controls/utils'
import clsx from 'clsx'
import type {
	FormatDataValueEventHandler,
	GetFormattingStylesEventHandler,
	GetIconValueEventHandler,
	IFormattingStyles,
	RenderDataEventHandler,
} from './types/MasterDetailEventHandlers'
import type { e_ListItemLayout } from './types/e_ListItemLayout'
import { e_MasterDetailTargetElement } from './types/e_MasterDetailTargetElement'
import { type IMasterDetailCallbackAPI } from './types/IMasterDetailCallbackAPI'

const classes = createStyle((theme) => ({
	masterdetailsurface: {
		flex: 1,

		overflow: 'hidden',
		display: 'flex',
		alignItems: 'stretch',
		justifyContent: 'stretch',
		position: 'relative',
	},

	masterDetailBorder: {
		border: `1px solid ${theme.colors.panel.border}`,
		borderRadius: '5px',
		boxShadow: theme.shadows.medium,
		background: theme.colors.input.background,
		color: theme.colors.panel.text,
	},

	view: {
		overflow: 'hidden',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
		justifyContent: 'stretch',
		background: 'inherit',
		position: 'absolute',
		left: 0,
		top: 0,
		right: 0,
		bottom: 0,
	},
}))

const standardBreakpoints: IMasterDetailPreakpoint[] = [
	{
		name: 'phone',
		maxWidth: 640,
	},
	{
		name: 'ipad',
		minWidth: 640,
		maxWidth: 1024,
	},
	{
		name: 'pc',
		minWidth: 1024,
	},
]

export interface IMasterDetailSurfaceProps {
	columnDescriptions: ITableFieldDescription[]
	detailFieldDescriptions: IDetailFieldDescription[]

	columnGroups?: IMasterViewColumnItemDescription[]

	data: IDataWithIdentifier[]

	contentName: string | (() => string)
	contentNamePlural: string | (() => string)

	itemName?: string | ((item: IDataWithIdentifier) => string)
	summaryContent?: string | ((item: IDataWithIdentifier) => string)
	avatarContent?: string | ((item: IDataWithIdentifier) => JSX.Element | string)

	listItemTemplate?: e_ListItemLayout

	detailComponent?: DetailItemSurface
	renderDetailComponent?: (
		item: IDataWithIdentifier,
		breakpoint: IMasterDetailPreakpoint
	) => React.ReactNode | undefined
	/** handle an action defined without selection context */
	generalActions?: IActionEntry[]
	/** defined actions applying to a single item. This also activates multiselect mode */
	singleItemActions?: IActionEntry[]
	/** defined actions applying to for items. This also activates multiselect mode */
	multiItemsActions?: IActionEntry[]

	/** handle an action defined without selection context */
	handleGeneralAction?: (actionId: string) => void
	/** handle an action defined for a single item */
	handleSingleItemAction?: (actionId: string, itemId: string) => void
	/** handle an action defined for multiple items */
	handleMultiItemAction?: (actionId: string, itemIds: string[]) => void

	/** Gets an icon name for a specified property
	 * @param {IDataWithIdentifier} item - The item for which an icon is requested
	 * @param {string} propertyId - The id of the property the icon should represent
	 */
	getIconValue?: GetIconValueEventHandler

	/** Formats a value from the data.
	 * Typically the name of an identifier, special date/time formats or a special meaning of a boolean value.
	 * Other known formats (such as date or number formatting should be handled by specifying format explicitly)
	 * The result of the format should typically be a string value but may also return a jsx element to allow for instance a <span> with formatting
	 * If no override is required, return undefined in the callback.
	 * Triggered when enableValueFormatting is set on property descriptions. */
	formatDataValue?: FormatDataValueEventHandler

	/** Callback to enable styling of elements. Common for formatting of cells (/properties) and rows (/objects).
	 * Triggered when enableFormatting is set on property descriptions or enableItemFormatting is set. */
	getFormattingStyles?: GetFormattingStylesEventHandler
	/** Total override of the presentation of the property.
	 * Apply to completely define the content of a cell or field */
	renderData?: RenderDataEventHandler

	id?: string
	dataAttributes?: Record<string, string>
	minHeight?: number
	displayBorder?: boolean

	useZebraStripes?: boolean
	useCompactView?: boolean
	enableItemFormatting?: boolean
	emptyListMessage?: string

	sortData?: ISortData[]
	sortDataChanged?: (sortData: ISortData[]) => void

	selectedItemIds?: string[]
	setSelectedItemIds?: (ids: string[]) => void
	callbackAPI?: React.MutableRefObject<IMasterDetailCallbackAPI | undefined>
	lockSelection?: boolean
	giveFeedback?: (message: string) => void
}

enum e_MasterDetailView {
	master = 'master',
	detail = 'detail',
}

export const DETAIL_VIEW_MASTER_PANEL_WIDTH = 280

export const MasterDetailSurface = (props: IMasterDetailSurfaceProps) => {
	const id = useId('master-detail-surface')
	const container = useRef<HTMLDivElement>(null)

	const detailRef = useRef<HTMLDivElement>(null)
	const masterRef = useRef<HTMLDivElement>(null)
	const masterViewRef = useRef<HTMLDivElement>(null)
	const masterPanelRef = useRef<HTMLDivElement>(null)
	const detailViewRef = useRef<HTMLDivElement>(null)
	const detailPanelRef = useRef<HTMLDivElement>(null)

	const [breakpoint, setBreakpoint] = useState<string>('')

	const [isMultiSelect, setIsMultiSelect] = useState(false)

	const columnDescriptions = props.columnDescriptions
	const detailFieldDescriptions = props.detailFieldDescriptions

	const generalActions = props.generalActions
	const singleItemActions = props.singleItemActions
	const multiItemsActions = props.multiItemsActions

	const handleGeneralAction = props.handleGeneralAction
	const handleSingleItemAction = props.handleSingleItemAction
	const handleMultiItemAction = props.handleMultiItemAction

	const breakpointChanged = useCallback(
		(breakpointName: string) => {
			if (breakpoint === breakpointName) {
				return
			}

			setBreakpoint(breakpointName)
		},
		[breakpoint]
	)

	useWidthChange(container, standardBreakpoints, breakpointChanged)
	const [view, setView] = useState<e_MasterDetailView>(e_MasterDetailView.master)

	const [selectedIds, setSelectedIds] = useState<string[]>([])
	const [activeId, setActiveId] = useState<string | undefined>()

	const [filterText, setFilterText] = useState('')
	const [sortedColumns, setSortedColumns] = useState<ISortData[]>([])

	const sortDataChangedCallback = props.sortDataChanged
	const updateSortedColumns = useMemo(
		() => (sortColumns: ISortData[]) => {
			setSortedColumns(sortColumns)

			if (sortDataChangedCallback) {
				sortDataChangedCallback(sortColumns)
			}
		},
		[sortDataChangedCallback]
	)

	useEffect(() => {
		updateSortedColumns(props.sortData || [])
	}, [props.sortData, updateSortedColumns])

	const updateSelectedIds = (itemIds: string[]) => {
		setSelectedIds(itemIds)
		props.setSelectedItemIds && props.setSelectedItemIds(itemIds)
	}

	useEffect(() => {
		if (props.selectedItemIds) {
			setSelectedIds(props.selectedItemIds)
		}
	}, [props.selectedItemIds])

	const getElementRef = (viewId: string, breakpointId: string) => {
		if (viewId === 'master') {
			return masterRef
		}

		if (viewId === 'detail') {
			return detailRef
		}

		// Function will never arrive down here...

		if (viewId === 'master' && breakpointId === 'pc') {
			return masterViewRef
		}

		if (viewId === 'detail' && breakpointId === 'pc') {
			return detailViewRef
		}

		if (viewId === 'master' && breakpointId === 'phone') {
			return masterPanelRef
		}

		if (viewId === 'detail' && breakpointId === 'phone') {
			return detailPanelRef
		}

		if (viewId === 'master' && breakpointId === 'ipad') {
			return masterViewRef
		}

		if (viewId === 'detail' && breakpointId === 'ipad') {
			return detailPanelRef
		}

		return null
	}

	const activeViewRef = getElementRef(view, breakpoint)

	const getFallbackItemName = (item: IDataWithIdentifier) => {
		return (item['name'] || item['title'] || item['caption'])?.toString()
	}

	const getFallbackAvatarContent = (item: IDataWithIdentifier) => {
		const itemName = getFallbackItemName(item)

		return itemName
			? itemName
					.split(' ')
					.map((part: string) => part[0])
					.join('')
					.substring(0, 2)
					.toLocaleUpperCase()
			: 'X'
	}

	const getFallbackSummaryContent = () => {
		return ''
	}

	const sortData = (data: IDataWithIdentifier[], sortColumns: ISortData[]) => {
		if (sortColumns.length === 0) {
			return data
		}

		return [...data].sort((item1: IDataWithIdentifier, item2: IDataWithIdentifier) => {
			for (let i = 0; i <= sortColumns.length - 1; i++) {
				const sortedColumn = sortColumns[i]

				const sortValue1 = item1[sortedColumn.propertyId]
				const sortValue2 = item2[sortedColumn.propertyId]

				const sortOrder = sortedColumn.order === 'asc' ? 1 : -1

				if (sortValue1 === sortValue2) {
					continue
				}

				if (!sortValue1 && sortValue2) {
					return -1 * sortOrder
				}
				if (sortValue1 && !sortValue2) {
					return 1 * sortOrder
				}

				const compareResult =
					sortValue1!.toString().toLowerCase().localeCompare(sortValue2!.toString().toLowerCase()) * sortOrder

				if (compareResult !== 0) {
					return compareResult
				}
			}

			return 0 // if all column values are otherwise equal
		})
	}

	const filterData = (data: IDataWithIdentifier[], filterText: string, filterColumns: string[]) => {
		if (!filterText) {
			return data
		}

		return data.filter((dataItem) => {
			const columnMatch = filterColumns.find(
				(columnId) => dataItem[columnId] && dataItem[columnId]!.toString().toLowerCase().includes(filterText)
			)

			return columnMatch !== undefined
		})
	}

	const filteredData = useMemo(() => {
		const textFilteredDescriptions = columnDescriptions.filter((propDesc) => !propDesc.omitFromTextFilter)
		const filteredPropertyIds = textFilteredDescriptions.map((filterPropDesc) => filterPropDesc.propertyId)

		const filteredData = filterData(props.data, filterText, filteredPropertyIds)
		const sortedFilteredData = sortData(filteredData, sortedColumns)

		return sortedFilteredData
	}, [props.data, filterText, sortedColumns, columnDescriptions])

	useEffect(() => {
		if (!isMultiSelect && selectedIds.length > 1) {
			updateSelectedIds([selectedIds[0]])
		}
	}, [isMultiSelect, selectedIds])

	const getItemTitle = () => {
		return typeof props.contentName === 'function' ? props.contentName() : props.contentName
	}

	const getPluralTitle = () => {
		return typeof props.contentNamePlural === 'function' ? props.contentNamePlural() : props.contentNamePlural
	}

	const currentBreakpoint = standardBreakpoints.find((item) => item.name === breakpoint)!

	const getFormattingStyles = (
		item: IDataWithIdentifier,
		propertyId: string | undefined,
		targetElement: e_MasterDetailTargetElement | undefined
	): IFormattingStyles | undefined => {
		if (props.getFormattingStyles) {
			return props.getFormattingStyles(item, propertyId, targetElement)
		}

		if (
			targetElement &&
			[
				e_MasterDetailTargetElement.pill,
				e_MasterDetailTargetElement.circle,
				e_MasterDetailTargetElement.avatar,
			].includes(targetElement)
		) {
			return { background: 'silver', text: 'black' }
		}

		return undefined
	}

	const transitionClassnames = useMemo(() => {
		const transitionClasses = breakpoint === 'pc' ? transitionPcClasses : transitionPhoneIPadClasses

		if (view === e_MasterDetailView.detail) {
			return {
				enter: transitionClasses.detailEnter,
				enterActive: transitionClasses.detailEnterActive,
				exit: transitionClasses.masterExit,
				exitActive: transitionClasses.masterExitActive,
			}
		} else {
			return {
				enter: transitionClasses.masterEnter,
				enterActive: transitionClasses.masterEnterActive,
				exit: transitionClasses.detailExit,
				exitActive: transitionClasses.detailExitActive,
			}
		}
	}, [view, breakpoint])

	const showItem = (id: string | undefined) => {
		if (isMultiSelect) {
			return
		}
		setActiveId(id)
		setView(e_MasterDetailView.detail)
		setSelectedIds(id ? [id] : [])
		setIsMultiSelect(false)
	}

	const tryShowItem = (id: string | undefined) => {
		props.lockSelection ? props.giveFeedback?.('You have unsaved changes') : showItem(id)
	}

	const showTable = () => {
		setView(e_MasterDetailView.master)
		setIsMultiSelect(false)
	}

	const tryShowTable = () => {
		props.lockSelection ? props.giveFeedback?.('You have unsaved changes') : showTable()
	}

	useEffect(() => {
		if (props.callbackAPI) {
			props.callbackAPI.current = {
				showTable,
				showItem,
			}
		}
	}, [activeId])

	return (
		<div
			id={props.id}
			{...props.dataAttributes}
			className={clsx(classes.masterdetailsurface, props.displayBorder && classes.masterDetailBorder)}
			ref={container}
			//style={{ minHeight: props.minHeight }}
		>
			<TransitionGroup
				childFactory={(child) =>
					React.cloneElement(child, {
						classNames: transitionClassnames,
					})
				}
				component={null}
			>
				<CSSTransition
					key={view}
					nodeRef={activeViewRef}
					addEndListener={(done) => {
						activeViewRef &&
							activeViewRef.current &&
							activeViewRef.current.addEventListener('transitionend', done, false)
					}}
					classNames={transitionClassnames}
				>
					<>
						{view === e_MasterDetailView.master && (
							<div className={classes.view} ref={masterRef} key="master">
								{view === e_MasterDetailView.master && (breakpoint === 'pc' || breakpoint === 'ipad') && (
									<MasterView
										title={getPluralTitle()}
										items={filteredData}
										id={`${id}-table`}
										selectedIds={selectedIds}
										setSelectedIds={setSelectedIds}
										setActiveId={tryShowItem}
										activeId={activeId}
										ref={masterViewRef}
										columnDescriptions={columnDescriptions}
										columnGroups={props.columnGroups}
										sortedColumns={sortedColumns}
										sortColumns={updateSortedColumns}
										filterText={filterText}
										setFilterText={setFilterText}
										isMultiSelect={isMultiSelect}
										setIsMultiSelect={setIsMultiSelect}
										itemActions={singleItemActions}
										handleItemAction={handleSingleItemAction}
										selectedItemsActions={multiItemsActions}
										handleSelectedItemsAction={handleMultiItemAction}
										globalActions={generalActions}
										handleGlobalAction={handleGeneralAction}
										getIconValue={props.getIconValue}
										getFormattingStyles={getFormattingStyles}
										formatDataValue={props.formatDataValue}
										useZebraStripes={props.useZebraStripes}
										useCompactView={props.useCompactView}
										enableItemFormatting={props.enableItemFormatting}
										emptyListMessage={props.emptyListMessage}
									/>
								)}
								{view === e_MasterDetailView.master && breakpoint === 'phone' && (
									<MasterPanel
										title={getPluralTitle()}
										items={filteredData}
										selectedIds={selectedIds}
										setSelectedIds={setSelectedIds}
										setActiveId={tryShowItem}
										lockActiveID={props.lockSelection}
										activeId={activeId}
										ref={masterPanelRef}
										itemName={props.itemName ? props.itemName : getFallbackItemName}
										getFormattingStyles={getFormattingStyles}
										formatDataValue={props.formatDataValue}
										listItemTemplate={props.listItemTemplate}
										avatarContent={props.avatarContent ? props.avatarContent : getFallbackAvatarContent}
										summaryContent={props.summaryContent ? props.summaryContent : getFallbackSummaryContent}
										getIconValue={props.getIconValue}
										columns={columnDescriptions}
										sortedColumns={sortedColumns}
										sortColumns={updateSortedColumns}
										filterText={filterText}
										setFilterText={setFilterText}
										isMultiSelect={isMultiSelect}
										setIsMultiSelect={setIsMultiSelect}
										singleItemActions={singleItemActions}
										handleSingleItemAction={handleSingleItemAction}
										multiItemActions={multiItemsActions}
										handleMultiItemAction={handleMultiItemAction}
										generalActions={generalActions}
										handleGeneralAction={handleGeneralAction}
										useZebraStripes={props.useZebraStripes}
										useCompactView={props.useCompactView}
										enableItemFormatting={props.enableItemFormatting}
										emptyListMessage={props.emptyListMessage}
									/>
								)}
							</div>
						)}

						{view === e_MasterDetailView.detail && (
							<div className={classes.view} ref={detailRef} key={e_MasterDetailView.detail}>
								{view === e_MasterDetailView.detail && breakpoint === 'pc' && (
									<DetailView
										items={filteredData}
										selectedIds={selectedIds}
										setSelectedIds={setSelectedIds}
										activeId={activeId}
										setActiveId={tryShowItem}
										lockActiveID={props.lockSelection}
										listTitle={getPluralTitle()}
										title={getItemTitle()}
										back={tryShowTable}
										ref={detailViewRef}
										itemName={props.itemName ? props.itemName : getFallbackItemName}
										getFormattingStyles={getFormattingStyles}
										formatDataValue={props.formatDataValue}
										listItemTemplate={props.listItemTemplate}
										avatarContent={props.avatarContent ? props.avatarContent : getFallbackAvatarContent}
										summaryContent={props.summaryContent ? props.summaryContent : getFallbackSummaryContent}
										getIconValue={props.getIconValue}
										columns={columnDescriptions}
										fieldDescriptions={detailFieldDescriptions}
										sortedColumns={sortedColumns}
										sortColumns={updateSortedColumns}
										filterText={filterText}
										setFilterText={setFilterText}
										detailComponent={props.detailComponent}
										renderDetailComponent={props.renderDetailComponent}
										isMultiSelect={isMultiSelect}
										setIsMultiSelect={setIsMultiSelect}
										breakpoint={currentBreakpoint}
										singleItemActions={singleItemActions}
										handleSingleItemAction={handleSingleItemAction}
										multiItemActions={multiItemsActions}
										handleMultiItemAction={handleMultiItemAction}
										generalActions={generalActions}
										handleGeneralAction={handleGeneralAction}
										useZebraStripes={props.useZebraStripes}
										useCompactView={props.useCompactView}
										enableItemFormatting={props.enableItemFormatting}
										emptyListMessage={props.emptyListMessage}
									/>
								)}
								{view === e_MasterDetailView.detail && (breakpoint === 'ipad' || breakpoint === 'phone') && (
									<DetailPanel
										items={filteredData}
										fieldDescriptions={detailFieldDescriptions}
										selectedIds={selectedIds}
										setSelectedIds={updateSelectedIds}
										activeId={activeId}
										setActiveId={setActiveId}
										title={getItemTitle()}
										back={tryShowTable}
										ref={detailPanelRef}
										breakpoint={currentBreakpoint}
										itemName={props.itemName ? props.itemName : getFallbackItemName}
										detailComponent={props.detailComponent}
										renderDetailComponent={props.renderDetailComponent}
										singleItemActions={singleItemActions}
										handleSingleItemAction={handleSingleItemAction}
										formatDataValue={props.formatDataValue}
									/>
								)}
							</div>
						)}
					</>
				</CSSTransition>
			</TransitionGroup>
		</div>
	)
}

const transitionPcClasses = createStyle({
	masterExit: {
		'& span[class^="headerTitle"]': {
			paddingLeft: 0,
			opacity: 1,
		},
		'& div[class^="masterTable"]': {
			opacity: 1,
		},
		clipPath: `inset(0 max(0px, calc(100% - ${DETAIL_VIEW_MASTER_PANEL_WIDTH}px - 650px)) 0 0)`,
	},
	masterExitActive: {
		'& span[class^="headerTitle"]': {
			paddingLeft: 32,
			opacity: 0,
			transition: 'padding-left 300ms ease, opacity 100ms ease 200ms',
		},
		'& div[class^="masterTable"]': {
			opacity: 0,
			transition: 'opacity 200ms ease 100ms',
		},
		// Since DetailPanel doesn't have a solid background(and stacked below masterpanel), we need to clip the master view.
		clipPath: `inset(0 calc(100% - ${DETAIL_VIEW_MASTER_PANEL_WIDTH}px) 0 0)`,
		transition: 'clip-path 300ms ease',
	},
	detailEnter: {
		'& div[class^="masterPanel"]': {
			'& div[class^="headerControlContent"], & div[class^="headerControlCommandArea"]': {
				opacity: 0,
			},
			'& div[class^="list"]': {
				opacity: 0,
			},
		},
		'& div[class^="detailPanel"]': {
			opacity: 0,
			transform: 'translateX(min(650px, 100%))',
		},
	},
	detailEnterActive: {
		'& div[class^="masterPanel"]': {
			'& div[class^="headerControlContent"]': {
				opacity: 1,
				transition: 'opacity 100ms ease 200ms',
			},
			'& div[class^="list"], & div[class^="headerControlCommandArea"]': {
				opacity: 1,
				transition: 'opacity 200ms ease 100ms',
			},
		},
		'& div[class^="detailPanel"]': {
			transform: 'translateX(0)',
			opacity: 1,
			transition: 'transform 300ms ease, opacity 300ms cubic-bezier(0.22, 1, 0.36, 1)',
		},
	},
	detailExit: {
		'& div[class^="masterPanel"]': {
			'& div[class^="headerControlContent"]': {
				opacity: 1,
				marginLeft: 0,
			},
			'& div[class^="list"], & div[class^="headerControlCommandArea"]': {
				opacity: 1,
			},
		},
		'& div[class^="detailPanel"]': {
			transform: 'translateX(0)',
			opacity: 1,
		},
	},
	detailExitActive: {
		'& div[class^="masterPanel"]': {
			'& div[class^="headerControlContent"]': {
				marginLeft: -32,
				opacity: 0,
				transition: 'margin-left 200ms ease, opacity 50ms ease 150ms',
			},
			'& div[class^="list"], & div[class^="headerControlCommandArea"]': {
				opacity: 0,
				transition: 'opacity 200ms ease',
			},
		},
		'& div[class^="detailPanel"]': {
			transform: 'translateX(100%)',
			opacity: 0,
			transition: 'transform 200ms ease, opacity 200ms cubic-bezier(0.16, 1, 0.3, 1)',
		},
	},
	masterEnter: {
		'& span[class^="headerTitle"], & div[class^="headerControlCommandArea"], & div[class^="masterTable"]': {
			opacity: 0,
		},
		clipPath: `inset(0 calc(100% - ${DETAIL_VIEW_MASTER_PANEL_WIDTH}px) 0 0)`,
	},
	masterEnterActive: {
		'& span[class^="headerTitle"], & div[class^="headerControlCommandArea"]': {
			opacity: 1,
			transition: 'opacity 50ms ease 150ms',
		},
		'& div[class^="masterTable"]': {
			opacity: 1,
			transition: 'opacity 200ms ease',
		},
		clipPath: 'inset(0 0 0 0)',
		transition: 'clip-path 200ms ease',
	},
})

const transitionPhoneIPadClasses = createStyle({
	masterExit: {
		transform: 'translateX(0)',
		clipPath: 'inset(0 0 0 0)',
		opacity: 1,
	},
	masterExitActive: {
		transform: 'translateX(-48px)',
		clipPath: 'inset(0 calc(100% - 48px) 0 0)',
		opacity: 0,
		transition: 'transform 300ms ease, clip-path 300ms ease, opacity 200ms ease',
	},
	detailEnter: {
		'& div[class^="masterPanel"]': {
			'& div[class^="headerControlContent"], & div[class^="headerControlCommandArea"]': {
				opacity: 0,
			},
			'& div[class^="list"]': {
				opacity: 0,
			},
		},
		'& div[class^="detailPanel"]': {
			transform: 'translateX(100%)',
		},
	},
	detailEnterActive: {
		'& div[class^="masterPanel"]': {
			'& div[class^="headerControlContent"]': {
				opacity: 1,
				transition: 'opacity 100ms ease 200ms',
			},
			'& div[class^="list"], & div[class^="headerControlCommandArea"]': {
				opacity: 1,
				transition: 'opacity 200ms ease 100ms',
			},
		},
		'& div[class^="detailPanel"]': {
			transform: 'translateX(0)',
			transition: 'transform 300ms ease',
		},
	},
	detailExit: {
		transform: 'translateX(0)',
	},
	detailExitActive: {
		transform: 'translateX(100%)',
		transition: 'transform 200ms ease',
	},
	masterEnter: {
		transform: 'translateX(-48px)',
		clipPath: 'inset(0 calc(100% - 48px) 0 0)',
	},
	masterEnterActive: {
		transform: 'translateX(0)',
		clipPath: 'inset(0 0 0 0)',
		transition: 'all 200ms ease',
	},
})
