import type { ReactNode } from 'react'
import React from 'react'
import { createStyle } from '../../theming'
import classNames from 'clsx'
import type { MenuItem } from '../Menu'
import { Icon } from '../Icon'
import type { IIconNames } from '@genusbiz/icon-set/dist/utils/IIconNames'
import type { ITableMultiselect } from '../Table'
import { CommandBar } from '../CommandBar'

interface ITableCellProps {
	children?: ReactNode | ReactNode[]
	className?: string
	columnWidth?: string
	colId?: string
	screenTip?: string
	columnSortOnClick?: (colId: string, multiSelect: ITableMultiselect) => void
	columnSortOrder?: 'asc' | 'desc'
	iconName?: IIconNames
	iconColorClass?: string
	label?: string
	menuItems?: MenuItem[]
	menuItemsClassName?: string
	leftDivider?: boolean
	commandbarRightAligned?: boolean
	commandbarOverflow?: boolean
	showMenuItemsOnHover?: boolean
}

const classes = createStyle((theme) => ({
	cell: {
		textAlign: 'left',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		display: 'flex',
		alignItems: 'center',
	},
	label: {
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		paddingLeft: 5,
		flex: 1,
	},
	icon: {
		paddingLeft: 5,
	},
	separator: {
		'&::before': {
			content: "' '",
			width: '1px',
			height: 20,
			alignSelf: 'center',
			display: 'block',
			background: theme.colors.button.border,
		},
	},
	invisibleSeparator: {
		'&::before': {
			content: "' '",
			width: '1px',
			display: 'block',
		},
	},
	menuItems: {
		marginLeft: 'auto',
		width: 'auto',
	},
}))

export const TableCell = (props: ITableCellProps) => {
	const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
		const ctrlMultiselect = e.ctrlKey || e.metaKey
		const shiftMultiselect = e.shiftKey

		if (props.colId && props.columnSortOnClick) {
			props.columnSortOnClick(props.colId, { ctrlMultiselect, shiftMultiselect })
		}
	}

	const sorting = props.columnSortOrder

	const sortIcon = sorting === 'desc' ? <i className="Fluent-SortDown" /> : <i className="Fluent-SortUp" />

	return (
		<div
			title={props.screenTip ? props.screenTip : props.label}
			onClick={onClick}
			className={classNames(
				classes.cell,
				props.className,
				props.leftDivider ? classes.separator : classes.invisibleSeparator,
				'cell'
			)}
			style={{ width: props.columnWidth }}
		>
			{props.iconName && <Icon className={classNames(classes.icon, props.iconColorClass)} iconName={props.iconName} />}
			{props.children || props.label ? <div className={classes.label}>{props.label}</div> : undefined}
			{props.menuItems && (
				<CommandBar
					className={classNames(classes.menuItems, props.menuItemsClassName, {
						['cell-menu-hover']: props.showMenuItemsOnHover,
					})}
					forceWidth={!props.children && !props.label}
					rightAligned={props.commandbarRightAligned}
					handleOverflow={props.menuItems.length > 1 && props.commandbarOverflow !== undefined}
					items={props.menuItems}
					size={'small'}
				/>
			)}

			{sorting && props.columnSortOnClick ? sortIcon : undefined}
		</div>
	)
}
