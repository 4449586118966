import React from 'react'

// AG-grid
import type { ICellRendererParams } from '@ag-grid-community/core'

// Components
import { CellSelectionCheckMark } from '../CellCheckMark'

// Interfaces and enums
import type { CellData, ICellRendererProps, TData } from '../../Table.types'

// Utils
import { createStyle } from '../../../../theming'

export interface IGroupCellRendererProps {
	multiSelect: boolean
}

const classes = createStyle((theme) => ({
	row: {
		fontWeight: theme.typography.styles.button.fontWeight,
		display: 'flex',
		height: '100%',
		alignItems: 'center',
		gap: 4,
	},
}))

export const GroupCellRenderer = (props: ICellRendererParams<TData, CellData> & IGroupCellRendererProps) => {
	const { node, valueFormatted } = props

	if (valueFormatted === '') {
		return null
	}
	const groupingFieldId = node.field ?? 'value'

	const colDef = props.api.getColumn(groupingFieldId)?.getColDef()
	const cellRendererParams = colDef?.cellRendererParams as ICellRendererProps | undefined

	const headerName = colDef?.headerName

	const groupName =
		cellRendererParams?.prefixGroup && headerName !== undefined ? headerName + ': ' + valueFormatted : valueFormatted

	return (
		<div className={classes.row} title={`${groupName} ${node.allChildrenCount ?? 0})`}>
			{props.multiSelect && <CellSelectionCheckMark api={props.api} node={props.node} />}
			<div>{groupName}</div>
			{node.allChildrenCount !== null && <div>({node.allChildrenCount ?? 0})</div>}
		</div>
	)
}
