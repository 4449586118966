import React, { useMemo } from 'react'
import { HeaderControl } from './HeaderControl'
import type { ITableFieldDescription } from '../types/IDataPropertyDescription'
import type { ISortData } from '../types/ISortData'
import { createStyle } from '../../../theming'
import type { IDataWithIdentifier } from '../types/IDataWithIdentifier'
import type { IActionEntry } from '../types/IActionDescription'
import { e_actionType } from '../types/IActionDescription'
import {
	type GetIconValueEventHandler,
	type FormatDataValueEventHandler,
	type GetFormattingStylesEventHandler,
	type IIconValue,
} from '../types/MasterDetailEventHandlers'
import type { MenuItem } from '../../../controls/Menu'
import { e_MenuItemType } from '../../../controls/Menu'
import type { IListItem } from '../../../controls/List'
import { List, e_ListNode } from '../../../controls/List'
import { e_ListItemLayout } from '../types/e_ListItemLayout'
import { formatPropertyValue } from '../utils/valueUtils'
import { useTranslation } from '../../../translation'
import { e_MasterDetailTargetElement } from '../types/e_MasterDetailTargetElement'
import { Icon } from '../../../controls/Icon'
import type { MasterDetailDataType } from '../types/MasterDetailDataType'

const classes = createStyle((theme) => ({
	masterPanel: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
		minHeight: 0,
	},

	withBorder: {
		border: `1px solid ${theme.colors.panel.border}`,
		borderRadius: '5px',
	},

	withShadow: {
		boxShadow: `0 6.4px 14.4px 0 rgba(${'0,0,0'}, 0.132), 0 1.2px 3.6px 0 rgba(${'0,0,0'}, 0.108)`,
	},

	leftMargin: {
		marginLeft: '16px',
	},

	rightMargin: {
		marginLeft: '16px',
	},

	masterPanelList: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		overflowX: 'hidden',
		overflowY: 'auto',
		alignItems: 'stretch',
	},

	item: {
		display: 'flex',
		padding: '4px 0px',
		userSelect: 'none',
		minWidth: 0,
	},

	avatar: {
		background: theme.colors.appBar.background,
		color: theme.colors.appBar.text,
		fontSize: '12px',
		marginRight: '8px',
		alignSelf: 'center',
		minWidth: '32px',
		maxWidth: '32px',
		height: '32px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: '50%',
		fontWeight: 600,
	},
	content: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
		justifyContent: 'center',
		gap: '4px',
		textAlign: 'left',
		minWidth: 0,
	},

	itemName: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},

	summaryContent: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		fontSize: '12px',
		opacity: 0.5,
	},
}))

interface IMasterPanelProps {
	title: string
	items: IDataWithIdentifier[]

	selectedIds: string[]
	setSelectedIds: (ids: string[]) => void

	activeId: string | undefined
	setActiveId: (id: string | undefined) => void
	/** Used to stop change in active ID */
	lockActiveID?: boolean
	back?: () => void
	width?: number

	itemName?: string | ((item: IDataWithIdentifier) => string | undefined)
	getFormattingStyles?: GetFormattingStylesEventHandler
	formatDataValue?: FormatDataValueEventHandler
	avatarContent?: string | ((item: IDataWithIdentifier) => JSX.Element | string | undefined)
	summaryContent?: string | ((item: IDataWithIdentifier) => string | undefined)

	columns: ITableFieldDescription[]
	sortedColumns?: ISortData[]
	sortColumns?: (sortedColumns: ISortData[]) => void

	filterText?: string
	setFilterText?: (filterTExt: string) => void

	isMultiSelect?: boolean
	setIsMultiSelect?: (multiSelect: boolean) => void

	singleItemActions?: IActionEntry[]
	handleSingleItemAction?: (actionId: string, itemId: string) => void

	multiItemActions?: IActionEntry[]
	handleMultiItemAction?: (actionId: string, itemIds: string[]) => void

	generalActions?: IActionEntry[]
	handleGeneralAction?: (actionId: string) => void

	getIconValue?: GetIconValueEventHandler

	listItemTemplate?: e_ListItemLayout

	useZebraStripes?: boolean
	useCompactView?: boolean
	enableItemFormatting?: boolean
	emptyListMessage?: string

	gridArea?: string
}

export const MasterPanel = React.forwardRef((props: IMasterPanelProps, forwardedRef: React.Ref<HTMLDivElement>) => {
	const { tcvi } = useTranslation()

	const itemNamePropertyDesc = useMemo(() => {
		if (typeof props.itemName === 'string') {
			return props.columns.find((col) => col.propertyId === props.itemName)
		}

		return undefined
	}, [props.columns, props.itemName])

	const summaryPropertyDesc = useMemo(() => {
		if (typeof props.summaryContent === 'string') {
			return props.columns.find((col) => col.propertyId === props.summaryContent)
		}

		return undefined
	}, [props.columns, props.summaryContent])

	const avatarPropertyDesc = useMemo(() => {
		if (typeof props.avatarContent === 'string') {
			return props.columns.find((col) => col.propertyId === props.avatarContent)
		}

		return undefined
	}, [props.columns, props.avatarContent])

	const getItemName = useMemo(
		() => (item: IDataWithIdentifier) => {
			if (!props.itemName) {
				return item.id
			}

			if (typeof props.itemName === 'string') {
				// props.itemName is a propertyId
				if (itemNamePropertyDesc && itemNamePropertyDesc.enableValueFormatting && props.formatDataValue) {
					const customValue = props.formatDataValue(
						item,
						itemNamePropertyDesc.propertyId,
						e_MasterDetailTargetElement.displayName
					)

					if (customValue !== undefined) {
						return customValue
					}
				}

				return itemNamePropertyDesc
					? formatPropertyValue(item, props.itemName, itemNamePropertyDesc, props.formatDataValue, tcvi)
					: item[props.itemName]
			}

			// deprecate
			if (typeof props.itemName === 'function') {
				return props.itemName(item)
			}
		},
		[itemNamePropertyDesc, props.formatDataValue, props.itemName]
	)

	const getAvatarColors = (item: IDataWithIdentifier, propertyId: string) => {
		if (props.getFormattingStyles) {
			return props.getFormattingStyles(item, propertyId, e_MasterDetailTargetElement.avatar)
		}

		return undefined
	}

	interface IAvatarValue {
		displayValue: MasterDetailDataType
		icon?: IIconValue | undefined
	}

	const getAvatarContent = useMemo(
		() => (item: IDataWithIdentifier) => {
			const avatarValue: IAvatarValue = {
				displayValue: undefined,
			}
			if (!props.avatarContent) {
				avatarValue.displayValue = null
				return avatarValue
			}

			if (typeof props.avatarContent === 'string') {
				// props.avatarContent is a propertyId

				// if (avatarPropertyDesc && avatarPropertyDesc.enableValueFormatting && props.formatDataValue) {
				// 	const customValue = props.formatDataValue(
				// 		item,
				// 		avatarPropertyDesc.propertyId,
				// 		e_MasterDetailTargetElement.avatar
				// 	)

				// 	if (customValue !== undefined) {
				// 		avatarValue.displayValue = customValue
				// 	}
				// } else {
				avatarValue.displayValue = avatarPropertyDesc
					? formatPropertyValue(item, props.avatarContent, avatarPropertyDesc, props.formatDataValue, tcvi)
					: item[props.avatarContent]
				// }

				// get avatar icon
				avatarValue.icon = props.getIconValue
					? props.getIconValue(item, props.avatarContent, e_MasterDetailTargetElement.avatar)
					: undefined

				return avatarValue
			}

			// deprecate
			if (typeof props.avatarContent === 'function') {
				return { displayValue: props.avatarContent(item) } as IAvatarValue
			}
		},
		[avatarPropertyDesc, props.formatDataValue, props.avatarContent, props.getIconValue]
	)

	const getSummaryContent = useMemo(
		() => (item: IDataWithIdentifier) => {
			if (!props.summaryContent) {
				return ''
			}

			if (typeof props.summaryContent === 'string') {
				// props.summaryContent is a propertyId

				if (summaryPropertyDesc && summaryPropertyDesc.enableValueFormatting && props.formatDataValue) {
					const customValue = props.formatDataValue(
						item,
						summaryPropertyDesc.propertyId,
						e_MasterDetailTargetElement.details
					)

					if (customValue !== undefined) {
						return customValue
					}
				}

				return summaryPropertyDesc
					? formatPropertyValue(item, props.summaryContent, summaryPropertyDesc, props.formatDataValue, tcvi)
					: item[props.summaryContent]
			}

			// deprecate
			if (typeof props.summaryContent === 'function') {
				return props.summaryContent(item)
			}
		},
		[summaryPropertyDesc, props.formatDataValue, props.summaryContent]
	)

	const listItems: IListItem<IDataWithIdentifier>[] = useMemo(() => {
		return props.items.map((item) => {
			return {
				type: e_ListNode.item as const,
				id: item.id,
				data: item,
				screenTip: getItemName(item)?.toString() || undefined,
			}
		})
	}, [props.items])

	const renderAvatarNameDetailListItem = (
		item: IListItem<IDataWithIdentifier> & { isSelected: boolean; isDisabled: boolean; isFocused?: boolean }
	) => {
		const data = item.data

		const avatarContent = getAvatarContent(data)

		const getFormattingStyles = getAvatarColors(data, avatarPropertyDesc ? avatarPropertyDesc.propertyId : '')

		const avatarStyle = getFormattingStyles
			? { background: getFormattingStyles.background, color: getFormattingStyles.text }
			: undefined

		return (
			<div key={data.id} className={classes.item}>
				<div className={classes.avatar} style={avatarStyle}>
					{avatarContent && avatarContent.icon && (
						<Icon
							iconClassName={avatarContent.icon.iconName}
							// color={avatarContent.icon.iconColor}
							screenTip={avatarContent.icon.screenTip}
						/>
					)}
					{avatarContent && avatarContent.displayValue}
				</div>
				<div className={classes.content}>
					<span className={classes.itemName}>{getItemName(data)}</span>
					<span className={classes.summaryContent}>{getSummaryContent(data)}</span>
				</div>
			</div>
		)
	}

	const renderNameDetailListItem = (
		item: IListItem<IDataWithIdentifier> & { isSelected: boolean; isDisabled: boolean; isFocused?: boolean }
	) => {
		const data = item.data

		return (
			<div key={data.id} className={classes.item}>
				<div className={classes.content}>
					<span className={classes.itemName}>{getItemName(data)}</span>
					<span className={classes.summaryContent}>{getSummaryContent(data)}</span>
				</div>
			</div>
		)
	}

	const renderNameListItem = (
		item: IListItem<IDataWithIdentifier> & { isSelected: boolean; isDisabled: boolean; isFocused?: boolean }
	) => {
		const data = item.data

		return (
			<div key={data.id} className={classes.item}>
				{getItemName(data)}
			</div>
		)
	}

	const renderListItem = (
		item: IListItem<IDataWithIdentifier> & { isSelected: boolean; isDisabled: boolean; isFocused?: boolean }
	) => {
		switch (props.listItemTemplate) {
			case e_ListItemLayout.avatarNameDetail:
				return renderAvatarNameDetailListItem(item)
			case e_ListItemLayout.nameDetail:
				return renderNameDetailListItem(item)
			case e_ListItemLayout.name:
				return renderNameListItem(item)
		}
		return renderAvatarNameDetailListItem(item)
	}

	const buildMultiItemMenuItems = (selectedItemIds: string[]) => {
		const contextMenuItems: MenuItem[] = []
		if (props.multiItemActions && props.multiItemActions.length > 0 && props.handleMultiItemAction) {
			props.multiItemActions.forEach((action) => {
				switch (action.type) {
					case e_actionType.action: {
						contextMenuItems.push({
							type: e_MenuItemType.action,
							id: action.id,
							iconClassName: action.iconClassName,
							iconColorClass: action.iconColorClass,
							name: action.displayName,
							screenTip: action.screenTip,
							isChecked: action.isChecked,

							onClick: () => {
								props.handleMultiItemAction && props.handleMultiItemAction(action.id, selectedItemIds)
							},
						})
						break
					}
					case e_actionType.separator: {
						contextMenuItems.push({ type: e_MenuItemType.divider })
						break
					}
				}
			})
		}

		return {
			type: 'root',
			menuType: 'contextMenu',
			items: contextMenuItems,
		}
	}

	const buildSingleItemMenuItems = (selectedItemIds: string[]) => {
		const contextMenuItems: MenuItem[] = []

		if (
			selectedItemIds.length === 1 &&
			props.singleItemActions &&
			props.singleItemActions.length > 0 &&
			props.handleSingleItemAction
		) {
			props.singleItemActions.forEach((action) => {
				switch (action.type) {
					case e_actionType.action: {
						contextMenuItems.push({
							type: e_MenuItemType.action,
							id: action.id,
							iconClassName: action.iconClassName,
							iconColorClass: action.iconColorClass,
							name: action.displayName,
							screenTip: action.screenTip,
							isChecked: action.isChecked,

							onClick: () => {
								props.handleSingleItemAction && props.handleSingleItemAction(action.id, selectedItemIds[0])
							},
						})
						break
					}
					case e_actionType.separator: {
						contextMenuItems.push({ type: e_MenuItemType.divider })
						break
					}
				}
			})
		}

		return {
			type: 'root',
			menuType: 'contextMenu',
			items: contextMenuItems,
		}
	}

	const getBuildContextMenuContent = () => {
		if (props.isMultiSelect && props.multiItemActions && props.multiItemActions.length && props.handleMultiItemAction) {
			return buildMultiItemMenuItems
		}

		if (
			!props.isMultiSelect &&
			props.singleItemActions &&
			props.singleItemActions.length &&
			props.handleSingleItemAction
		) {
			return buildSingleItemMenuItems
		}

		return undefined
	}

	const selectionMode = props.lockActiveID ? 'none' : props.isMultiSelect ? 'multi' : 'single'
	return (
		<div
			className={classes.masterPanel}
			style={{
				width: props.width ? `${props.width}px` : undefined,
				flex: props.width ? 'unset' : 1,
				gridArea: props.gridArea || undefined,
			}}
			ref={forwardedRef}
		>
			<HeaderControl
				title={props.title}
				back={props.back}
				filterText={props.filterText}
				setFilterText={props.setFilterText}
				count={props.items.length}
				columns={props.columns}
				sortedColumns={props.sortedColumns}
				sortColumns={props.sortColumns}
				isMultiSelect={props.isMultiSelect}
				setIsMultiSelect={props.setIsMultiSelect}
				multiItemActions={props.multiItemActions}
				handleMultiItemAction={props.handleMultiItemAction}
				generalActions={props.generalActions}
				handleGeneralAction={props.handleGeneralAction}
				selectedIds={props.selectedIds}
				compactView
			/>

			<List
				selectionMode={selectionMode}
				displayCheckbox={props.isMultiSelect}
				items={listItems}
				selectedItemIds={props.selectedIds}
				onSelectionChange={props.setSelectedIds}
				itemRenderer={renderListItem}
				onActivate={props.setActiveId}
				activateOnSingleClick
				zebraStripes={props.useZebraStripes}
				buildContextMenuContent={getBuildContextMenuContent()}
				emptyListMessage={props.emptyListMessage}
				rowClickSelectionMode="replace"
			/>
		</div>
	)
})

MasterPanel.displayName = 'MasterPanel'
