import React, { useEffect, useRef, useState } from 'react'
import classNames from 'clsx'
import { Dropdown, DropdownInput } from '../../../../Dropdown'
import { createStyle } from '../../../../../theming'
import type { ICellRenderer, Option, Value } from '../../../Table.types'

interface ICellRendererDropdown extends ICellRenderer {
	value: Value
	displayValue: string | undefined
	filterOperator: e_FilterOperator
	options: Option[]
	allowNull: boolean
	onNeedOptions: () => void
	onClose: (e?: Event) => void
	isLoadingOptions: boolean
	initialFilterTerm: string | undefined
}

export enum e_FilterOperator {
	startsWith = 'startsWith',
	includes = 'includes',
	disabled = 'disabled',
}

const classes = createStyle({
	dropdown: { flex: '1' },
})

export const CellRendererDropdown = (props: ICellRendererDropdown) => {
	const ref = useRef<HTMLInputElement>(null)

	const [value, setValue] = useState<Value>(props.value)
	const valueRef = useRef<Value>(props.value)
	const [displayLabel, setDisplayLabel] = useState(value !== null ? props.displayValue : undefined)

	const onClose = () => {
		if (valueRef.current !== props.value) {
			props.setValue?.(valueRef.current)
		}
		props.onClose()
	}

	const onChange = (value: Value) => {
		valueRef.current = value
		setValue(value)
	}

	useEffect(() => {
		setDisplayLabel(value !== null ? props.displayValue : undefined)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.displayValue])

	useEffect(() => {
		ref.current?.focus()
	}, [])

	return props.filterOperator === e_FilterOperator.disabled ? (
		<Dropdown
			ref={ref}
			value={value}
			displayLabel={displayLabel}
			labelPosition="top"
			allowNull={props.allowNull}
			className={classNames(classes.dropdown, props.className)}
			options={props.options}
			defaultOpen
			onOpen={props.onNeedOptions}
			onChange={onChange}
			onClose={onClose}
			error={props.hasError}
		/>
	) : (
		<DropdownInput
			ref={ref}
			value={value}
			displayLabel={displayLabel}
			labelPosition="top"
			allowNull={props.allowNull}
			className={classNames(classes.dropdown, props.className)}
			options={props.options}
			defaultOpen
			onOpen={props.onNeedOptions}
			onChange={onChange}
			onClose={onClose}
			isLoadingOptions={props.isLoadingOptions}
			error={props.hasError}
			filterOperator={props.filterOperator}
			initialFilterTerm={props.initialFilterTerm}
		/>
	)
}
