import { useMemo } from 'react'
import type { CellClickedEvent, ColDef } from '@ag-grid-community/core'
import type { ITableColumnHeaderProps } from '../components/ColumnHeader/ColumnHeader'
import type { CellData, IGroupColumnDef, TData } from '../Table.types'
import { AutoGroupColumnHeader } from '../components/AutoGroupColumnHeader'
import { GroupCellRenderer } from '../components/CellRenderer/GroupCellRenderer'
import { formatGroupCellValue } from '../utils/formatGroupCellValue'
import { getAutoGroupValueGetter } from '../utils/colDefUtils'

export const useAutoGroupColumnDef = (
	groupHeaderName: string | undefined,
	tcvi: (t: string) => string,
	isMultiSelect: boolean,
	groupColumnDef: IGroupColumnDef | undefined,
	treeData: boolean | undefined,
	/**
	 * Disables filtering and sorting for the table. Defaults to true.
	 * @default true
	 */
	enableTableSorting = true
) =>
	useMemo(
		() => {
			const onCellClicked = (e: CellClickedEvent<TData>) => {
				if (e.event?.defaultPrevented) {
					return
				}
				if ((e.node.allChildrenCount ?? 0) > 0) {
					e.node.setExpanded(!e.node.expanded)
				}
			}

			const headerComponentParams: ITableColumnHeaderProps = {
				disableMenu: true,
				multiSelect: isMultiSelect,
			}

			const isSortingEnabledForColumn = groupColumnDef?.enableSorting ?? true

			const autoGroupColumnDef: ColDef<TData, CellData | string> = {
				lockPosition: true,
				headerComponentParams,
				headerComponent: AutoGroupColumnHeader,
				headerName: groupHeaderName ?? tcvi('GENERAL:GROUP'),
				cellRendererParams: {
					suppressCount: true,
					prefixGroup: false,
					multiSelect: isMultiSelect,
					innerRenderer: GroupCellRenderer,
				},
				resizable: true,
				minWidth: groupColumnDef?.minWidth || 54,
				width: getWidth(groupColumnDef?.width),
				sortable: enableTableSorting && isSortingEnabledForColumn,
				maxWidth: 400,
				cellRenderer: 'agGroupCellRenderer',
				valueFormatter: (params) => {
					return formatGroupCellValue(params.data, params.value, params.node, params.colDef)
				},
				valueGetter: getAutoGroupValueGetter(treeData),
				pinned: true,
				lockPinned: true,
				onCellClicked,
			}

			return autoGroupColumnDef
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	)
function getWidth(width: number | null | undefined): number | undefined {
	if (width === null) {
		return undefined
	}
	return width || 300
}
