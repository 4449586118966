import React from 'react'
import { IDetailItemSurfaceProps } from '@genusbiz/web-ui/surfaces/MasterDetail/types/IDetailItemSurfaceProps'
import { FieldContainer, GridLayout } from '@genusbiz/web-ui/surfaces'
import { e_GridTrackType } from '@genusbiz/web-ui/surfaces/Grid/gridTypes'
import { e_Overflow } from '@genusbiz/web-ui/enums/e_Overflow'
import { e_TextAlignment } from '@genusbiz/web-ui/enums/e_TextAlignment'
import { DisplayField } from '@genusbiz/web-ui/controls/Field/DisplayField'
import { IDataPartition } from 'src/interfaces/IK8sRuntime'
import { useTranslation } from 'react-i18next'
import { e_EnvironmentType } from 'src/enums/e_EnvironmentType'
import { capitalize } from 'src/utils/capitalize'

interface RuntimeDetailProps extends IDetailItemSurfaceProps {
	partitions: IDataPartition[]
}

export const RuntimeDetail = (props: RuntimeDetailProps) => {
	const item = props.dataItem
	const partitions = props.partitions

	const { t } = useTranslation()

	const EMPTY_CONTENT = '---'

	const gridConfig = [
		{
			rows: [
				{ type: e_GridTrackType.auto, length: 1 },
				{ type: e_GridTrackType.auto, length: 1 },
			],
			columns: [{ type: e_GridTrackType.fraction, length: 1 }],
			areas: {
				['outer-grid']: undefined,
				['feature-grid']: undefined,
			},
		},
	]

	const name = item['name'] ?? EMPTY_CONTENT

	const partitionId = item['dataPartitionId'] as string
	const partition = partitions.find((partition) => partition.id === partitionId)
	const partitionName = partition?.name

	const environmentType = item['environmentType'] as number
	const environmentTypeName = capitalize(e_EnvironmentType[environmentType])

	const cardDavService = getCheckmarkContent(item['CardDavService'] as boolean)
	const DataMartService = getCheckmarkContent(item['DataMartService'] as boolean)
	const MailService = getCheckmarkContent(item['MailService'] as boolean)
	const MessageQueueService = getCheckmarkContent(item['MessageQueueService'] as boolean)
	const RestService = getCheckmarkContent(item['RestService'] as boolean)
	const ScheduledActionService = getCheckmarkContent(item['ScheduledActionService'] as boolean)
	const WebCalService = getCheckmarkContent(item['WebCalService'] as boolean)
	const XmlService = getCheckmarkContent(item['XmlService'] as boolean)

	return (
		<GridLayout gridConfig={gridConfig}>
			<FieldContainer groupId="all" data-gridname={'outer-grid'} padding={10} gap={4}>
				<DisplayField
					label={t('GENERAL:NAME')}
					content={name}
					overflow={e_Overflow.noScrollbars}
					textAlignment={e_TextAlignment.left}
					disableBorder
					labelBold
				/>
				<DisplayField
					label={t('RUNTIMES:DATA_PARTITION')}
					content={partitionName}
					overflow={e_Overflow.noScrollbars}
					textAlignment={e_TextAlignment.left}
					disableBorder
					labelBold
				/>
				<DisplayField
					label={t('RUNTIMES:ENVIRONMENT')}
					content={environmentTypeName}
					overflow={e_Overflow.noScrollbars}
					textAlignment={e_TextAlignment.left}
					disableBorder
					labelBold
				/>
				<FieldContainer groupId="all" header="Features" data-gridname={'feature-grid'}>
					<DisplayField
						label={t('FEATURES:CARDDAVSERVICE')}
						content={cardDavService}
						overflow={e_Overflow.noScrollbars}
						textAlignment={e_TextAlignment.left}
						disableBorder
						labelBold
					/>
					<DisplayField
						label={t('FEATURES:DATAMARTSERVICE')}
						content={DataMartService}
						overflow={e_Overflow.noScrollbars}
						textAlignment={e_TextAlignment.left}
						disableBorder
						labelBold
					/>
					<DisplayField
						label={t('FEATURES:MAILSERVICE')}
						content={MailService}
						overflow={e_Overflow.noScrollbars}
						textAlignment={e_TextAlignment.left}
						disableBorder
						labelBold
					/>
					<DisplayField
						label={t('FEATURES:MESSAGEQUEUESERVICE')}
						content={MessageQueueService}
						overflow={e_Overflow.noScrollbars}
						textAlignment={e_TextAlignment.left}
						disableBorder
						labelBold
					/>
					<DisplayField
						label={t('FEATURES:RESTSERVICE')}
						content={RestService}
						overflow={e_Overflow.noScrollbars}
						textAlignment={e_TextAlignment.left}
						disableBorder
						labelBold
					/>
					<DisplayField
						label={t('FEATURES:SCHEDULEDACTIONSERVICE')}
						content={ScheduledActionService}
						overflow={e_Overflow.noScrollbars}
						textAlignment={e_TextAlignment.left}
						disableBorder
						labelBold
					/>
					<DisplayField
						label={t('FEATURES:WEBCALSERVICE')}
						content={WebCalService}
						overflow={e_Overflow.noScrollbars}
						textAlignment={e_TextAlignment.left}
						disableBorder
						labelBold
					/>
					<DisplayField
						label={t('FEATURES:XMLSERVICE')}
						content={XmlService}
						overflow={e_Overflow.noScrollbars}
						textAlignment={e_TextAlignment.left}
						disableBorder
						labelBold
					/>
				</FieldContainer>
			</FieldContainer>
		</GridLayout>
	)
}

const checkMarkPadding = { paddingLeft: '12px', paddingRight: '13px' }
const getCheckmarkWithText = (text: string, visible?: true) => (
	<>
		<i
			className="Fluent-CheckMark"
			aria-hidden="true"
			style={{ visibility: visible && 'hidden', fontWeight: 'bold', ...checkMarkPadding }}
		/>
		{text}
	</>
)

const getCheckmarkContent = (value: boolean) =>
	value ? getCheckmarkWithText('Enabled') : getCheckmarkWithText('Disabled', true)
