import type { e_BooleanFormat } from '../enums/e_BooleanFormat'
import { e_DataType } from '../enums/e_DataType'
import { e_DateFormat } from '../enums/e_DateFormat'
import { e_DateTimeFormat } from '../enums/e_DateTimeFormat'
import type { ExcelStyle } from '@ag-grid-community/core'

let styles: ExcelStyle[]
export function getExcelStyles() {
	if (styles) {
		return styles
	}
	styles = []
	Object.values(e_DateFormat).forEach((format) => {
		switch (format) {
			case e_DateFormat.longDate:
				styles.push({
					id: e_DateFormat.longDate,
					dataType: 'DateTime',
					numberFormat: { format: 'd. mmmm yyyy' }, // 22. august 2019
				})
				break
			case e_DateFormat.longDateTime:
				styles.push({
					id: e_DateFormat.longDateTime,
					dataType: 'DateTime',
					numberFormat: { format: 'd. mmmm yyyy hh:mm:ss' }, // 22. august 2019 13:53:01
				})
				break
			case e_DateFormat.longDateTimeWithoutSeconds:
				styles.push({
					id: e_DateFormat.longDateTimeWithoutSeconds,
					dataType: 'DateTime',
					numberFormat: { format: 'd. mmmm yyyy hh:mm' }, // 22. august 2019 13:53
				})
				break
			case e_DateFormat.shortDate:
				styles.push({
					id: e_DateFormat.shortDate,
					dataType: 'DateTime',
					numberFormat: { format: 'dd.mm.yyyy' }, // 22.08.2019
				})
				break
			case e_DateFormat.shortDateWithWeekday:
				styles.push({
					id: e_DateFormat.shortDateWithWeekday,
					dataType: 'DateTime',
					numberFormat: { format: 'dddd dd.mm.yyyy' }, // Tuesday 29.06.2021
				})
				break
			case e_DateFormat.shortDateWithShortWeekday:
				styles.push({
					id: e_DateFormat.shortDateWithShortWeekday,
					dataType: 'DateTime',
					numberFormat: { format: 'ddd. dd.mm.yyyy' }, // Tue 29.06.2021
				})
				break
			case e_DateFormat.shortDateTime:
				styles.push({
					id: e_DateFormat.shortDateTime,
					dataType: 'DateTime',
					numberFormat: { format: 'dd.mm.yyyy hh:mm:ss' }, // 22.08.2019 13:53:51
				})
				break

			case e_DateFormat.shortDateTimeWithoutSeconds:
				styles.push({
					id: e_DateFormat.shortDateTimeWithoutSeconds,
					dataType: 'DateTime',
					numberFormat: { format: 'dd.mm.yyyy hh:mm' }, // 22.08.2019 13:53
				})
				break
		}
	})

	Object.values(e_DateTimeFormat).forEach((format) => {
		switch (format) {
			case e_DateTimeFormat.DTF_TP_LOCALE_LD:
				styles.push({
					id: e_DateTimeFormat.DTF_TP_LOCALE_LD,
					dataType: 'DateTime',
					numberFormat: { format: 'd. mmmm yyyy' }, // 22. august 2019
				})
				break
			case e_DateTimeFormat.DTF_TP_LOCALE_SD:
				styles.push({
					id: e_DateTimeFormat.DTF_TP_LOCALE_SD,
					dataType: 'DateTime',
					numberFormat: { format: 'dd.mm.yyyy' }, // 22.08.2019
				})
				break
			case e_DateTimeFormat.DTF_TP_LOCALE_LT:
				styles.push({
					id: e_DateTimeFormat.DTF_TP_LOCALE_LT,
					dataType: 'DateTime',
					numberFormat: { format: 'hh:mm:ss' }, // 13:53:01
				})
				break
			case e_DateTimeFormat.DTF_TP_LOCALE_ST:
				styles.push({
					id: e_DateTimeFormat.DTF_TP_LOCALE_ST,
					dataType: 'DateTime',
					numberFormat: { format: 'hh:mm' }, // 13:53
				})
				break
			case e_DateTimeFormat.DTF_TP_LOCALE_LD_B_LT:
				styles.push({
					id: e_DateTimeFormat.DTF_TP_LOCALE_LD_B_LT,
					dataType: 'DateTime',
					numberFormat: { format: 'd. mmmm yyyy hh:mm:ss' }, // 22. august 2019 13:53:01
				})
				break
			case e_DateTimeFormat.DTF_TP_LOCALE_LD_B_ST:
				styles.push({
					id: e_DateTimeFormat.DTF_TP_LOCALE_LD_B_ST,
					dataType: 'DateTime',
					numberFormat: { format: 'd. mmmm yyyy hh:mm' }, // 22. august 2019 13:53
				})
				break
			case e_DateTimeFormat.DTF_TP_LOCALE_SD_B_LT:
				styles.push({
					id: e_DateTimeFormat.DTF_TP_LOCALE_SD_B_LT,
					dataType: 'DateTime',
					numberFormat: { format: 'dd.mm.yyyy hh:mm:ss' }, // 22.08.2019 13:53:51
				})
				break
			case e_DateTimeFormat.DTF_TP_LOCALE_SD_B_ST:
				styles.push({
					id: e_DateTimeFormat.DTF_TP_LOCALE_SD_B_ST,
					dataType: 'DateTime',
					numberFormat: { format: 'dd.mm.yyyy hh:mm' }, // 22.08.2019 13:53
				})
				break
			case e_DateTimeFormat.DTF_TP_MIL_YYYY_MM_DD:
				styles.push({
					id: e_DateTimeFormat.DTF_TP_MIL_YYYY_MM_DD,
					dataType: 'DateTime',
					numberFormat: { format: 'YYYYMMDD' },
				})
				break
			case e_DateTimeFormat.DTF_TP_MIL_YYYY_MM_DD_HH_NN:
				styles.push({
					id: e_DateTimeFormat.DTF_TP_MIL_YYYY_MM_DD_HH_NN,
					dataType: 'DateTime',
					numberFormat: { format: 'YYYYMMDDHHmm' },
				})
				break
			case e_DateTimeFormat.DTF_TP_MIL_YYYY_MM_DD_HH_NN_SS:
				styles.push({
					id: e_DateTimeFormat.DTF_TP_MIL_YYYY_MM_DD_HH_NN_SS,
					dataType: 'DateTime',
					numberFormat: { format: 'YYYYMMDDHHmmss' },
				})
				break
			case e_DateTimeFormat.DTF_TP_STD_YYYY_Z_MM_Z_DD:
				styles.push({
					id: e_DateTimeFormat.DTF_TP_STD_YYYY_Z_MM_Z_DD,
					dataType: 'DateTime',
					numberFormat: { format: 'YYYY/MM/DD' },
				})
				break
			case e_DateTimeFormat.DTF_TP_STD_YYYY_Z_MM_Z_DD_B_HH_C_NN:
				styles.push({
					id: e_DateTimeFormat.DTF_TP_STD_YYYY_Z_MM_Z_DD_B_HH_C_NN,
					dataType: 'DateTime',
					numberFormat: { format: 'YYYY/MM/DD HH:mm' },
				})
				break
			case e_DateTimeFormat.DTF_TP_STD_YYYY_Z_MM_Z_DD_B_HH_C_NN_C_SS:
				styles.push({
					id: e_DateTimeFormat.DTF_TP_STD_YYYY_Z_MM_Z_DD_B_HH_C_NN_C_SS,
					dataType: 'DateTime',
					numberFormat: { format: 'YYYY/MM/DD HH:mm:ss' },
				})
				break
			case e_DateTimeFormat.DTF_TP_STD_YY:
				styles.push({
					id: e_DateTimeFormat.DTF_TP_STD_YY,
					dataType: 'DateTime',
					numberFormat: { format: 'YY' },
				})
				break
			case e_DateTimeFormat.DTF_TP_STD_YYYY:
				styles.push({
					id: e_DateTimeFormat.DTF_TP_STD_YYYY,
					dataType: 'DateTime',
					numberFormat: { format: 'YYYY' },
				})
				break
			case e_DateTimeFormat.DTF_TP_STD_MM:
				styles.push({
					id: e_DateTimeFormat.DTF_TP_STD_MM,
					dataType: 'DateTime',
					numberFormat: { format: 'MM' },
				})
				break
			case e_DateTimeFormat.DTF_TP_STD_MMM:
				styles.push({
					id: e_DateTimeFormat.DTF_TP_STD_MMM,
					dataType: 'DateTime',
					numberFormat: { format: 'MMM' },
				})
				break
			case e_DateTimeFormat.DTF_TP_STD_D:
				styles.push({
					id: e_DateTimeFormat.DTF_TP_STD_D,
					dataType: 'DateTime',
					numberFormat: { format: 'D' },
				})
				break
			case e_DateTimeFormat.DTF_TP_STD_DD:
				styles.push({
					id: e_DateTimeFormat.DTF_TP_STD_DD,
					dataType: 'DateTime',
					numberFormat: { format: 'DD' },
				})
				break
			case e_DateTimeFormat.DTF_TP_STD_DDD:
				styles.push({
					id: e_DateTimeFormat.DTF_TP_STD_DDD,
					dataType: 'DateTime',
					numberFormat: { format: 'ddd' },
				})
				break
			case e_DateTimeFormat.DTF_TP_STD_DDDD:
				styles.push({
					id: e_DateTimeFormat.DTF_TP_STD_DDDD,
					dataType: 'DateTime',
					numberFormat: { format: 'dddd' },
				})
				break
		}
	})

	return styles
}

let excelIds: Set<string>

export function getExcelStyleId(
	numberFormat: string | undefined,
	dataType: e_DataType | undefined,
	columnFormat: e_DateFormat | e_BooleanFormat | undefined
) {
	const format = columnFormat !== e_DateFormat.default ? columnFormat : numberFormat

	if (dataType && [e_DataType.date, e_DataType.dateTime].includes(dataType)) {
		if (!excelIds) {
			excelIds = new Set(getExcelStyles().map((excelStyle) => excelStyle.id))
		}
		if (!format) {
			return e_DateFormat.shortDateTime
		}
		if (excelIds.has(format)) {
			return format
		}
	}
}
