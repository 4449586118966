import React, { useRef, useState, useCallback, useEffect } from 'react'
import classNames from 'clsx'
import { createStyle } from '../../theming'

import { DropdownListItem } from './DropdownListItem'
import type { DropdownOptionsHandler, DropdownOptionSelectable } from './DropdownOptionsHandler'
import { isSelectableOption } from './DropdownOptionsHandler'
import { DropdownListMenu } from './DropdownListMenu'
import type { DropdownMenuItem } from './Dropdown.types'
import { Popper } from '../utils/Popper'
import { e_Placement } from '../../enums/e_Placement'
import { e_DropShadow } from '../../enums/e_DropShadow'
import type PopperJS from 'popper.js'
import { Measurer } from '../utils/Measurer'
import { topWindow } from '../utils/topWindow'
import { useOnScrollOrResize } from '../utils/useOnScrollOrResize'

const classes = createStyle((theme) => ({
	popper: {
		background: theme.controls.input.colors.background,
		boxShadow: theme.shadows.light,
		overflowX: 'hidden',
		zIndex: theme.zIndex.popper,
		display: 'flex',
		flexDirection: 'column',
	},

	none: { boxShadow: theme.shadows.none },
	extraLight: { boxShadow: theme.shadows.extraLight },
	light: { boxShadow: theme.shadows.light },
	medium: { boxShadow: theme.shadows.medium },
	strong: { boxShadow: theme.shadows.strong },

	listWrapper: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		overflow: 'hidden',
	},
	scrollableListContent: {
		flex: 1,
		overflowY: 'auto',
	},
	statusText: {
		padding: 4,
		borderBottom: '1px solid ' + theme.colors.body.line,
		color: theme.colors.list.text,
	},
	list: {
		listStyle: 'none',
		padding: 0,
		margin: 0,
		flexDirection: 'column',
		alignItems: 'stretch',
	},
}))

interface IDropdownList<T> {
	options: DropdownOptionsHandler<T>
	isLoadingOptions?: boolean
	statusText?: string
	preselectedIndex?: number
	isOpen: boolean
	onClose?: () => void
	onChange: (value: T | null) => void
	value?: T | null
	values?: Set<T | null>
	className?: string
	style?: React.CSSProperties
	reserveIconSpace?: boolean
	multiSelect?: boolean
	typeJumping?: boolean
	disableSelectWithSpace?: boolean
	restrictValueToOptions?: boolean
	headerLeftItems?: DropdownMenuItem<T>[]
	headerRightItems?: DropdownMenuItem<T>[]
	footerLeftItems?: DropdownMenuItem<T>[]
	footerRightItems?: DropdownMenuItem<T>[]
	emptyListMessage?: string
	id?: string
	onActiveItemChange?: (activeItemId: string | undefined) => void

	anchorElement: React.RefObject<HTMLElement>
	maxHeight?: number
	width?: number
	minWidth?: number
	dropShadow?: e_DropShadow
	disableCloseOnViewportChange?: boolean
	dataAttributes?: Record<string, string>
}

const getRequiredSize = (dropdownControl: HTMLElement) => {
	const contentControls = dropdownControl.querySelectorAll('[data-control')

	const requiredSize = { width: 0, height: 0 }

	contentControls.forEach((control) => {
		requiredSize.height += control.scrollHeight
		requiredSize.width = Math.max(requiredSize.width, control.scrollWidth)
	})

	return requiredSize
}

const SCREEN_INSET_TOPBOTTOM = 16

export function DropdownList<T>(props: IDropdownList<T>) {
	const { dropShadow = e_DropShadow.medium } = props
	const { preselectedIndex = -1, restrictValueToOptions = true, onActiveItemChange } = props

	const popperNode = useRef<HTMLDivElement>()
	const listRef = useRef<HTMLUListElement>(null)
	const listContainerRef = useRef<HTMLDivElement>(null)
	const hasNavigatedByKeys = useRef(false)

	const [activeItemIndex, setActiveItemIndex] = useState(preselectedIndex)

	useEffect(() => {
		if (props.id && onActiveItemChange) {
			if (activeItemIndex !== -1) {
				onActiveItemChange(`${props.id}-${activeItemIndex}`)
			} else {
				onActiveItemChange(undefined)
			}
		}
	}, [activeItemIndex, onActiveItemChange, props.id])

	const searchText = useRef('')
	const searchTextTimeout = useRef<number | null>(null)

	const updateNavIndex = (index: number) => {
		setActiveItemIndex(index)
	}
	const scrollItemIndexIntoView = (index: number) => {
		setTimeout(() => {
			if (!listRef.current) {
				return
			}
			const itemElement: HTMLElement | null = listRef.current.querySelector(`[data-itemindex="${index}"`)

			if (!itemElement) {
				return
			}

			itemElement.scrollIntoView({ block: 'nearest', inline: 'nearest' })
		}, 200)
	}

	// Scroll selected into view on open
	useEffect(() => {
		if (props.isOpen) {
			if (activeItemIndex === -1 && preselectedIndex !== -1) {
				setActiveItemIndex(preselectedIndex)
				scrollItemIndexIntoView(preselectedIndex)
			} else if (activeItemIndex !== -1) {
				scrollItemIndexIntoView(activeItemIndex)
			}
		}
		if (!props.isOpen) {
			setActiveItemIndex(-1)
		}
	}, [props.isOpen])

	useEffect(() => {
		setActiveItemIndex(props.preselectedIndex ?? -1)
	}, [props.preselectedIndex])

	const updateSearchText = (key: string) => {
		if (searchTextTimeout.current) {
			window.clearInterval(searchTextTimeout.current)
		}

		searchText.current += key

		// search for match after current navIndex
		let matchedIndex = props.options.findIndex((item, index) => {
			const searchOnlyAfterCurrentSelection = searchText.current === '' ? index > activeItemIndex : true
			return (
				isSelectableOption(item) &&
				searchOnlyAfterCurrentSelection &&
				item.label.toLowerCase().startsWith(searchText.current)
			)
		})

		if (matchedIndex === -1) {
			matchedIndex = props.options.findIndex(
				(item) => isSelectableOption(item) && item.label.toLowerCase().startsWith(searchText.current)
			)
		}

		hasNavigatedByKeys.current = true
		if (matchedIndex !== -1) {
			setActiveItemIndex(matchedIndex)
		}

		searchTextTimeout.current = window.setTimeout(() => {
			searchText.current = ''
		}, 1000)
	}

	const onKeyDown = useCallback(
		(e: KeyboardEvent) => {
			if (!props.isOpen) {
				return
			}
			if (e.key === 'ArrowDown') {
				e.preventDefault()
				hasNavigatedByKeys.current = true
				const nextItemIndex = props.options.nextSelectableIndex(activeItemIndex)
				setActiveItemIndex(nextItemIndex)
				scrollItemIndexIntoView(nextItemIndex)
			} else if (e.key === 'ArrowUp') {
				e.preventDefault()
				hasNavigatedByKeys.current = true
				const nextItemIndex = props.options.prevSelectableIndex(activeItemIndex)
				setActiveItemIndex(nextItemIndex)
				scrollItemIndexIntoView(nextItemIndex)
			} else if (e.key === 'Tab' || e.key === 'Enter' || (!props.disableSelectWithSpace && e.key === ' ')) {
				if (e.key !== 'Tab') {
					e.preventDefault()
				}
				if (activeItemIndex === -1) {
					props.onClose?.()
					return
				}
				const option = props.options.get(activeItemIndex)
				if (option && isSelectableOption(option)) {
					props.onChange(option.value)
				}
			} else if (e.key === 'Escape' || (e.key === 'Tab' && props.multiSelect)) {
				e.preventDefault()
				e.stopPropagation()

				props.onClose?.()
			} else if (props.typeJumping && e.key.length === 1) {
				updateSearchText(e.key)
			}
		},
		[props.onChange, props.options, props.isOpen, activeItemIndex]
	)

	useEffect(() => {
		document.addEventListener('keydown', onKeyDown, true)
		return () => {
			document.removeEventListener('keydown', onKeyDown, true)
		}
	}, [onKeyDown])

	const onClick = (index: number) => {
		setActiveItemIndex(index)
		const option = props.options.get(index) as DropdownOptionSelectable<T>
		props.onChange(option.value)
	}

	const shouldDisplayNoItemsMessage =
		restrictValueToOptions && !props.isLoadingOptions && props.options.hasOptions && props.options.length === 0

	const mapMenuItems = (item: DropdownMenuItem<T>) => {
		if ('onAdvancedClick' in item) {
			const onClick = (e: React.MouseEvent | React.KeyboardEvent | undefined) => {
				const visibleItems = props.options
					.map((opt) => ('value' in opt && opt.value !== null ? opt.value : undefined))
					.filter((val) => val !== undefined)

				e &&
					item.onAdvancedClick(e, {
						visibleItems,
					})
			}
			const newItem: Omit<typeof item, 'onAdvancedClick'> & {
				onAdvancedClick?: (typeof item)['onAdvancedClick']
			} = {
				...item,
				onClick,
			}
			delete newItem.onAdvancedClick

			return newItem
		}
		return item
	}

	const headerLeftItems = props.headerLeftItems ? props.headerLeftItems.map(mapMenuItems) : undefined
	const headerRightItems = props.headerRightItems?.map(mapMenuItems)
	const footerLeftItems = props.footerLeftItems ? props.footerLeftItems.map(mapMenuItems) : undefined
	const footerRightItems = props.footerRightItems?.map(mapMenuItems)

	const onPopperCreate = (popper: HTMLDivElement) => {
		popperNode.current = popper
	}

	const onViewportChange = () => {
		if (props.disableCloseOnViewportChange) {
			scrollItemIndexIntoView(activeItemIndex)
			return
		}
		props.onClose && props.onClose()
	}

	useOnScrollOrResize(onViewportChange, popperNode, props.isOpen)

	const onOutsideClick = () => {
		props.onClose && props.onClose()
	}

	const applyPopperStyle = (data: PopperJS.Data) => {
		const dropdownListPanel = data.instance.popper as HTMLElement
		const dropdownTargetElement = data.instance.reference as HTMLElement

		if (!dropdownListPanel) {
			return
		}

		if (!dropdownTargetElement) {
			return
		}

		const dropdownTargetElementMeasurer = new Measurer(dropdownTargetElement)

		const requiredSize = getRequiredSize(dropdownListPanel)

		const windowBounds = {
			left: dropdownTargetElementMeasurer.left - dropdownTargetElementMeasurer.actualScreenSpaceLeft,
			top: dropdownTargetElementMeasurer.top - dropdownTargetElementMeasurer.actualScreenSpaceTop,
			right: dropdownTargetElementMeasurer.right + dropdownTargetElementMeasurer.actualScreenSpaceRight,
			bottom: dropdownTargetElementMeasurer.bottom + dropdownTargetElementMeasurer.actualScreenSpaceBottom,
		}

		dropdownListPanel.style.removeProperty('left')
		dropdownListPanel.style.removeProperty('top')
		dropdownListPanel.style.removeProperty('right')
		dropdownListPanel.style.removeProperty('bottom')
		dropdownListPanel.style.removeProperty('width')
		dropdownListPanel.style.removeProperty('height')
		dropdownListPanel.style.removeProperty('max-height')
		dropdownListPanel.style.removeProperty('max-width')
		dropdownListPanel.style.removeProperty('min-height')
		dropdownListPanel.style.removeProperty('min-width')

		let top: number | undefined = undefined
		let bottom: number | undefined = undefined
		let maxHeight: number | undefined = props.maxHeight

		if (dropdownTargetElementMeasurer.actualScreenSpaceBottom - SCREEN_INSET_TOPBOTTOM > requiredSize.height) {
			// the list can be placed below the target
			top = windowBounds.top + dropdownTargetElementMeasurer.bottom
		} else if (dropdownTargetElementMeasurer.actualScreenSpaceTop - SCREEN_INSET_TOPBOTTOM > requiredSize.height) {
			// the list can be placed above the target
			bottom = windowBounds.bottom - dropdownTargetElementMeasurer.top
		} else if (
			dropdownTargetElementMeasurer.actualScreenSpaceBottom - SCREEN_INSET_TOPBOTTOM >
			dropdownTargetElementMeasurer.actualScreenSpaceTop
		) {
			top = windowBounds.top + dropdownTargetElementMeasurer.bottom
			maxHeight = windowBounds.bottom - dropdownTargetElementMeasurer.bottom - SCREEN_INSET_TOPBOTTOM
		} else {
			top = SCREEN_INSET_TOPBOTTOM
			maxHeight = dropdownTargetElementMeasurer.top - windowBounds.top - SCREEN_INSET_TOPBOTTOM
		}

		if (top !== undefined) {
			if (topWindow.visualViewport) {
				top += topWindow.visualViewport.offsetTop
			}
			dropdownListPanel.style.top = `${top}px`
		}
		if (bottom !== undefined) {
			dropdownListPanel.style.bottom = `${bottom}px`
		}

		if (maxHeight !== undefined) {
			dropdownListPanel.style.maxHeight = `${maxHeight}px`
		}

		dropdownListPanel.style.right = `${windowBounds.right - dropdownTargetElementMeasurer.right}px`
		dropdownListPanel.style.width = `${props.width || dropdownTargetElementMeasurer.width}px`
		if (props.minWidth) {
			dropdownListPanel.style.minWidth = `${props.minWidth}px`
		}
	}

	return (
		<Popper
			anchorElement={props.anchorElement}
			className={classNames(classes.popper, classes[dropShadow])}
			onOutsideClick={onOutsideClick}
			onCreate={onPopperCreate}
			open={props.isOpen}
			enablePortal
			enableBackdrop
			fade
			placement={e_Placement.bottomEnd}
			applyStyle={applyPopperStyle}
		>
			<div id={props.id} className={classes.listWrapper} role="listbox" ref={listContainerRef}>
				{props.statusText && (
					<div className={(classes.statusText, props.className)} data-control="statustext">
						{props.statusText}
					</div>
				)}
				{(headerLeftItems || headerRightItems) && (
					<DropdownListMenu
						onClose={props.onClose}
						leftItems={headerLeftItems}
						rightItems={headerRightItems}
						type="header"
					/>
				)}
				<div className={classes.scrollableListContent}>
					<ul
						className={classNames(classes.list, props.className)}
						style={props.style}
						tabIndex={-1}
						ref={listRef}
						data-control="list"
					>
						{shouldDisplayNoItemsMessage && (
							<DropdownListItem label={props.emptyListMessage} type="custom" data-control="noitems" />
						)}

						{props.options &&
							props.options.map((item, index) => {
								const isActiveItem = activeItemIndex === index

								const isSelected = () => {
									if (!props.multiSelect) {
										return isSelectableOption(item) && props.value === item.value
									}
									if (isSelectableOption(item)) {
										return props.values?.has(item.value as T | null)
									}
									return false
								}

								return (
									<DropdownListItem
										id={props.id ? `${props.id}-${index}` : undefined}
										key={`item${index}`}
										index={index}
										onClick={onClick}
										onMouseEnter={() => updateNavIndex(index)}
										label={'label' in item ? item.label : undefined}
										screenTip={'screenTip' in item ? item.screenTip : undefined}
										type={item.type}
										isSelected={isSelected()}
										isPreselected={isActiveItem}
										disabled={'disabled' in item ? item.disabled : undefined}
										contentRender={'render' in item ? item.render : undefined}
										iconClassName={'iconClassName' in item ? item.iconClassName : undefined}
										reserveIconSpace={props.reserveIconSpace}
										multiSelect={props.multiSelect}
										dataAttributes={props.dataAttributes}
									/>
								)
							})}
					</ul>
				</div>
				{(footerLeftItems || footerRightItems) && (
					<DropdownListMenu
						onClose={props.onClose}
						leftItems={footerLeftItems}
						rightItems={footerRightItems}
						type="footer"
					/>
				)}
			</div>
		</Popper>
	)
}
