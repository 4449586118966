import type { GridApi, ColDef, ColGroupDef, Column } from '@ag-grid-community/core'
import type { TData, ITableRowData, ICellRendererProps } from '../Table.types'
import { isCellRendererNeeded } from './isCellRendererNeeded'
import { isCustomColumn } from './gridCallbacks'
import { CellRendererWrapper } from '../components/CellRenderer/CellRendererWrapper'

const getColumnAtFirstIndex = (api: GridApi<TData>) => {
	let colDef = api
		.getColumnDefs()
		?.find((column: ColDef) => !column.rowGroup && !column.hide && column.colId !== 'ag-Grid-AutoColumn')

	while ((colDef as ColGroupDef<TData>)?.children) {
		const children = (colDef as ColGroupDef<TData>)?.children
		colDef = children.find((column: ColDef) => !column.rowGroup && !column.hide)
	}

	return (colDef as ColDef<TData>)?.colId
		? api.getColumn((colDef as ColDef<TData>).colId as NonNullable<ColDef<TData>['colId']>) || undefined
		: undefined
}

const getCurrentCustomColumn = (api: GridApi) =>
	api.getColumns()?.find((column: Column<TData>) => isCustomColumn(column.getColDef()))

export const createUpdateFirstColumn = (contextMenuButton: ITableRowData['contextMenuButton']) => {
	return function updateFirstColumn(api: GridApi<TData>) {
		const currentCustomColumn = getCurrentCustomColumn(api)
		const columnAtFirstIndex = getColumnAtFirstIndex(api)

		if (columnAtFirstIndex?.getColId() === currentCustomColumn?.getColId()) {
			return
		}

		if (currentCustomColumn) {
			const currentCustomColumnDef = currentCustomColumn.getColDef()
			const currentCellRendererParams = currentCustomColumnDef.cellRendererParams as ICellRendererProps

			const _isCellRendererNeeded = isCellRendererNeeded({
				isGroup: false,
				hasCustomCellRenderer: !!currentCellRendererParams.onRenderCell,
				hasOnClick: !!currentCellRendererParams.onClick,
				renderType: currentCellRendererParams.renderType,
				hasIcon: !!currentCellRendererParams.iconPlacement,
				// We know by now that this column is not the first column so we can set multiSelect and hasFileDrag to false
				multiSelect: false,
				hasFileDrag: false,
				editable: currentCellRendererParams.readOnly !== true,
				hasContextMenu: currentCellRendererParams.hasContextMenuButton,
				interpretation: currentCellRendererParams.interpretation,
				fillVariant: currentCellRendererParams.fillVariant,
				rowDataOnCellClickScope: currentCellRendererParams.rowDataOnCellClickScope,
			})

			const newCellRendererParams: ICellRendererProps = {
				...currentCellRendererParams,
				hasContextMenuButton: false,
				isFirstColumn: false,
			}
			currentCustomColumnDef.cellRendererParams = newCellRendererParams
			currentCustomColumnDef.cellRenderer = _isCellRendererNeeded ? CellRendererWrapper : undefined
			currentCustomColumn.setColDef(currentCustomColumnDef, currentCustomColumnDef, 'uiColumnMoved')
		}

		// Set new column with mark
		if (columnAtFirstIndex) {
			const newCustomColumnDef = columnAtFirstIndex.getColDef()

			const currentCellRendererParams = newCustomColumnDef.cellRendererParams as ICellRendererProps

			const newCellRendererParams: ICellRendererProps = {
				...currentCellRendererParams,
				hasContextMenuButton: contextMenuButton === 'firstColumn' || contextMenuButton === 'inline',
				isFirstColumn: true,
			}

			newCustomColumnDef.cellRendererParams = newCellRendererParams

			newCustomColumnDef.cellRenderer = CellRendererWrapper
			newCustomColumnDef.suppressCellFlash = true

			columnAtFirstIndex.setColDef(newCustomColumnDef, newCustomColumnDef, 'uiColumnMoved')
		}
	}
}
