import { RuntimeRightPane } from 'components/RightPanel'
import { OperatorAppBar } from 'components/AppBar/OperatorAppBar'
import { OperatorMainPanel } from 'components/MainPanel/OperatorMainPanel'
import { OperatorNavigationPane } from 'components/NavigationPane/OperatorNavigationPane'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { e_OperatorStorageKeys } from 'src/enums/e_OperatorStorageKeys'
import { operatorFrontendActions } from './duck/operatorFrontendActions'
import { sessionStorageService } from 'src/modules/sessionStorageService/sessionStorageService'
import { ModalManager } from '../ModalManager'
import { operatorFrontendSelectors } from './duck/operatorFrontendSelectors'
import { SpinnerOverlay } from 'src/components/SpinnerOverlay'
import { authenticationActions } from '../Authentication/duck'
import { userSettingsSelectors } from '../UserSettings/duck/userSettingsSelectors'
import { createStyle } from '@genusbiz/web-ui/theming'
import { DragDropProvider } from '@genusbiz/web-ui/controls/utils/dragdrop/DragDropProvider'

const classes = createStyle((theme) => ({
	operatorContainer: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		background: theme.colors.body.backgroundAlt,
		color: theme.colors.body.text,
	},
	operatorContentContainer: {
		flex: 1,
		display: 'flex',
		overflow: 'hidden',
	},
	operatorMainContentContainer: {
		flex: 1,
		display: 'flex',
		overflow: 'auto',
	},
}))

export const OperatorFrontend = () => {
	const dispatch = useDispatch()

	const isInitialized = useSelector(operatorFrontendSelectors.selectIsInitialized)

	const [showUserSettings, setShowUserSettings] = useState(false)

	const toggleShowUserSettings = () => {
		setShowUserSettings(!showUserSettings)
	}

	const signOut = () => {
		sessionStorageService.write(e_OperatorStorageKeys.persistSession, false)

		// Reset state to initialState
		dispatch(authenticationActions.signOut())
		dispatch(operatorFrontendActions.setInitialized())
	}

	const closeRightBar = () => {
		setShowUserSettings(false)
	}
	// const location =
	// 	props.location === e_NavigationItem.workloads
	// 		? (window.location.pathname.substring(1) as e_NavigationItem)
	// : props.location

	const displayFormat = useSelector(userSettingsSelectors.selectPreferredFormattingCulture)
	return (
		<DragDropProvider>
			<ModalManager />
			{!isInitialized && <SpinnerOverlay />}
			{isInitialized && (
				<div key={displayFormat} className={classes.operatorContainer}>
					<OperatorAppBar showUserSettings={showUserSettings} toggleShowUserSettings={toggleShowUserSettings} />
					<div className={classes.operatorContentContainer}>
						<OperatorNavigationPane />
						<main className={classes.operatorMainContentContainer}>
							<OperatorMainPanel />
							{showUserSettings && (
								<RuntimeRightPane showUserSettings={showUserSettings} closeRightBar={closeRightBar} signOut={signOut} />
							)}
						</main>
					</div>
				</div>
			)}
		</DragDropProvider>
	)
}
