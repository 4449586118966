import React, { useEffect, useRef, useState } from 'react'
import type { IFormControl } from '../FormControl'
import { FormControl, FormControlButton } from '../FormControl'
import { DurationInput } from '../DurationInput'
import type { IInternalDateTimeValue } from './DateInput'
import {
	DateInput,
	getInternalFormatFromDateTimeValue,
	getDateTimeFromInternalFormat,
	getDateFromInternalFormat,
} from './DateInput'
import { createStyle } from '../../theming'
import { useDataAttributes } from '../utils/useDataAttributes'
import { type e_TextAlignment } from '../../enums/e_TextAlignment'
import { useId } from '../hooks/useId'

interface IDateTimeInputProps extends IFormControl {
	value?: string
	placeholder: string
	dateFormat: string
	textAlignment?: e_TextAlignment
	datePickerClassName?: string
	onChange: (value?: string) => void
	onBlur?: (value?: string) => void
	actionButtonRef?: React.Ref<HTMLButtonElement>
}

const classes = createStyle({
	dateInput: {
		flex: 3,
		marginRight: 8,
	},
	timeInput: {
		flex: 2,
	},
})

export const DateTimeInput = (props: IDateTimeInputProps) => {
	const { actionIcon = 'Fluent-Forward' } = props
	const dataAttributesDateInput = useDataAttributes(props.dataAttributes, 'date-input')
	const dataAttributesDurationInput = useDataAttributes(props.dataAttributes, 'duration-input')

	const internalValue = useRef<IInternalDateTimeValue | undefined>()
	const [date, setDate] = useState<string>()
	const [timeOfDay, setTimeOfDay] = useState<number>()
	const [internalDateInputFormat, setInternalDateInputFormat] = useState<string>()

	const [logicalHoverDuration, setLogicalHoverDuration] = useState(false)
	const [logicalHoverDate, setLogicalHoverDate] = useState(false)
	const [logicalFocusDuration, setLogicalFocusDuration] = useState(false)
	const [logicalFocusDate, setLogicalFocusDate] = useState(false)

	const actionButtonDisabled = !props.value || props.disabled

	useEffect(() => {
		// a new value has been passed in
		internalValue.current = getInternalFormatFromDateTimeValue(props.value)
		updateDateInputValue()
		updateDurationInputValue()
	}, [props.value])

	useEffect(() => {
		switch (props.dateFormat) {
			case 'longDateTime':
			case 'longDateTimeWithoutSeconds':
			case 'longDate':
				setInternalDateInputFormat('longDate')
				break
			default:
				setInternalDateInputFormat('shortDate')
				break
		}
	}, [props.dateFormat])

	const updateDateInputValue = () => {
		if (internalValue.current) {
			const dateValue = {
				...internalValue.current,
				hour: undefined,
				minute: undefined,
				second: undefined,
				millisecond: undefined,
			}
			const newDate = getDateFromInternalFormat(dateValue)
			setDate(newDate)
		} else {
			setDate(undefined)
		}
	}

	const updateDurationInputValue = () => {
		const integerTimeValue =
			internalValue.current?.hour !== undefined && internalValue.current?.minute !== undefined
				? internalValue.current.hour * 100 + internalValue.current.minute
				: undefined

		setTimeOfDay(integerTimeValue)
	}

	const updateTimeInInternalValue = (integerTimeValue?: number | null | undefined) => {
		const hours = integerTimeValue ? Math.floor(integerTimeValue / 100) : undefined
		const minutes = integerTimeValue ? Math.floor(integerTimeValue % 100) : undefined

		const dateValue = {
			...internalValue.current,
			hour: hours,
			minute: minutes,
			second: undefined,
			millisecond: undefined,
		}
		updateValue(dateValue)
	}

	const updateDateInInternalValue = (value?: string | null) => {
		const newDate = getInternalFormatFromDateTimeValue(value)

		const dateValue = {
			...internalValue.current,
			year: newDate?.year,
			month: newDate?.month,
			day: newDate?.day,
		}
		updateValue(dateValue)
	}

	const updateDateInInternalValueOnBlur = (value?: string | null) => {
		const newDate = getInternalFormatFromDateTimeValue(value)

		const dateValue = {
			...internalValue.current,
			year: newDate?.year,
			month: newDate?.month,
			day: newDate?.day,
		}

		if (!isValidDateTime(dateValue)) {
			if (!isValidDate(dateValue)) {
				updateValue(undefined)
			}
			return
		}
		updateValue(dateValue)
	}

	const updateValue = (value: IInternalDateTimeValue | undefined) => {
		internalValue.current = value

		if (!isValidDate(value)) {
			//Not a valid date
			return
		}

		props.onChange(getDateTimeFromInternalFormat(internalValue.current))
	}

	const isValidDate = (date: IInternalDateTimeValue | undefined) => {
		if (date === undefined) {
			return true
		}
		if (date.day === undefined || date.month === undefined || date.year === undefined) {
			return false
		}
		return true
	}

	const isValidDateTime = (date: IInternalDateTimeValue | undefined) => {
		if (!isValidDate(date) || date?.minute !== undefined || date?.hour !== undefined) {
			return false
		}
	}

	const handleSetLogicalFocusDuration = (focus: boolean) => {
		if (logicalHoverDuration && focus === true) {
			setLogicalHoverDuration(false)
		}
		setLogicalFocusDuration(focus)
	}
	const handleSetLogicalFocusDate = (focus: boolean) => {
		if (logicalHoverDate && focus === true) {
			setLogicalHoverDate(false)
		}
		setLogicalFocusDate(focus)
	}

	const id = useId('date-input', props.id)

	return (
		<FormControl
			id={id}
			label={props.label}
			labelPosition={props.labelPosition}
			labelProps={props.labelProps}
			labelBold={props.labelBold}
			labelIcon={props.labelIcon}
			labelIconColor={props.labelIconColor}
			labelContentLayout={props.labelContentLayout}
			hideLabel={props.hideLabel}
			disableBorder
			disabled={props.disabled}
			className={props.className}
			labelSubText={props.labelSubText}
			validationText={props.validationText}
			validationTextPosition={props.validationTextPosition}
			subText={props.subText}
			reserveHelperTextSpace={props.reserveHelperTextSpace}
			readOnly={props.readOnly}
			error={props.error}
			warning={props.warning}
			required={props.required}
			screenTip={props.screenTip}
		>
			<DateInput
				dateFormat={internalDateInputFormat || ''}
				type="date"
				value={date}
				placeholder={props.placeholder}
				onChange={updateDateInInternalValue}
				onBlur={updateDateInInternalValueOnBlur}
				textAlignment={props.textAlignment}
				datePickerClassName={props.datePickerClassName}
				className={classes.dateInput}
				disableBorder={props.disableBorder}
				disabled={props.disabled}
				error={props.error}
				id={`${id}-date`}
				dataAttributes={dataAttributesDateInput}
				margin={props.margin}
				name={props.name}
				readOnly={props.readOnly}
				warning={props.warning}
				required={props.required}
				logicalHover={logicalHoverDate}
				setLogicalHover={setLogicalHoverDuration}
				logicalFocus={logicalFocusDate}
				setLogicalFocus={handleSetLogicalFocusDuration}
			/>
			<DurationInput
				value={timeOfDay}
				onChange={updateTimeInInternalValue}
				isIntegerTime
				enableOptionDropdown
				textAlignment={props.textAlignment}
				className={classes.timeInput}
				disableBorder={props.disableBorder}
				disabled={props.disabled}
				error={props.error}
				id={`${id}-time`}
				dataAttributes={dataAttributesDurationInput}
				margin={props.margin}
				name={props.name}
				readOnly={props.readOnly}
				warning={props.warning}
				required={props.required}
				logicalHover={logicalHoverDuration}
				setLogicalHover={setLogicalHoverDate}
				logicalFocus={logicalFocusDuration}
				setLogicalFocus={handleSetLogicalFocusDate}
				cannotBeBlank={!!date}
			/>
			{props.defaultAction && !props.hideActionButton && (
				<FormControlButton
					iconClassName={actionIcon}
					screenTip={props.actionScreenTip}
					onClick={!actionButtonDisabled ? props.defaultAction : undefined}
					disabled={actionButtonDisabled}
					ref={props.actionButtonRef}
					dataAttributes={props.dataAttributes}
				/>
			)}
		</FormControl>
	)
}
