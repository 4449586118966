import React, { useRef, useState } from 'react'

import { createStyle } from '../../../../theming'

import {
	CellRendererDate,
	CellRendererDropdown,
	CellRendererDuration,
	CellRendererLookup,
	CellRendererNumber,
	CellRendererText,
	CellRendererViewer,
} from './cellRenderers'

import { tDataToRowData } from '../../utils'

import { e_DateFormat } from '../../enums/e_DateFormat'
import type { CellData, ICellRendererProps, ITableContext, Option, TData } from '../../Table.types'
import { e_RenderType } from '../../Table.types'
import type { IFilterEvent } from '../../../LookupInput'
import type { CustomCellEditorProps } from '@ag-grid-community/react'
import { getRowNodeId } from './getRowNodeId'

const classes = createStyle({
	control: {
		height: '100%',
		width: '100%',
		'& > div': { height: '100%', width: '100%', lineHeight: '20px' },
	},
})
const inputCharacters = /^[A-Za-zŽžÀ-ÿ1234567890!"£$%^&*()_+-=[\];'#,./\\|<>?:@~{}]$/

export const CellRendererControl = (
	props: ICellRendererProps & CustomCellEditorProps<TData, CellData, ITableContext>
) => {
	const {
		cellEditingProps,
		// eslint-disable-next-line destructuring/no-rename
		value: cellData,
		interpretation,
		filterOperator,
		dataType,
		renderType,
		node,
		allowNull = false,
		isPercentNumber = false,
	} = props

	const id = getRowNodeId(node)

	// const rowHeight = useMemo(() => {
	// 	return (props.node.rowHeight ?? 0) - 1
	// }, [props.node.rowHeight])

	// const classes = useStyles(rowHeight)

	const colId = props.column?.getId() || ''

	const [options, setOptions] = useState<Option[]>([])
	const [isLoadingOptions, setIsLoadingOptions] = useState(false)

	const onOptionsReceived = (query: string | undefined) => (options: Option[]) => {
		setOptions(options)
		setIsLoadingOptions(false)

		if (query) {
			// Cache Lookup Options
			lookupOptionsCache.current.set(query, options)
		}
	}

	const lookupOptionsCache = useRef(new Map<string, Option[]>())
	const onNeedOptions = (event?: IFilterEvent) => {
		if (event?.filterTerm && lookupOptionsCache.current.has(event.filterTerm)) {
			// Get Lookup Options from cache
			setOptions([...(lookupOptionsCache.current.get(event.filterTerm) as Option[])])
		} else {
			setIsLoadingOptions(true)
			cellEditingProps?.onNeedOptions?.(id, colId, event?.filterTerm, onOptionsReceived(event?.filterTerm))
		}
	}

	const onClick = props.onClick
		? (targetEl: React.RefObject<HTMLElement>) => {
				const rowData = tDataToRowData(props.node.data)
				if (rowData) {
					if (props.onClick) {
						props.onClick(rowData, targetEl)
					}
				}
		  }
		: undefined

	const hasError = !!cellData?.error

	if (props.node.footer) {
		return <CellRendererViewer value={props.value?.formattedValue?.toString()} />
	}

	const stopEditing = () => {
		props.api.stopEditing()
		props.api.setFocusedCell(props.rowIndex, props.column.getColId())
	}

	switch (renderType) {
		case e_RenderType.text:
			return (
				<CellRendererText
					key={id}
					value={cellData?.value?.toString()}
					setValue={(v) => props.onValueChange({ value: v })}
					interpretation={interpretation}
					className={classes.control}
					hasError={hasError}
					onClick={onClick}
					onBlur={stopEditing}
					initialKey={props.eventKey && inputCharacters.test(props.eventKey) ? props.eventKey : undefined}
				/>
			)

		case e_RenderType.number:
			return (
				<CellRendererNumber
					key={id}
					value={cellData?.value as number}
					dataType={props.dataType as 'int' | 'float'}
					setValue={(v) => props.onValueChange({ value: v })}
					className={classes.control}
					isPercentNumber={isPercentNumber}
					hasError={hasError}
					onClick={onClick}
					onBlur={stopEditing}
					initialKey={props.eventKey?.length === 1 ? props.eventKey : undefined}
				/>
			)

		case e_RenderType.dropdown: {
			return (
				<CellRendererDropdown
					key={id}
					value={cellData?.value}
					displayValue={props.value?.formattedValue?.toString()}
					filterOperator={filterOperator}
					options={options}
					allowNull={allowNull}
					onNeedOptions={onNeedOptions}
					setValue={(v) =>
						props.onValueChange({ value: v, formattedValue: options.find((opt) => opt.value === v)?.label })
					}
					onClose={stopEditing}
					isLoadingOptions={isLoadingOptions}
					className={classes.control}
					hasError={hasError}
					onClick={onClick}
					initialFilterTerm={props.eventKey && inputCharacters.test(props.eventKey) ? props.eventKey : undefined}
				/>
			)
		}
		case e_RenderType.lookup: {
			return (
				<CellRendererLookup
					key={id}
					value={cellData?.value?.toString() ?? undefined}
					displayLabel={cellData?.formattedValue?.toString()}
					onNeedOptions={onNeedOptions}
					options={options}
					isLoadingOptions={isLoadingOptions}
					setValue={(v) => props.onValueChange({ ...props.value, value: v })}
					onClose={stopEditing}
					className={classes.control}
					hasError={hasError}
					onClick={onClick}
					initialFilterTerm={props.eventKey && inputCharacters.test(props.eventKey) ? props.eventKey : undefined}
				/>
			)
		}
		case e_RenderType.date:
			return (
				<CellRendererDate
					key={id}
					value={cellData?.value?.toString()}
					dataType={dataType}
					setValue={(v) => {
						props.onValueChange({ ...props.value, value: v, formattedValue: props.formatCellValue?.(v) })
					}}
					format={(props.format || e_DateFormat.default) as e_DateFormat}
					formatValue={props.formatValue}
					className={classes.control}
					hasError={hasError}
					onClick={onClick}
					context={props.context}
					api={props.api}
					initialKey={props.eventKey?.length === 1 ? props.eventKey : undefined}
				/>
			)

		case e_RenderType.duration:
			return (
				<CellRendererDuration
					key={id}
					value={cellData?.value as number}
					interpretation={props.interpretation}
					setValue={(v) => props.onValueChange({ value: v, formattedValue: props.formatCellValue?.(v) })}
					className={classes.control}
					hasError={hasError}
					onClick={onClick}
					initialKey={props.eventKey?.length === 1 ? props.eventKey : undefined}
					context={props.context}
					onClose={stopEditing}
				/>
			)

		case e_RenderType.none:
			return null
		default:
			return <span key={id}>{'<Unknown render type>'}</span>
	}
}
