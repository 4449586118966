import React from 'react'
import { DetailPanel } from './DetailPanel'
import { MasterPanel } from './MasterPanel'
import type { IDetailFieldDescription, ITableFieldDescription } from '../types/IDataPropertyDescription'
import type { ISortData } from '../types/ISortData'
import { createStyle } from '../../../theming'
import type { IDataWithIdentifier } from '../types/IDataWithIdentifier'
import type { DetailItemSurface } from '../types/IDetailItemSurfaceProps'
import type { IActionEntry } from '../types/IActionDescription'
import type { IMasterDetailPreakpoint } from '../types/IMasterDetailBreakpoint'
import type {
	FormatDataValueEventHandler,
	GetFormattingStylesEventHandler,
	GetIconValueEventHandler,
} from '../types/MasterDetailEventHandlers'
import type { e_ListItemLayout } from '../types/e_ListItemLayout'
import { DETAIL_VIEW_MASTER_PANEL_WIDTH } from '../MasterDetailSurface'

const classes = createStyle({
	detailView: {
		flex: 1,
		display: 'grid',
		gridTemplateColumns: 'auto 1fr',
		gridTemplateRows: '1fr',
		gridTemplateAreas: '"list detail"',
		minHeight: 0,
	},

	withBorder: {
		background: 'white',
		border: '1px solid silver',
		borderRadius: '5px',
	},

	withShadow: {
		boxShadow: `0 6.4px 14.4px 0 rgba(${'0,0,0'}, 0.132), 0 1.2px 3.6px 0 rgba(${'0,0,0'}, 0.108)`,
	},

	leftMargin: {
		marginLeft: '16px',
	},

	rightMargin: {
		marginLeft: '16px',
	},
})

interface IDetailViewProps {
	title: string
	listTitle: string
	items: IDataWithIdentifier[]

	selectedIds: string[]
	setSelectedIds: (ids: string[]) => void

	activeId: string | undefined
	setActiveId: (id: string | undefined) => void
	/** Used to stop change in active ID */
	lockActiveID?: boolean
	itemName?: string | undefined | ((item: IDataWithIdentifier) => string | undefined)
	avatarContent?: string | ((item: IDataWithIdentifier) => JSX.Element | string)
	getFormattingStyles?: GetFormattingStylesEventHandler
	formatDataValue?: FormatDataValueEventHandler
	summaryContent?: string | ((item: IDataWithIdentifier) => string)

	columns: ITableFieldDescription[]
	fieldDescriptions: IDetailFieldDescription[]
	sortedColumns?: ISortData[]
	sortColumns?: (sortedColumns: ISortData[]) => void

	filterText?: string
	setFilterText?: (filterTExt: string) => void

	isMultiSelect?: boolean
	setIsMultiSelect?: (multiSelect: boolean) => void
	breakpoint: IMasterDetailPreakpoint

	singleItemActions?: IActionEntry[]
	multiItemActions?: IActionEntry[]
	generalActions?: IActionEntry[]

	handleGeneralAction?: (actionId: string) => void

	handleSingleItemAction?: (actionId: string, itemId: string) => void
	handleMultiItemAction?: (actionId: string, itemIds: string[]) => void

	useZebraStripes?: boolean
	useCompactView?: boolean
	enableItemFormatting?: boolean
	emptyListMessage?: string

	getIconValue?: GetIconValueEventHandler

	listItemTemplate?: e_ListItemLayout
	detailComponent?: DetailItemSurface
	renderDetailComponent?: (
		item: IDataWithIdentifier,
		breakpoint: IMasterDetailPreakpoint
	) => React.ReactNode | undefined
	back?: () => void
}

export const DetailView = React.forwardRef((props: IDetailViewProps, forwardedRef: React.Ref<HTMLDivElement>) => {
	return (
		<div className={classes.detailView} ref={forwardedRef}>
			<MasterPanel
				items={props.items}
				selectedIds={props.selectedIds}
				setSelectedIds={props.setSelectedIds}
				activeId={props.activeId}
				setActiveId={props.setActiveId}
				title={props.listTitle}
				back={props.back}
				width={DETAIL_VIEW_MASTER_PANEL_WIDTH}
				itemName={props.itemName}
				getFormattingStyles={props.getFormattingStyles}
				formatDataValue={props.formatDataValue}
				listItemTemplate={props.listItemTemplate}
				avatarContent={props.avatarContent}
				summaryContent={props.summaryContent}
				getIconValue={props.getIconValue}
				columns={props.columns}
				sortedColumns={props.sortedColumns}
				sortColumns={props.sortColumns}
				filterText={props.filterText}
				setFilterText={props.setFilterText}
				isMultiSelect={props.isMultiSelect}
				setIsMultiSelect={props.setIsMultiSelect}
				singleItemActions={props.singleItemActions}
				handleSingleItemAction={props.handleSingleItemAction}
				generalActions={props.generalActions}
				handleGeneralAction={props.handleGeneralAction}
				multiItemActions={props.multiItemActions}
				handleMultiItemAction={props.handleMultiItemAction}
				useZebraStripes={props.useZebraStripes}
				useCompactView={props.useCompactView}
				enableItemFormatting={props.enableItemFormatting}
				gridArea="list"
				emptyListMessage={props.emptyListMessage}
				lockActiveID={props.lockActiveID}
			/>
			<DetailPanel
				items={props.items}
				fieldDescriptions={props.fieldDescriptions}
				activeId={props.activeId}
				title={props.title}
				itemName={props.itemName}
				breakpoint={props.breakpoint}
				detailComponent={props.detailComponent}
				renderDetailComponent={props.renderDetailComponent}
				singleItemActions={props.singleItemActions}
				handleSingleItemAction={props.handleSingleItemAction}
				formatDataValue={props.formatDataValue}
				selectedIds={props.selectedIds}
				setSelectedIds={props.setSelectedIds}
				setActiveId={props.setActiveId}
				leftBorder
				gridArea="detail"
			/>
		</div>
	)
})

DetailView.displayName = 'DetailView'
