import React from 'react'
import { FieldContainer, GridLayout } from '@genusbiz/web-ui/surfaces'
import { e_GridTrackType } from '@genusbiz/web-ui/surfaces/Grid/gridTypes'
import { e_Overflow } from '@genusbiz/web-ui/enums/e_Overflow'
import { e_TextAlignment } from '@genusbiz/web-ui/enums/e_TextAlignment'
import { DisplayField } from '@genusbiz/web-ui/controls/Field/DisplayField'
import { Dropdown, RadioButtonGroup, Switch, TextInput } from '@genusbiz/web-ui/controls'
import { IDataPartition } from 'src/interfaces/IK8sRuntime'
import { useTranslation } from 'react-i18next'
import { e_EnvironmentType, e_EnvironmentTypeName } from 'src/enums/e_EnvironmentType'
import { capitalize } from 'src/utils/capitalize'
import { IEditableDetailComponentProps } from '@genusbiz/web-ui/surfaces/EditableMasterDetail/types/detailConfigTypes'

interface RuntimeDetailEditProps extends IEditableDetailComponentProps {
	mode: 'edit' | 'create'
	partitions: IDataPartition[]
}

export const RuntimeDetailEdit = (props: RuntimeDetailEditProps) => {
	const { mode, partitions } = props
	const item = props.dataItem

	const { t } = useTranslation()

	const EMPTY_CONTENT = '---'

	const gridConfig = [
		{
			rows: [
				{ type: e_GridTrackType.auto, length: 1 },
				{ type: e_GridTrackType.auto, length: 1 },
			],
			columns: [{ type: e_GridTrackType.fraction, length: 1 }],
			areas: {
				['outer-grid']: undefined,
				['feature-grid']: undefined,
				['general-properties-grid']: undefined,
			},
		},
	]
	const runtimeName = item['name'] as string

	const partitionId = item['dataPartitionId'] as string
	const partition = partitions.find((partition) => partition.id === partitionId)
	const partitionName = partition?.name

	const environmentType = item['environmentType'] as number
	const environmentTypeName = capitalize(e_EnvironmentType[environmentType])
	const environmentOptions = Object.keys(e_EnvironmentTypeName).filter(
		(type) => (type as e_EnvironmentTypeName) !== e_EnvironmentTypeName.operator
	)

	type BooleanEntries =
		| 'CardDavService'
		| 'DataMartService'
		| 'MailService'
		| 'MessageQueueService'
		| 'RestService'
		| 'ScheduledActionService'
		| 'WebCalService'
		| 'XmlService'

	const CardDavService = item['CardDavService'] as boolean
	const DataMartService = item['DataMartService'] as boolean
	const MailService = item['MailService'] as boolean
	const MessageQueueService = item['MessageQueueService'] as boolean
	const RestService = item['RestService'] as boolean
	const ScheduledActionService = item['ScheduledActionService'] as boolean
	const WebCalService = item['WebCalService'] as boolean
	const XmlService = item['XmlService'] as boolean

	const toggleFeature = (feature: BooleanEntries, currentValue: boolean) => {
		const newValue = { [feature]: !currentValue }
		props.updateLocalChanges(newValue)
	}

	const updateEnvironmentType = (environmentType: e_EnvironmentType) => {
		const environmentTypeName = e_EnvironmentType[environmentType]
		const newValue = { environmentType, environmentTypeName }
		props.updateLocalChanges(newValue)
	}

	const updateName = (name: string) => {
		const newValue = { name }
		props.updateLocalChanges(newValue)
	}

	const updatePartitionId = (dataPartitionId: string) => {
		const newValue = { dataPartitionId }
		props.updateLocalChanges(newValue)
	}

	return (
		<GridLayout gridConfig={gridConfig}>
			<FieldContainer groupId="all" data-gridname={'outer-grid'} padding={10} gap={4}>
				{mode === 'create' ? (
					<FieldContainer groupId="all" data-gridname={'general-properties-grid'}>
						<TextInput
							label={t('GENERAL:NAME')}
							textAlignment={e_TextAlignment.left}
							value={runtimeName}
							onChange={updateName}
							placeholder={EMPTY_CONTENT}
							required
						/>
						<Dropdown
							label={t('RUNTIMES:DATA_PARTITION')}
							value={partitionId}
							options={partitions.map((partition) => {
								return { label: partition.name, value: partition.id }
							})}
							onChange={updatePartitionId}
							required
						/>
						<RadioButtonGroup
							label={t('RUNTIMES:ENVIRONMENT')}
							value={environmentType as e_EnvironmentType}
							orientation="horizontal"
							onChange={updateEnvironmentType}
							options={environmentOptions.map((type) => {
								return {
									label: capitalize(type),
									value: e_EnvironmentType[type as keyof typeof e_EnvironmentType],
								}
							})}
							required
						/>
					</FieldContainer>
				) : (
					<FieldContainer groupId="all" data-gridname={'general-properties-grid'}>
						<DisplayField
							label={t('GENERAL:NAME')}
							content={runtimeName}
							overflow={e_Overflow.noScrollbars}
							textAlignment={e_TextAlignment.left}
							disableBorder
							labelBold
						/>
						<DisplayField
							label={t('RUNTIMES:DATA_PARTITION')}
							content={partitionName}
							overflow={e_Overflow.noScrollbars}
							textAlignment={e_TextAlignment.left}
							disableBorder
							labelBold
						/>
						<DisplayField
							label={t('RUNTIMES:ENVIRONMENT')}
							content={environmentTypeName}
							overflow={e_Overflow.noScrollbars}
							textAlignment={e_TextAlignment.left}
							disableBorder
							labelBold
						/>
					</FieldContainer>
				)}

				<FieldContainer groupId="all" header="Features" data-gridname={'feature-grid'}>
					<Switch
						label={t('FEATURES:CARDDAVSERVICE')}
						checked={CardDavService}
						valueLabel={CardDavService ? 'Enabled' : 'Disabled'}
						onChange={() => toggleFeature('CardDavService', CardDavService)}
					/>
					<Switch
						label={t('FEATURES:DATAMARTSERVICE')}
						checked={DataMartService}
						valueLabel={DataMartService ? 'Enabled' : 'Disabled'}
						onChange={() => toggleFeature('DataMartService', DataMartService)}
					/>
					<Switch
						label={t('FEATURES:MAILSERVICE')}
						checked={MailService}
						valueLabel={MailService ? 'Enabled' : 'Disabled'}
						onChange={() => toggleFeature('MailService', MailService)}
					/>
					<Switch
						label={t('FEATURES:MESSAGEQUEUESERVICE')}
						checked={MessageQueueService}
						valueLabel={MessageQueueService ? 'Enabled' : 'Disabled'}
						onChange={() => toggleFeature('MessageQueueService', MessageQueueService)}
					/>
					<Switch
						label={t('FEATURES:RESTSERVICE')}
						checked={RestService}
						valueLabel={RestService ? 'Enabled' : 'Disabled'}
						onChange={() => toggleFeature('RestService', RestService)}
					/>
					<Switch
						label={t('FEATURES:SCHEDULEDACTIONSERVICE')}
						checked={ScheduledActionService}
						valueLabel={ScheduledActionService ? 'Enabled' : 'Disabled'}
						onChange={() => toggleFeature('ScheduledActionService', ScheduledActionService)}
					/>
					<Switch
						label={t('FEATURES:WEBCALSERVICE')}
						checked={WebCalService}
						valueLabel={WebCalService ? 'Enabled' : 'Disabled'}
						onChange={() => toggleFeature('WebCalService', WebCalService)}
					/>
					<Switch
						label={t('FEATURES:XMLSERVICE')}
						checked={XmlService}
						valueLabel={XmlService ? 'Enabled' : 'Disabled'}
						onChange={() => toggleFeature('XmlService', XmlService)}
					/>
				</FieldContainer>
			</FieldContainer>
		</GridLayout>
	)
}
