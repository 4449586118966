import type { CSSProperties } from 'react'
import React, { useState, useEffect, useCallback, useRef } from 'react'
import classNames from 'clsx'

import { useTranslation } from '../../../../translation'
import { Button } from '../../../Button'
import type { IIconNames } from '@genusbiz/icon-set/dist/utils'

// Enums and Interfaces
import { e_SortOrder } from '../../enums/e_SortOrder'
import { MAX_HEADER_HEIGHT, VERTICAL_HEADER_BASE_HEIGHT, VERTICAL_HEADER_PADDING } from '../../consts'
import type { ITableContext, TData } from '../../Table.types'
import type { IHeaderParams } from '@ag-grid-community/core'
import { ColumnHeaderContent } from './ColumnHeaderContent'
import { HeaderCheckMark } from '../HeaderCheckMark'
import { createStyle } from '../../../../theming'

const classes = createStyle((theme) => ({
	columnHeaderWrapper: {
		display: 'flex',
		height: '100%',
		flexGrow: 1,
		alignItems: 'center',
		gap: 4,
		overflow: 'hidden',
		'&:hover': { '& > :last-child': { background: theme.colors.list.hoveredBackground, visibility: 'visible' } },
	},
	columnHeaderClickable: {
		cursor: 'pointer',
		'&:active': { background: theme.colors.list.pressedBackground },
	},
	columnHeader: {
		display: 'flex',
		height: '100%',
		alignItems: 'center',
		flexGrow: 1,
		overflow: 'hidden',
		gap: 4,
	},
	columnHeaderAuto: { marginLeft: 26 },

	columnHeaderTiltedBase: {
		position: 'absolute',
		width: '100%',
		bottom: 0,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		paddingBottom: 4,
	},
	columnHeaderTiltedWrapper: {
		position: 'absolute',
		overflow: 'visible',
		left: 'calc(50% - 0.15em)',
	},

	textAlignCenter: { justifyContent: 'center' },
	textAlignRight: { justifyContent: 'flex-end' },
	iconRight: { marginLeft: '4px' },
	iconLeft: { marginRight: '4px' },
	menuButtonWrapper: {
		display: 'flex',
		alignSelf: 'stretch',
		visibility: 'hidden',
		position: 'absolute',
		height: '100%',
	},
	menuButton: {
		margin: '5px',
	},
	menuButtonWrapperActiveFilter: { position: 'static', visibility: 'visible' },
}))

export interface ITableColumnHeaderProps {
	textAlignment?: 'right' | 'left' | 'center'
	iconPlacement?: 'before' | 'after'
	screenTip?: string
	iconName?: string
	hideLabel?: boolean
	disableFilter?: boolean
	inPreviewMode?: boolean
	isVertical?: boolean
	disableMenu?: boolean
	multiSelect?: boolean
	cellStyle?: CSSProperties
}

export const AutoGroupColumnHeader = (props: ITableColumnHeaderProps & IHeaderParams<TData, ITableContext>) => {
	const { tcvi } = useTranslation()

	const { disableFilter, enableSorting, hideLabel = false, showColumnMenu, textAlignment = 'left', setSort } = props

	const [sorting, setSorting] = useState(props.column.getSort() ?? undefined)
	const [filterActive, setFilterActive] = useState(props.column.isFilterActive())

	const filterIsAllowed = props.column.isFilterAllowed() && !disableFilter

	const menuButtonAnchor = useRef<HTMLDivElement>(null)
	const mounted = useRef(true)

	const onClick = useCallback(
		(e: React.MouseEvent | React.TouchEvent | KeyboardEvent) => {
			if (!enableSorting) {
				return
			}

			switch (sorting) {
				case e_SortOrder.asc:
					setSort(e_SortOrder.desc, e.shiftKey)
					break
				case e_SortOrder.desc:
					setSort(e_SortOrder.asc, e.shiftKey)
					break
				default:
					setSort(e_SortOrder.asc, e.shiftKey)
					break
			}
		},
		[setSort, enableSorting, sorting]
	)

	const onContextMenu = useCallback(
		(event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			if (!filterIsAllowed) {
				return
			}

			event?.preventDefault()

			const anchor = event?.currentTarget || menuButtonAnchor.current

			if (anchor) {
				showColumnMenu(anchor)
			}
		},
		[filterIsAllowed, showColumnMenu]
	)

	const onKeyDown = useCallback(
		(e: KeyboardEvent) => {
			if (e.key === 'Enter') {
				if (e.ctrlKey) {
					onContextMenu()

					return
				}

				onClick(e)
			}
		},
		[onClick, onContextMenu]
	)

	const onSortChange = useCallback(() => {
		if (!mounted.current) {
			return
		}

		setSorting(props.column.getSort() ?? undefined)
	}, [props.column])

	const onFilterChange = useCallback(() => {
		const filterIsActive = props.column.isFilterActive()
		if (filterIsActive) {
			mounted.current && setFilterActive(true)
		} else {
			mounted.current && setFilterActive(false)
		}
	}, [props.column])

	useEffect(() => {
		props.eGridHeader.addEventListener('keydown', onKeyDown)
		props.column.addEventListener('sortChanged', onSortChange)
		props.column.addEventListener('filterChanged', onFilterChange)
		return () => {
			props.eGridHeader.removeEventListener('keydown', onKeyDown)
			props.column.removeEventListener('sortChanged', onSortChange)
			props.column.removeEventListener('filterChanged', onFilterChange)
		}
	}, [onFilterChange, onSortChange, onKeyDown, props.eGridHeader, props.column])

	useEffect(() => {
		return () => {
			mounted.current = false
		}
	}, [])

	const columnId = props.column.getColId()
	const isAutoGroup = columnId === 'ag-Grid-AutoColumn'
	const textAlignmentClassName = classNames(classes.columnHeader, {
		[classes.columnHeaderAuto]: isAutoGroup,
		[classes.textAlignCenter]: textAlignment === 'center',
		[classes.textAlignRight]: textAlignment === 'right',
	})

	const headerClassNames = classNames(classes.columnHeaderWrapper, enableSorting && classes.columnHeaderClickable)

	const SortArrow = (
		<>
			{sorting === e_SortOrder.desc && <i className="Fluent-SortDown" />}
			{sorting === e_SortOrder.asc && <i className="Fluent-SortUp" />}
			{sorting === undefined && <i className="Fluent-Transparent" />}
		</>
	)

	const isLeftColumn = props.api.getDisplayedLeftColumns()[0]?.getColId() === 'ag-Grid-AutoColumn'

	return props.isVertical ? (
		<div
			style={{ position: 'relative', width: '100%', height: '100%' }}
			onClick={onClick}
			onTouchEnd={onClick}
			onContextMenu={!props.disableMenu ? onContextMenu : undefined}
			title={props.screenTip || props.displayName}
		>
			<div className={classes.columnHeaderTiltedBase} style={{ height: VERTICAL_HEADER_BASE_HEIGHT }}>
				{SortArrow}
			</div>

			<div className={classes.columnHeaderTiltedWrapper} style={{ bottom: VERTICAL_HEADER_BASE_HEIGHT }}>
				<div
					style={{
						transform: `rotate(-90deg)`,
						width: `${MAX_HEADER_HEIGHT - VERTICAL_HEADER_BASE_HEIGHT - VERTICAL_HEADER_PADDING}px`,
					}}
				>
					<ColumnHeaderContent
						displayName={props.displayName}
						hideLabel={hideLabel}
						iconName={props.iconName as IIconNames}
						iconPlacement={props.iconPlacement}
					/>
				</div>
			</div>
		</div>
	) : (
		<div className={headerClassNames} data-pageelementid={columnId}>
			<div
				className={textAlignmentClassName}
				style={props.cellStyle}
				title={props.screenTip || props.displayName}
				onClick={onClick}
				onTouchEnd={onClick}
				onContextMenu={!props.disableMenu ? onContextMenu : undefined}
			>
				{props.multiSelect && isLeftColumn && (
					<HeaderCheckMark
						api={props.api}
						eGridHeader={props.eGridHeader}
						inPreviewMode={props.inPreviewMode}
						tableContext={props.context}
					/>
				)}
				{textAlignment === 'right' && SortArrow}
				<ColumnHeaderContent
					displayName={props.displayName}
					hideLabel={hideLabel}
					iconName={props.iconName as IIconNames}
					iconPlacement={props.iconPlacement}
				/>

				{textAlignment !== 'right' && SortArrow}
			</div>
			{filterIsAllowed && !props.disableMenu && (
				<div
					className={classNames(classes.menuButtonWrapper, filterActive && classes.menuButtonWrapperActiveFilter)}
					style={textAlignment === 'right' ? { left: 0 } : { right: 0 }}
					ref={menuButtonAnchor}
				>
					<Button
						icon={filterActive ? 'Fluent-Filter' : 'Fluent-More'}
						screenTip={tcvi('GENERAL:MORE')}
						variant="inline"
						size="small"
						onClick={() => showColumnMenu(menuButtonAnchor.current as HTMLElement)}
						dataAttributes={{ 'data-cy': 'table-header-filter-btn' }}
						className={classes.menuButton}
					/>
				</div>
			)}
		</div>
	)
}
