import { useMemo } from 'react'
import { useSyncWidth } from './customHooks/useSyncWidth'
import { e_LabelPosition } from '../../enums/e_LabelPosition'

//
export const useLeftLabel = (
	id: string | undefined,
	labelText: string,
	labelPosition: e_LabelPosition,
	deps?: unknown[]
) => {
	const useLeftLabel = id !== undefined && labelPosition === e_LabelPosition.left && labelText
	const leftLabelClassName = useSyncWidth(id, [labelText, ...(deps ?? [])], !useLeftLabel)
	const labelProps = useMemo(
		() => ({
			className: leftLabelClassName,
		}),
		[leftLabelClassName]
	)

	if (!useLeftLabel) {
		return undefined
	}

	return labelProps
}
