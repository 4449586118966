import { useEffect } from 'react'
import { createStyle } from '../../../../theming'
import { CELL_PADDING } from '../../consts'

const classes = createStyle((theme) => ({
	gridCell: { paddingRight: `${CELL_PADDING}px !important`, paddingLeft: `${CELL_PADDING}px` },
	gridCellNested: { display: 'flex !important' },
	gridCellLink: { color: `${theme.colors.links.text} !important` },
	gridCellViewer: { padding: `${CELL_PADDING}px` },
	gridCellEditMode: {
		borderColor: 'transparent !important',
		borderWidth: '0px !important',
		paddingLeft: '0px',
		paddingTop: '0px !important',
		paddingRight: '0px !important',
		paddingBottom: '0px !important',
	},
	gridCellEditModeNoIndent: {
		paddingLeft: '0px !important',
	},
	gridCellEditable: {
		cursor: 'pointer',
		'&:hover': { borderColor: `${theme.colors.input.focusBorder} !important` },
	},
	gridCellEditModeMultiSelect: { borderLeftWidth: '1px !important' },
	gridCellEditModeContextMenu: { borderRightWidth: '1px !important' },
	border: { borderTop: '0 !important', borderBottom: '0 !important' },
	borderLeft: { borderLeft: '0 !important' },
	borderLeftTransparent: { borderLeftColor: 'transparent !important' },
	borderRight: { borderRight: '0 !important' },
	borderRightTransparent: { borderRightColor: 'transparent !important' },
	cellEditable: {
		cursor: 'pointer',
		'&:hover': { borderColor: `${theme.colors.input.focusBorder} !important` },
	},
}))

export const useCellStyles = (
	eGridCell: HTMLElement,
	multiSelect: boolean,
	editingMode: boolean,
	editable: boolean,
	visibleContextMenu: boolean,
	isPlainTextCell: boolean,
	isLinkCell: boolean,
	isFirstColumnWithGrouping: boolean,
	isFirstColumnWithDrag: boolean
) => {
	useEffect(() => {
		if (editingMode) {
			eGridCell.classList.remove(classes.gridCellViewer)

			if (!isFirstColumnWithGrouping && !multiSelect) {
				eGridCell.classList.add(classes.gridCellEditModeNoIndent)
			}

			eGridCell.classList.add(classes.gridCellEditMode)

			if (multiSelect) {
				eGridCell.classList.add(classes.gridCellEditModeMultiSelect)
			}

			if (visibleContextMenu) {
				eGridCell.classList.add(classes.gridCellEditModeContextMenu)
			}

			eGridCell.classList.remove(classes.gridCellEditable)
		} else {
			eGridCell.classList.add(classes.gridCellViewer)
			if (editable) {
				eGridCell.classList.add(classes.gridCellEditable)
				eGridCell.classList.remove(classes.gridCellEditMode)

				if (multiSelect) {
					eGridCell.classList.remove(classes.gridCellEditModeMultiSelect)
				}

				if (visibleContextMenu) {
					eGridCell.classList.remove(classes.gridCellEditModeContextMenu)
				}

				if (!isFirstColumnWithGrouping) {
					eGridCell.classList.remove(classes.gridCellEditModeNoIndent)
				}
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editingMode])

	useEffect(() => {
		if (isPlainTextCell && !editingMode && !isFirstColumnWithDrag) {
			if (isLinkCell) {
				eGridCell.classList.add(classes.gridCellLink)
			}
			eGridCell.classList.remove(classes.gridCellNested)
		} else {
			eGridCell.classList.add(classes.gridCellNested)

			if (isLinkCell) {
				eGridCell.classList.remove(classes.gridCellLink)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isPlainTextCell, editingMode])
}
