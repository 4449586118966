import type { IRowNode, GridApi } from '@ag-grid-community/core'
import type { TData } from '../Table.types'
import { refreshCheckMarkColumn } from './selectionUtils'

export function setNodeSelected(
	selected: boolean,
	props: { node: IRowNode<TData>; gridApi: GridApi<TData>; clearSelection?: boolean }
) {
	const { node, gridApi, clearSelection } = props

	node.setSelected(selected, clearSelection)
	refreshCheckMarkColumn(gridApi)
}
