import React from 'react'
import clsx from 'clsx'
import { createStyle } from '../../theming'

import { getScreenTip } from './utils/getScreenTip'
import { useForwardedRef } from '../utils/useForwardedRef'
import { useGetStyle } from '../utils/Style.context'
import { useIconAndLabelUtils } from './utils/useIconAndLabelUtils'
import { useKeyboardShortcut } from './utils/useKeyboardShortcut'
import { Badge } from '../Badge'
import type { IIconSize } from '../Icon'
import { Icon } from '../Icon'
import type { IKeyboardShortcut } from '../utils/keyboard/IKeyboardShortcut'
import { Spinner } from '../Spinner'
import { variantClasses } from './utils/variantClasses'

const classes = createStyle((theme) => ({
	button: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		outline: 0,
		padding: 0,

		transitionDuration: '100ms',
		transitionProperty: 'background, border, color',
		transitionTimingFunction: 'cubic-bezier(0.33,0,0.67,1)',

		borderRadius: theme.controls.button.borderRadius,
		borderStyle: theme.controls.button.borderStyle,
		borderWidth: theme.controls.button.borderWidth,
		fontSize: theme.controls.button.fontSize,
		fontWeight: theme.controls.button.fontWeight,
		margin: theme.controls.button.margin,
		'&:focus-visible': {
			backgroundClip: theme.controls.button.backgroundClip,
			borderStyle: theme.controls.button.focusBorderStyle,
			outlineOffset: `calc(0px - ${theme.controls.button.borderWidth} + ${theme.controls.button.outlineOffset})`,
			outlineStyle: theme.controls.button.outlineStyle,
			outlineWidth: theme.controls.button.outlineWidth,
		},
	},
	content: {
		display: 'flex',
		lineHeight: '16px',
		alignItems: 'center',
		gap: 6,
	},
	contentVertical: { flexDirection: 'column' },
	contentIconAfterText: { '& > i, & > svg': { order: 2 } },

	label: { textDecoration: theme.controls.button.textDecoration },
	labelLineHeight: { lineHeight: '32px' },

	extraSmall: { fontSize: 12, padding: '2px 6px' },
	extraSmallIconButton: { padding: '2px' },
	small: { fontSize: 12, padding: '4px 16px' },
	smallIconButton: { padding: '4px' },
	medium: { padding: '6px 18px' },
	mediumIconButton: { padding: '6px' },
	large: { padding: '8px 34px' },
	largeIconButton: { padding: '8px' },
	extraLarge: { padding: '18px 48px' },
	extraLargeIconButton: { padding: '18px' },
	none: { padding: theme.controls.button.padding },

	dropdownIcon: { paddingRight: '8px' },
}))

export interface IButtonProps {
	/** Click event handler that is called when the button is clicked or activated by keyboard */
	onClick?: (e: React.MouseEvent) => void
	onMouseDown?: (e: React.MouseEvent) => void
	onEnterPress?: () => void
	disabled?: boolean
	screenTip?: string
	size?: 'extraSmall' | 'small' | 'medium' | 'large' | 'extraLarge' | 'none'
	/** Categories of buttons controlling fill and outline */
	variant?: 'default' | 'inline' | 'primary'
	/** Text displayed inside button */
	label?: string
	/** Checks the button by changing appearance and adding area */
	isChecked?: boolean
	/** Add custom classes */
	className?: string
	/** Add inline style */
	style?: React.CSSProperties
	id?: string
	/** Use this to add attributes to button, i.e. data-cy used for testing */
	dataAttributes?: Record<string, string>

	/** Icon name. Mostly sourced from fluent icons. See https://genus.gitlab.io/packages/icon-set/ for full list */
	icon?: string
	iconPosition?: 'left' | 'right' | 'top' | 'bottom'
	type?: 'button' | 'submit' | 'reset'
	dropdownIconSize?: IIconSize

	badgeValue?: number
	showSpinner?: boolean
	showDropdownButton?: boolean

	ariaLabel?: string
	children?: React.ReactNode
	keyboardShortcut?: IKeyboardShortcut
}

type ClassKeys = keyof typeof classes

/**
 * Primary UI component for user interaction
 *
 * Color is determined by theming which is controlled by the theme provider, not the button itself
 */
export const Button = React.forwardRef((props: IButtonProps, ref: React.Ref<HTMLButtonElement>) => {
	const { iconPosition = 'left', type = 'button', variant = 'default' } = props

	const { iconSize: styleIconSize, size = props.size ?? 'medium', width } = { ...useGetStyle().button }

	const buttonRef = useForwardedRef<HTMLButtonElement>(ref)

	const screenTip = getScreenTip(props.screenTip, props.keyboardShortcut)

	const { hasLabel, hasIcon, positionIconAfterText, isContentVertical, iconSize, sizeIsLarge } = useIconAndLabelUtils(
		iconPosition,
		size,
		styleIconSize,
		props.label,
		props.icon
	)

	useKeyboardShortcut(buttonRef, props.disabled, props.keyboardShortcut)

	const onClick = (e: React.MouseEvent) => {
		if (props.onClick) {
			e.stopPropagation()
			props.onClick(e)
		}
	}

	const onMouseDown = (e: React.MouseEvent) => {
		if (props.onMouseDown) {
			e.stopPropagation()
			props.onMouseDown(e)
		}
	}

	const onkeydown = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' && props.onEnterPress) {
			e.preventDefault()
			e.stopPropagation()
			props.onEnterPress()
		}
	}

	const buttonClasses = clsx(classes.button, variantClasses[variant], props.className)

	const contentClasses = clsx(classes.content, {
		[classes.contentIconAfterText]: positionIconAfterText,
		[classes.contentVertical]: isContentVertical,
		[classes[size]]: hasLabel ?? size === 'none',
	})

	const iconClasses = clsx({ [classes[(size + 'IconButton') as ClassKeys]]: !hasLabel && hasIcon && size !== 'none' })

	return (
		<button
			id={props.id}
			ref={buttonRef}
			title={screenTip.length > 0 ? screenTip.join('\n') : undefined}
			tabIndex={props.disabled ? -1 : undefined}
			type={type}
			disabled={props.disabled}
			onClick={onClick}
			onMouseDown={onMouseDown}
			onKeyDown={onkeydown}
			style={{ ...props.style, width }}
			className={buttonClasses}
			{...props.dataAttributes}
			aria-label={props.ariaLabel}
			role={props.isChecked ? 'button' : undefined}
			aria-checked={props.isChecked ? true : undefined}
		>
			<Badge value={props.badgeValue} offsetRight={-16} offsetTop={-4}>
				<span className={contentClasses}>
					{props.showSpinner && <Spinner size="small" />}
					{hasIcon && <Icon iconClassName={props.icon} size={iconSize} className={iconClasses} />}
					{hasLabel && (
						<span className={clsx(classes.label, !isContentVertical && sizeIsLarge && classes.labelLineHeight)}>
							{props.label}
						</span>
					)}
				</span>
				{props.children}
				{props.showDropdownButton && (
					<Icon
						iconClassName="Fluent-ChevronDown"
						className={classes.dropdownIcon}
						size={props.dropdownIconSize ?? iconSize}
					/>
				)}
			</Badge>
		</button>
	)
})

Button.displayName = 'Button'
