import React from 'react'
import classNames from 'clsx'
import { Button } from '../Button'
import type { CommandBarItem } from '../CommandBar'
import { CommandBar } from '../CommandBar'
import { createStyle } from '../../theming'

const classes = createStyle({
	commandBar: { marginRight: '16px' },
})

interface IDialogTitle {
	id?: string
	title?: string
	classes: Record<string, string>
	handlePointerDown: (event: React.PointerEvent) => void
	handleCloseButtonClick: (event: React.MouseEvent) => void
	hasCancelButton: boolean
	severity?: string
	disableCancelOnEscape?: boolean
	variant?: 'normal' | 'small'
	headerCommands?: CommandBarItem[]
	wrapTitle?: boolean
}

export const DialogTitle = (props: IDialogTitle) => {
	return (
		<div
			className={classNames(props.classes.dialogHeader, props.variant === 'small' && props.classes.dialogHeaderSmall)}
		>
			<div className={props.classes.dialogHeaderContent} onPointerDown={props.handlePointerDown}>
				<div
					className={classNames(
						props.classes.dialogHeaderText,
						props.severity === 'error' && props.classes.severityErrorText,
						props.variant === 'small' && props.classes.dialogHeaderTextSmall,
						!props.wrapTitle && props.classes.textOverflowEllipsis
					)}
					id={props.id}
				>
					{props.title}
				</div>
			</div>

			<div className={props.classes.dialogHeaderButtons}>
				<CommandBar size={'small'} items={props.headerCommands} className={classes.commandBar} />
				{!props.disableCancelOnEscape && props.hasCancelButton && (
					<Button
						variant="inline"
						size={props.variant === 'small' ? 'small' : undefined}
						screenTip="Close"
						icon="Fluent-ChromeClose"
						onClick={props.handleCloseButtonClick}
					/>
				)}
			</div>
		</div>
	)
}
