import className from 'clsx'
import React, { useMemo } from 'react'
import { HeaderControl } from './HeaderControl'
import { createStyle } from '../../../theming'
import type { IDataWithIdentifier } from '../types/IDataWithIdentifier'
import type { IDetailFieldDescription } from '../types/IDataPropertyDescription'
import type { DetailItemSurface } from '../types/IDetailItemSurfaceProps'
import { e_actionType, type IActionEntry } from '../types/IActionDescription'
import type { IMasterDetailPreakpoint } from '../types/IMasterDetailBreakpoint'
import { getComponentFromColumnData } from '../utils/getComponentFromColumnData'
import { useTranslation } from '../../../translation'
import type { FormatDataValueEventHandler } from '../types/MasterDetailEventHandlers'
import { e_MasterDetailTargetElement } from '../types/e_MasterDetailTargetElement'
import { formatPropertyValue } from '../utils/valueUtils'

const classes = createStyle((theme) => ({
	detailPanel: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		minWidth: 0,
		minHeight: 0,
	},

	withBorder: {
		border: `1px solid ${theme.colors.panel.border}`,
		borderRadius: '5px',
	},

	withShadow: {
		boxShadow: `0 6.4px 14.4px 0 rgba(${'0,0,0'}, 0.132), 0 1.2px 3.6px 0 rgba(${'0,0,0'}, 0.108)`,
	},

	leftMargin: {
		marginLeft: '16px',
	},

	rightMargin: {
		marginLeft: '16px',
	},

	leftBorder: {
		borderLeft: `1px solid ${theme.colors.panel.border}`,
	},
	item: {
		display: 'flex',
		padding: '8px',
		flexDirection: 'column',
		alignItems: 'stretch',
	},

	property: {
		display: 'flex',
		padding: '4px',

		'& > label': {
			width: '120px',
			textAlign: 'left',
		},
	},
}))

interface IDetailPanelProps {
	title: string
	items: IDataWithIdentifier[]

	leftBorder?: boolean

	activeId: string | undefined

	itemName?: string | ((item: IDataWithIdentifier) => string | undefined)

	fieldDescriptions: IDetailFieldDescription[]
	formatDataValue?: FormatDataValueEventHandler

	singleItemActions?: IActionEntry[]

	breakpoint: IMasterDetailPreakpoint

	handleSingleItemAction?: (actionId: string, itemId: string) => void

	back?: () => void

	detailComponent?: DetailItemSurface
	renderDetailComponent?: (
		item: IDataWithIdentifier,
		breakpoint: IMasterDetailPreakpoint
	) => React.ReactNode | undefined
	gridArea?: string

	selectedIds: string[]
	setSelectedIds: (ids: string[]) => void

	setActiveId: (id: string | undefined) => void
}

export const DetailPanel = React.forwardRef((props: IDetailPanelProps, forwardedRef: React.Ref<HTMLDivElement>) => {
	const activeItem = props.activeId ? props.items.find((item) => item.id === props.activeId) : undefined

	const { tcvi } = useTranslation()

	const itemNamePropertyDesc = useMemo(() => {
		if (typeof props.itemName === 'string') {
			return props.fieldDescriptions.find((col) => col.propertyId === props.itemName)
		}

		return undefined
	}, [props.fieldDescriptions, props.itemName])

	const getItemName = (item: IDataWithIdentifier) => {
		if (!props.itemName) {
			return item.id
		}

		if (typeof props.itemName === 'string') {
			if (itemNamePropertyDesc && itemNamePropertyDesc.enableValueFormatting && props.formatDataValue) {
				const customValue = props.formatDataValue(
					item,
					itemNamePropertyDesc.propertyId,
					e_MasterDetailTargetElement.displayName
				)

				if (customValue !== undefined) {
					return customValue
				}
			}

			return itemNamePropertyDesc
				? formatPropertyValue(item, props.itemName, itemNamePropertyDesc, props.formatDataValue, tcvi)
				: item[props.itemName]
		}

		if (typeof props.itemName === 'function') {
			return props.itemName(item)
		}
	}

	const renderDetailComponent = (item: IDataWithIdentifier) => {
		// render by either render using callback, render a specified component, or fallbaclk to rendering based on specified configuration
		if (props.renderDetailComponent) {
			const detailComponent = props.renderDetailComponent(item, props.breakpoint)

			if (detailComponent !== undefined) {
				return detailComponent
			}
		}

		if (props.detailComponent) {
			return <props.detailComponent dataItem={item} breakpoint={props.breakpoint} />
		}

		return getComponentFromColumnData(item, props.fieldDescriptions, props.formatDataValue, tcvi)
	}

	const itemActions = useMemo(() => {
		const actions = []

		if (props.singleItemActions) {
			actions.push(...props.singleItemActions)
		}

		actions.push({
			type: e_actionType.action,
			id: 'previous',
			displayName: tcvi('GENERAL:PREVIOUS'),
			iconClassName: 'Basic-ArrowUp',
			screenTip: tcvi('GENERAL:PREVIOUS'),
			disabled: !activeItem || props.items.findIndex((item) => item.id === activeItem.id) === 0,
		})

		actions.push({
			type: e_actionType.action,
			id: 'next',
			displayName: tcvi('GENERAL:NEXT'),
			iconClassName: 'Basic-ArrowDown',
			screenTip: tcvi('GENERAL:NEXT'),
			disabled: !activeItem || props.items.findIndex((item) => item.id === activeItem.id) === props.items.length - 1,
		})

		return actions
	}, [activeItem, props.items, props.singleItemActions, tcvi])

	const handleItemAction = useMemo(() => {
		return (actionId: string) => {
			if (actionId === 'previous') {
				const index = props.items.findIndex((item) => item.id === activeItem?.id)
				if (index > 0 && activeItem) {
					props.setActiveId(props.items[index - 1].id)
					props.setSelectedIds([activeItem?.id])
				}
			} else if (actionId === 'next') {
				const index = props.items.findIndex((item) => item.id === activeItem?.id)
				if (index < props.items.length - 1 && activeItem) {
					props.setActiveId(props.items[index + 1].id)
					props.setSelectedIds([activeItem?.id])
				}
			} else {
				props.handleSingleItemAction && activeItem && props.handleSingleItemAction(actionId, activeItem.id)
			}
		}
	}, [props.handleSingleItemAction, props.setSelectedIds, props.setActiveId, activeItem, props.items])

	return (
		<div
			className={className(classes.detailPanel, props.leftBorder && classes.leftBorder)}
			ref={forwardedRef}
			style={props.gridArea ? { gridArea: props.gridArea } : undefined}
		>
			<HeaderControl
				title={(activeItem ? getItemName(activeItem)?.toString() : props.title) ?? ''}
				back={props.back}
				generalActions={itemActions}
				handleGeneralAction={handleItemAction}
			/>
			{activeItem && renderDetailComponent(activeItem)}
		</div>
	)
})

DetailPanel.displayName = 'DetailPanel'
