import React, { useEffect, useRef, useState } from 'react'

// Selectors
import { useDispatch, useSelector } from 'react-redux'
import { kubernetesSelectors } from 'src/features/Kubernetes/duck/kubernetesSelectors'
import { useTranslation } from 'react-i18next'
import { operatorApi } from 'src/modules/operatorApi'
import { modalManagerActions } from 'src/features/ModalManager/duck'
import { translator } from 'src/utils/locale'
import { isActive } from 'src/utils/publicationIsActive'
import { IDataPartition, IGService, IK8sRuntime } from 'src/interfaces/IK8sRuntime'
import { capitalize } from 'src/utils/capitalize'
import { IDataWithIdentifier, IMasterDetailCallbackAPI } from '@genusbiz/web-ui/surfaces'
import {
	EditableMasterDetailSurface,
	presetAdditionalDetailSurfaceProps,
} from '@genusbiz/web-ui/surfaces/EditableMasterDetail'
import { RuntimeDetail } from './RuntimeDetail'
import { RuntimeDetailEdit } from './RuntimeDetailEdit'
import { e_EnvironmentType } from 'src/enums/e_EnvironmentType'
import { AxiosError } from 'axios'
import { generateGuid } from 'src/utils/generateGuid'
import { e_OperatorStorageKeys } from 'src/enums/e_OperatorStorageKeys'
import { IConfirmationModalProps } from 'src/interfaces/IModalProps'
import { operatorFrontendActions } from 'src/features/OperatorFrontend/duck/operatorFrontendActions'
import { IEditableEntry, IEditableItem } from '@genusbiz/web-ui/surfaces/EditableMasterDetail/types/detailConfigTypes'
import { e_MasterDetailValueDataType } from '@genusbiz/web-ui/surfaces/MasterDetail/types/MasterDetailDataType'
import { e_MasterDetailPropertyPresentation } from '@genusbiz/web-ui/surfaces/MasterDetail/types/e_MasterDetailPropertyPresentation'

export const OperatorK8sRuntimeTable = () => {
	const k8sRuntimes = useSelector(kubernetesSelectors.selectK8sRuntimes)
	const productVersions = useProductVersions(k8sRuntimes)
	const partitions = useDataPartitions()

	const { columnDescriptions, columnGroups } = useRuntimeTableDataDescriptions()

	const rowdata = deriveRowData(k8sRuntimes, productVersions)
	const itemMethods = useRuntimeItemMethods(k8sRuntimes, rowdata)

	const activeId = rowdata.find((data) => data.isActive === 'true')?.id

	const getFormattingStyles = (item: IDataWithIdentifier) => {
		const boldObj = {
			isBold: true,
		}

		return item.id === activeId ? boldObj : undefined
	}

	const components = {
		view: presetAdditionalDetailSurfaceProps(RuntimeDetail, { partitions }),
		edit: presetAdditionalDetailSurfaceProps(RuntimeDetailEdit, { mode: 'edit' as const, partitions }),
		create: presetAdditionalDetailSurfaceProps(RuntimeDetailEdit, { mode: 'create' as const, partitions }),
	}

	const apiRef = useRef<IMasterDetailCallbackAPI | undefined>()

	const showMessage = useSnackbarMessages()

	const { t } = useTranslation()

	return (
		<EditableMasterDetailSurface
			callbackAPI={apiRef}
			interactions={itemMethods}
			viewComponent={components.view}
			editComponent={components.edit}
			createComponent={components.create}
			componentDeps={[partitions]}
			detailFieldDescriptions={columnDescriptions}
			data={rowdata}
			contentName={t('RUNTIMES:RUNTIME')}
			contentNamePlural={t('RUNTIMES:RUNTIMES')}
			columnDescriptions={columnDescriptions}
			columnGroups={columnGroups}
			getFormattingStyles={getFormattingStyles}
			giveFeedback={showMessage}
		/>
	)
}

const useRuntimeTableDataDescriptions = () => {
	const { t } = useTranslation()

	const columnDescriptions = [
		{
			displayName: t('GENERAL:ID'),
			propertyId: 'id',
			sortable: false,
			datatype: e_MasterDetailValueDataType.string,
			enableFormatting: true,
		},
		{
			displayName: capitalize(t('GENERAL:ENVIRONMENT')),
			propertyId: 'environmentType',
			sortable: false,
			datatype: e_MasterDetailValueDataType.string,
			enableFormatting: true,
		},
		{
			displayName: capitalize(t('GENERAL:ENVIRONMENT')),
			propertyId: 'environmentTypeName',
			sortable: false,
			datatype: e_MasterDetailValueDataType.string,
			enableFormatting: true,
		},
		{
			displayName: t('GENERAL:NAME'),
			propertyId: 'name',
			sortable: false,
			datatype: e_MasterDetailValueDataType.string,
			enableFormatting: true,
		},
		{
			displayName: t('GENERAL:ACTIVE'),
			propertyId: 'isActive',
			sortable: false,
			datatype: e_MasterDetailValueDataType.string,
			enableFormatting: true,
		},
		{
			displayName: t('TABLE_HEADERS:PRODUCT_VERSION'),
			propertyId: 'deployedProductVersion',
			sortable: false,
			datatype: e_MasterDetailValueDataType.string,
			enableFormatting: true,
		},
		{
			displayName: t('TABLE_HEADERS:CARDDAV_SERVICE'),
			propertyId: 'CardDavService',
			sortable: false,
			datatype: e_MasterDetailValueDataType.boolean,
			presentation: e_MasterDetailPropertyPresentation.checkmark,
			enableFormatting: false,
		},
		{
			displayName: t('TABLE_HEADERS:DATAMART_SERVICE'),
			propertyId: 'DataMartService',
			sortable: false,
			datatype: e_MasterDetailValueDataType.boolean,
			presentation: e_MasterDetailPropertyPresentation.checkmark,
			enableFormatting: false,
		},
		{
			displayName: t('TABLE_HEADERS:MAIL_SERVICE'),
			propertyId: 'MailService',
			sortable: false,
			datatype: e_MasterDetailValueDataType.boolean,
			presentation: e_MasterDetailPropertyPresentation.checkmark,
			enableFormatting: false,
		},
		{
			displayName: t('TABLE_HEADERS:MESSAGE_QUEUE_SERVICE'),
			propertyId: 'MessageQueueService',
			sortable: false,
			datatype: e_MasterDetailValueDataType.boolean,
			presentation: e_MasterDetailPropertyPresentation.checkmark,
			enableFormatting: false,
		},
		{
			displayName: t('TABLE_HEADERS:REST_SERVICE'),
			propertyId: 'RestService',
			sortable: false,
			datatype: e_MasterDetailValueDataType.boolean,
			presentation: e_MasterDetailPropertyPresentation.checkmark,
			enableFormatting: false,
		},
		{
			displayName: t('TABLE_HEADERS:SCHEDULED_ACTION_SERVICE'),
			propertyId: 'ScheduledActionService',
			sortable: false,
			datatype: e_MasterDetailValueDataType.boolean,
			presentation: e_MasterDetailPropertyPresentation.checkmark,
			enableFormatting: false,
		},
		{
			displayName: t('TABLE_HEADERS:WEBCAL_SERVICE'),
			propertyId: 'WebCalService',
			sortable: false,
			datatype: e_MasterDetailValueDataType.boolean,
			presentation: e_MasterDetailPropertyPresentation.checkmark,
			enableFormatting: false,
		},
		{
			displayName: t('TABLE_HEADERS:XML_SERVICE'),
			propertyId: 'XmlService',
			sortable: false,
			datatype: e_MasterDetailValueDataType.boolean,
			presentation: e_MasterDetailPropertyPresentation.checkmark,
			enableFormatting: false,
		},
	]

	const columnGroups = [
		'environmentTypeName',
		'name',
		'isActive',
		'deployedProductVersion',
		{
			displayName: t('RUNTIMES:FEATURES'),
			groupId: 'services',
			children: [
				'CardDavService',
				'DataMartService',
				'MailService',
				'MessageQueueService',
				'RestService',
				'ScheduledActionService',
				'WebCalService',
				'XmlService',
			],
		},
	]

	return { columnDescriptions, columnGroups }
}

// Master desc helper funcs:

type IBooleanObject = { [id: string]: boolean }
type IProductVersions = {
	[runtime: string]: string[]
}

const addService = (services: IBooleanObject, service: IGService) => {
	services[service.name] = service.enabled
	return services
}
const createRuntimeServicesObject = (services: IGService[]) =>
	services.reduce((services, service) => addService(services, service), {} as IBooleanObject)

const useSnackbarMessages = () => {
	const dispatch = useDispatch()

	const showMessage = (message: string) => dispatch(modalManagerActions.showSnackbar({ message }))

	return showMessage
}

const deriveRowData = (k8sRuntimes: IK8sRuntime[], productVersions: IProductVersions) =>
	k8sRuntimes.map((runtime) => {
		const runtimeIsActive = isActive(runtime.currentPublication)
		const services = createRuntimeServicesObject(runtime.services)

		const runtimeItem = {
			id: runtime.id,
			name: runtime.name,
			environmentType: runtime.environmentType,
			environmentTypeName: runtime.environmentTypeName,
			dataPartitionId: runtime.dataPartitionId,
			isActive: runtimeIsActive.toString(),
			deployedProductVersion: productVersions[runtime.name]?.join(', ') ?? '',
		}

		return Object.assign(runtimeItem, services)
	})

// Custom hooks

const getArrayEntry = <T extends { id: string }>(array: T[], id: string) => array.find((entry) => entry.id === id)

const useRuntimeItemMethods = (k8sRuntimes: IK8sRuntime[], items: IEditableItem[]) => {
	const { commitRuntimeCreation, commitRuntimeUpdate, commitRuntimeDeletion } = useRuntimeCommits(k8sRuntimes)
	const showDeletionModal = useRuntimeDeletionModal(commitRuntimeDeletion)

	const dispatch = useDispatch()
	const { t } = useTranslation()

	const itemMethods = {
		getItem: (id: string) => getArrayEntry(items, id),
		getDraftBaseItem: () => getRuntimeDraftItem(),
		commitItemUpdate: (item: IEditableItem | undefined, onConfirm: () => void) => {
			const validRuntime = item && commitRuntimeUpdate(item)
			if (validRuntime) {
				onConfirm()
			} else {
				dispatch(
					modalManagerActions.showSnackbar({
						message: `${t('RUNTIMES:INVALID_OBJECT')}`,
					})
				)
			}
		},

		commitItemCreation: (item: IEditableItem | undefined, onConfirm: () => void) => {
			const validRuntime = item && commitRuntimeCreation(item)
			if (validRuntime) {
				onConfirm()
			} else {
				dispatch(
					modalManagerActions.showSnackbar({
						message: `${t('RUNTIMES:INVALID_OBJECT')}`,
					})
				)
			}
		},

		commitItemDeletion: (itemId: string, onConfirm: () => void) => {
			const item = getArrayEntry(items, itemId)
			const name = (item?.name ?? 'Unknown') as string

			showDeletionModal(itemId, name, onConfirm)
		},
	}

	return itemMethods
}

const useRuntimeDeletionModal = (commitRuntimeDeletion: (id: string, name?: string) => void) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const commitDeletionModal = (itemId: string, name: string, onConfirm: () => void) => {
		const modalprops: IConfirmationModalProps = {
			title: t('GENERAL:WARNING'),
			message: `${t('RUNTIMES:DELETE_WARNING')}: '${name}'? ${t('RUNTIMES:ACTION_UNDONE')}.`,
			declineText: t('GENERAL:NO'),
			confirmText: t('GENERAL:YES'),
			onConfirm: () => {
				commitRuntimeDeletion(itemId, name)
				onConfirm()
			},
		}
		dispatch(modalManagerActions.showConfirmationModal(modalprops))
	}

	return commitDeletionModal
}

const useRuntimeCommits = (k8sRuntimes: IK8sRuntime[]) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const reload = () => {
		dispatch(operatorFrontendActions.fetchK8sRuntimes())
	}

	const commitRuntimeCreation = (newItem: IEditableItem) => {
		const newK8sRuntime = completeDraftItem(newItem)

		if (!newK8sRuntime) {
			return false
		}

		operatorApi
			.createOperatorK8sRuntime(newK8sRuntime)
			.then(() => {
				dispatch(
					modalManagerActions.showSnackbar({
						message: `${t('RUNTIMES:CREATE_SUCCESSFUL')}: '${newK8sRuntime.name}'`,
					})
				)
				reload()
			})
			.catch((error) => {
				dispatch(modalManagerActions.showErrorModal(error))
			})

		return true
	}

	const commitRuntimeUpdate = (updatedItem: IEditableItem) => {
		const runtime = getArrayEntry(k8sRuntimes, updatedItem.id)
		const updatedK8sRuntime = runtime && addServiceChanges(runtime, updatedItem)

		if (!updatedK8sRuntime) {
			return false
		}

		operatorApi
			.updateOperatorK8sRuntime(updatedK8sRuntime)
			.then(() => {
				dispatch(
					modalManagerActions.showSnackbar({
						message: `${t('RUNTIMES:UPDATE_SUCCESSFUL')}: '${updatedK8sRuntime.name}'`,
					})
				)
				reload()
			})
			.catch((error) => {
				dispatch(modalManagerActions.showErrorModal(error))
			})

		return true
	}

	const commitRuntimeDeletion = (id: string, name?: string) => {
		operatorApi
			.deleteOperatorK8sRuntime(id)
			.then(() => {
				dispatch(
					modalManagerActions.showSnackbar({
						message: `${t('RUNTIMES:RUNTIME')} '${name ?? 'Unknown'}' ${t('RUNTIMES:DELETE_SUCCESSFUL')}.`,
					})
				)
				reload()
			})
			.catch((error) => {
				dispatch(modalManagerActions.showErrorModal(error))
			})
	}

	return { commitRuntimeCreation, commitRuntimeUpdate, commitRuntimeDeletion }
}

const useProductVersions = (k8sRuntimes: IK8sRuntime[]) => {
	const dispatch = useDispatch()
	const [productVersions, setProductVersions] = useState<{ [runtime: string]: string[] }>({})

	useEffect(() => {
		const promises = k8sRuntimes.map((runtime) => {
			return operatorApi.fetchOperatorDeployments(runtime.name)
		})
		Promise.all(promises)
			.then((deployments) => {
				const productVersions: { [runtime: string]: string[] } = {}
				k8sRuntimes.forEach((runtime, i) => {
					productVersions[runtime.name] = [
						...new Set(
							deployments[i].map(
								(deployment) =>
									deployment.runtimeConfig.genusVersion || deployment.runtimeConfig.genusOperatorVersion || ''
							)
						),
					]
				})
				setProductVersions(productVersions)
			})
			.catch(() => {
				setProductVersions({})
				dispatch(
					modalManagerActions.showSnackbar({
						message: translator.translate('ERROR:FAILED_GET_DEPLOYMENTS'),
					})
				)
			})
	}, [k8sRuntimes])

	return productVersions
}

const useDataPartitions = () => {
	const dispatch = useDispatch()
	const [partitions, setPartitions] = useState<IDataPartition[]>([])

	useEffect(() => {
		operatorApi
			.fetchDataPartitions()
			.then((partitions: IDataPartition[]) => {
				setPartitions(partitions)
			})
			.catch((error: Error | AxiosError) => {
				dispatch(modalManagerActions.showErrorModal(error))
			})
	}, [])

	return partitions
}

const getRuntimeDraftItem = () => {
	const runtimeInfo = {
		id: generateGuid(),
		name: undefined as undefined | string,
		dataPartitionId: undefined as undefined | string,
		environmentType: undefined as undefined | string,
	}

	const services = {
		CardDavService: false,
		DataMartService: false,
		MailService: false,
		MessageQueueService: false,
		RestService: false,
		ScheduledActionService: false,
		WebCalService: false,
		XmlService: false,
	}

	return { ...runtimeInfo, ...services }
}

const getNewRuntimeEntry = (
	id: string,
	name: string,
	environmentType: e_EnvironmentType,
	dataPartitionId: string,
	services: IGService[]
) => {
	const username = sessionStorage.getItem(e_OperatorStorageKeys.username) ?? ''
	const changeSeqNo = 0

	const newK8sRuntime: IK8sRuntime = {
		id,
		name,
		environmentType,
		currentPublication: null,
		dataPartitionId,
		services,
		changeSeqNo,
		// NOTE! modifiedBy and createdBy should be set to specific
		// user when Entra ID is implemented in Operator.
		modifiedBy: username,
		createdBy: username,
	}
	return newK8sRuntime
}

const knownServices = [
	'CardDavService',
	'DataMartService',
	'MailService',
	'MessageQueueService',
	'RestService',
	'ScheduledActionService',
	'WebCalService',
	'XmlService',
] as const

const extractService = (item: IEditableEntry, service: string) => ({
	name: service,
	enabled: item[service] as boolean,
})

const addServiceToList = (services: IGService[], item: IEditableEntry, service: string) => [
	...services,
	extractService(item, service),
]

const completeDraftItem = (item: IEditableEntry) => {
	const id = item.id
	const name = item.name
	const environmentType = item.environmentType
	const dataPartitionId = item.dataPartitionId

	if (
		typeof id !== 'string' ||
		typeof name !== 'string' ||
		typeof environmentType !== 'number' ||
		typeof dataPartitionId !== 'string'
	) {
		return
	}

	const services = knownServices.reduce(
		(services, service) => addServiceToList(services, item, service),
		[] as IGService[]
	)

	const completeitem = getNewRuntimeEntry(id, name, environmentType, dataPartitionId, services)
	return completeitem
}

const addServiceChanges = (runtime: IK8sRuntime, item: IEditableItem) => {
	const username = sessionStorage.getItem(e_OperatorStorageKeys.username) ?? ''

	const services = knownServices.reduce(
		(services, service) => addServiceToList(services, item, service),
		[] as IGService[]
	)

	const updatedK8sRuntime: IK8sRuntime = {
		...runtime,
		services,
		modifiedBy: username,
	}

	return updatedK8sRuntime
}
