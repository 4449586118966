import { useRef, useState } from 'react'

export const useEditableItem = <T extends object>() => {
	const [item, setItem] = useState<T>()
	const isDirtyRef = useRef(false)
	const isDirty = isDirtyRef.current

	const setBaseItem = (item?: T) => {
		setItem(item)
		isDirtyRef.current = false
	}
	const combineValues = (item: T, newValues: Partial<T>) => {
		isDirtyRef.current = true
		return { ...item, ...newValues }
	}
	const edit = (newValues: Partial<T>) => setItem((item) => item && combineValues(item, newValues))

	return { item, isDirty, setBaseItem, edit }
}
