import type { ReactNode } from 'react'
import React, { useRef, useState, useEffect, useCallback } from 'react'
import classNames from 'clsx'

import { Avatar } from '../Avatar'
import { Icon } from '../Icon'
import type { MenuItem } from '../Menu'
import { Menu, e_MenuItemType } from '../Menu'
import { e_Placement } from '../../enums/e_Placement'
import { useEventListener } from '../utils/useEventListener'
import throttle from 'lodash/throttle'
import { useTranslation } from '../../translation'
import { Callout } from '../../surfaces/Callout'
import { createStyle } from '../../theming'

const classes = createStyle((theme) => {
	return {
		appBar: {
			display: 'flex',
			width: '100%',
			background: theme.controls.appBar.colors.background,
			color: theme.controls.appBar.colors.color,
			padding: theme.controls.appBar.padding,
			height: theme.controls.appBar.height,
		},
		appBarActive: { background: theme.controls.appBar.checkedColors.background },
		appbarContent: {
			display: 'flex',
			width: '100%',
			minHeight: '48px',
		},
		appbarButton: {
			display: 'flex',
			height: 50,
			flexShrink: 0,
			justifyContent: 'center',
			alignItems: 'center',
			alignSelf: 'center',
			padding: 0,
			outline: 0,
			cursor: 'pointer',
			background: 'transparent',
			color: 'inherit',
			border: '1px solid transparent',
			'&:hover': { background: theme.controls.appBar.hoverColors.background },
			'&:focus-visible': { borderWidth: '1px', borderColor: 'inherit' },
			'&:active': { background: theme.controls.appBar.pressedColors.background },
		},

		appbarTextButton: {
			display: 'inline',
			maxWidth: 270,
			'@media screen and (max-width: 400px)': { maxWidth: 235 },
			padding: '0 12px 0 8px',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		},

		appModelName: {
			fontSize: theme.typography.styles.h4.fontSize,
			fontWeight: theme.typography.styles.h4.fontWeight,
			'@media screen and (max-width: 400px)': { display: 'none !important' },
		},
		appModelNameButton: {
			display: 'none !important',
			'@media screen and (max-width: 400px)': { display: 'flex !important' },
		},
		title: {
			fontSize: theme.typography.styles.h4.fontSize,
			flexShrink: 1,
		},
		navigationButtons: {
			display: 'flex',
			height: '100%',
			margin: 0,
			padding: 0,
			overflow: 'hidden',
		},
		navigationButtonTitle: {
			padding: '0 12px 0 8px',
			overflow: 'hidden',
			fontSize: theme.typography.styles.h5.fontSize,
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
		},
		appBarDetail: {
			flexGrow: 1,
			fontSize: theme.typography.styles.h4.fontSize,
			marginLeft: 10,
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		},
		leftRegion: { display: 'flex' },
		centerRegion: {
			display: 'flex',
			flexGrow: 1,
			overflow: 'hidden',
			alignItems: 'center',
		},
		centerRegionGrow: { flexGrow: 1 },
		rightRegion: { display: 'flex' },
		buttonDefaultWidth: { width: 50 },
		buttonChecked: {
			background: theme.controls.appBar.checkedColors.background,
			'&:hover': { background: theme.controls.appBar.checkedHoverColors.background },
		},
		avatarWrapper: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		avatar: { userSelect: 'none' },
	}
})

const ICON_WIDTH = 50

interface IAppBarProps {
	children?: ReactNode | ReactNode[]
	appModelName?: string | ReactNode | ReactNode[]
	appModelNameCompact?: ReactNode | ReactNode[]
	appModelNameScreenTip?: string
	navigationButtons?: { title: string; onClick: () => void; id?: string }[]
	isAppModelAdmin?: boolean
	title?: string
	titleScreenTip?: string
	detail?: string
	accountName?: string
	gravatarUrl?: string
	helpUrl?: string
	rightChildren?: ReactNode | ReactNode[]
	appNavBarOpen?: boolean
	sitemapOpen?: boolean
	settingsOpen?: boolean
	userInformationOpen?: boolean
	userCalloutContents?: React.ReactNode
	childHasFocus?: boolean
	onAppModelNameClick?: () => void
	onTitleClick?: () => void
	onSiteMapClick?: () => void
	onAppNavigationClick?: () => void
	onSettingsClick?: () => void
	onHelpUrlClick?: () => void
	onUserInformationClick?: () => void

	onDeveloperToolsClick?: () => void
	ariaNavigationPaneId?: string
	ariaSitemapId?: string
}

export const AppBar = (props: IAppBarProps) => {
	const { tcvi } = useTranslation()

	let userInitials = props.accountName
	if (userInitials) {
		const names = userInitials.split(' ')
		if (names.length > 1 && names[0].length && names[1].length) {
			userInitials = (names[0][0] + names[1][0]).toUpperCase()
		} else {
			userInitials = userInitials.replace(/\s+/g, '').substring(0, 2).toUpperCase()
		}
	}

	const availableSpaceRef = useRef<HTMLDivElement | undefined>(undefined)
	const [overflowMenuOpen, setOverflowMenuOpen] = useState(false)
	const [overflowItems, setOverflowItems] = useState<MenuItem[]>([])
	const [inOverflowLayout, setInOverflowLayout] = useState(false)
	const screenWidth = useRef(0)

	const overflowButtonRef = useRef<HTMLButtonElement>(null)
	const userInformationRef = useRef<HTMLButtonElement>(null)

	const onResize = throttle(() => {
		setOverflowMenuOpen(false)
		if (availableSpaceRef.current?.offsetWidth === undefined) {
			return
		}

		if (availableSpaceRef.current.offsetWidth <= 10 && !inOverflowLayout) {
			setInOverflowLayout(true)
			screenWidth.current = window.innerWidth
		} else if (
			window.innerWidth > screenWidth.current &&
			availableSpaceRef.current.offsetWidth !== 0 &&
			availableSpaceRef.current.offsetWidth >= ICON_WIDTH * 2 &&
			inOverflowLayout
		) {
			setInOverflowLayout(false)
		}
	}, 200)

	useEventListener('resize', onResize)

	const onRefChange = useCallback((node: HTMLDivElement | null) => {
		if (node !== null) {
			availableSpaceRef.current = node

			if (availableSpaceRef.current?.offsetWidth !== undefined && availableSpaceRef.current?.offsetWidth <= 10) {
				setInOverflowLayout(true)
				screenWidth.current = window.innerWidth
			}
		}
	}, [])

	useEffect(() => {
		const items: MenuItem[] = []
		if (props.onSettingsClick) {
			items.push({
				type: e_MenuItemType.action,
				name: tcvi('GENERAL:SETTINGS'),
				iconClassName: 'Fluent-Settings',
				onClick: props.onSettingsClick,
			})
		}

		if (props.onHelpUrlClick) {
			items.push({
				type: e_MenuItemType.action,
				name: tcvi('GENERAL:HELP'),
				iconClassName: 'Fluent-Help',
				onClick: props.onHelpUrlClick,
			})
		}

		if (props.onDeveloperToolsClick) {
			items.push({
				type: e_MenuItemType.action,
				name: tcvi('GENERAL:DEVELOPER_TOOLS'),
				iconClassName: 'Fluent-DeveloperTools',
				onClick: props.onDeveloperToolsClick,
			})
		}

		setOverflowItems(items)
	}, [props.onSettingsClick, props.onHelpUrlClick, props.onUserInformationClick, props.onDeveloperToolsClick, tcvi])

	const userInformationButtonClassNames = classNames(classes.buttonDefaultWidth, {
		[classes.appbarButton]: props.onUserInformationClick,
		[classes.avatarWrapper]: !props.onUserInformationClick,
		[classes.buttonChecked]: props.userInformationOpen,
	})

	const overflowButtonClassNames = classNames(classes.appbarButton, classes.buttonDefaultWidth, {
		[classes.buttonChecked]: overflowMenuOpen,
	})

	const handleClose = () => setOverflowMenuOpen(false)

	return (
		<div
			className={classNames(classes.appBar, {
				[classes.appBarActive]: props.childHasFocus,
			})}
		>
			<div className={classes.appbarContent}>
				{/* SHOW SITEMAP */}
				<div className={classes.leftRegion}>
					{props.onSiteMapClick && (
						<button
							className={classNames(classes.appbarButton, classes.buttonDefaultWidth, {
								[classes.buttonChecked]: props.sitemapOpen,
							})}
							onClick={props.onSiteMapClick}
							title={tcvi('GENERAL:SITEMAP')}
							aria-pressed={props.sitemapOpen}
							aria-expanded={props.sitemapOpen ? 'true' : 'false'}
							aria-controls={props.sitemapOpen ? props.ariaSitemapId : undefined}
							id={'appbar-btn-sitemap'}
							data-cy={'appbar-btn-sitemap'}
						>
							<Icon iconName="Fluent-GlobalNavButton" />
						</button>
					)}
				</div>
				{/* Page info, typically model name etc */}
				<div className={classes.centerRegion}>
					{props.appModelName && (
						<>
							{/* Goto Home */}
							{/* Only one of the following buttons are visible at a time, controlled by media query */}
							{/* Large Home button */}
							<button
								className={classNames(classes.appbarButton, classes.appbarTextButton, classes.appModelName)}
								title={props.appModelNameScreenTip ? props.appModelNameScreenTip : tcvi('GENERAL:GO_TO_START_PAGE')}
								onClick={props.onAppModelNameClick}
								id={'appbar-btn-home'}
								data-cy={'appbar-btn-home'}
								role="link"
							>
								{props.appModelName}
							</button>
							{/* Small Home button */}
							<button
								id={'appbar-btn-home-small'}
								data-cy={'appbar-btn-home-small'}
								className={classNames(classes.appbarButton, classes.buttonDefaultWidth, classes.appModelNameButton)}
								onClick={props.onAppModelNameClick}
								title={props.appModelNameScreenTip ? props.appModelNameScreenTip : tcvi('GENERAL:GO_TO_START_PAGE')}
								role="link"
							>
								{props.appModelNameCompact ? props.appModelNameCompact : <Icon iconName="Fluent-Home" />}
							</button>
						</>
					)}
					{props.onAppNavigationClick && (
						<button
							className={classNames(classes.appbarButton, {
								[classes.buttonChecked]: props.appNavBarOpen,
							})}
							title={tcvi('GENERAL:SWITCH_TO_ANOTHER_APP')}
							onClick={props.onAppNavigationClick}
							id={'appbar-btn-select-app'}
							data-cy={'appbar-btn-select-app'}
							aria-pressed={props.appNavBarOpen}
							aria-expanded={props.appNavBarOpen ? 'true' : 'false'}
							aria-controls={props.appNavBarOpen ? props.ariaNavigationPaneId : undefined}
						>
							<Icon iconName="Fluent-ChevronDown" margin={{ margin: '0 4px' }} />
						</button>
					)}
					{props.onTitleClick ? (
						<button
							className={classNames(classes.appbarButton, classes.appbarTextButton, classes.title)}
							title={props.titleScreenTip ? props.titleScreenTip : 'Go to ' + props.title}
							onClick={props.onTitleClick}
							id={'appbar-btn-app-home'}
							data-cy={'appbar-btn-app-home'}
						>
							{props.title}
						</button>
					) : (
						<div
							className={classNames(classes.appbarTextButton, classes.title)}
							title={props.titleScreenTip ? props.titleScreenTip : props.title}
						>
							{props.title}
						</div>
					)}
					{props.navigationButtons && (
						<div className={classes.navigationButtons}>
							{props.navigationButtons.map((button, index) => (
								<button
									key={index}
									id={button.id}
									data-cy={button.id}
									className={classes.appbarButton}
									onClick={button.onClick}
									title={'Go to ' + button.title}
								>
									<div className={classes.navigationButtonTitle}>{button.title}</div>
								</button>
							))}
						</div>
					)}
					{props.detail && <div className={classes.appBarDetail}>{props.detail}</div>}
					{props.children}
					<div ref={onRefChange} className={classes.centerRegionGrow} />
				</div>
				<div className={classes.rightRegion}>
					{inOverflowLayout ? (
						<button
							ref={overflowButtonRef}
							className={overflowButtonClassNames}
							onClick={() => setOverflowMenuOpen(!overflowMenuOpen)}
							title={tcvi('GENERAL:MORE_COMMANDS')}
						>
							<Icon iconName="Fluent-MoreVertical" />
						</button>
					) : (
						<>
							{props.onSettingsClick && (
								<button
									id={'appbar-btn-app-settings'}
									data-cy={'appbar-btn-app-settings'}
									className={classNames(classes.appbarButton, classes.buttonDefaultWidth, {
										[classes.buttonChecked]: props.settingsOpen,
									})}
									onClick={props.onSettingsClick}
									title={tcvi('GENERAL:SETTINGS')}
									aria-expanded={props.settingsOpen ? 'true' : 'false'}
								>
									<Icon iconName="Fluent-Settings" />
								</button>
							)}
							{props.helpUrl && (
								<button
									id={'appbar-btn-app-help'}
									data-cy={'appbar-btn-app-help'}
									className={classNames(classes.appbarButton, classes.buttonDefaultWidth)}
									onClick={props.onHelpUrlClick}
									title={tcvi('GENERAL:HELP')}
								>
									<Icon iconName="Fluent-Help" />
								</button>
							)}

							{props.isAppModelAdmin && props.onDeveloperToolsClick && (
								<button
									className={classNames(classes.appbarButton, classes.buttonDefaultWidth)}
									onClick={props.onDeveloperToolsClick}
									title={tcvi('GENERAL:DEVELOPER_TOOLS')}
									id={'appbar-btn-devtools'}
									data-cy={'appbar-btn-devtools'}
								>
									<Icon iconName="Fluent-DeveloperTools" />
								</button>
							)}
						</>
					)}
					<Menu
						open={overflowMenuOpen}
						onClose={handleClose}
						items={overflowItems}
						anchorEl={overflowButtonRef}
						placement={e_Placement.bottomEnd}
					/>

					{props.accountName &&
						(props.onUserInformationClick ? (
							<button
								ref={userInformationRef}
								id={'appbar-btn-app-usersettings'}
								data-cy={'appbar-btn-app-usersettings'}
								className={userInformationButtonClassNames}
								onClick={props.onUserInformationClick}
								title={tcvi('GENERAL:USER_INFORMATION') + ': ' + props.accountName}
								aria-expanded={props.userInformationOpen ? 'true' : 'false'}
							>
								<Avatar
									initials={userInitials}
									imageUrl={props.gravatarUrl}
									numberOfInitials={2}
									size={'extraSmall'}
									className={classes.avatar}
								/>
							</button>
						) : (
							<div className={userInformationButtonClassNames}>
								<Avatar
									initials={userInitials}
									id={'appbar-btn-app-usersettings'}
									data-cy={'appbar-btn-app-usersettings'}
									imageUrl={props.gravatarUrl}
									numberOfInitials={2}
									size={'extraSmall'}
									className={classes.avatar}
									screenTip={props.accountName}
								/>
							</div>
						))}
					{props.rightChildren}
				</div>
			</div>

			{props.userCalloutContents && (
				<Callout
					anchorElement={userInformationRef}
					isOpen
					onClose={() => {
						props.onUserInformationClick?.()
					}}
				>
					{props.userCalloutContents}
				</Callout>
			)}
		</div>
	)
}
