import type { CSSProperties } from 'react'
import React, { useState, useEffect, useCallback, useRef } from 'react'
import classNames from 'clsx'

import { createStyle } from '../../../../theming'
import { useTranslation } from '../../../../translation'
import type { IIconNames } from '@genusbiz/icon-set/dist/utils'

// Enums and Interfaces
import { e_SortOrder } from '../../enums/e_SortOrder'
import { MAX_HEADER_HEIGHT, VERTICAL_HEADER_BASE_HEIGHT, VERTICAL_HEADER_PADDING } from '../../consts'
import type { ITableContext, TData } from '../../Table.types'
import type { IHeaderParams, GridApi } from '@ag-grid-community/core'
import { ColumnHeaderContent } from './ColumnHeaderContent'
import { HeaderCheckMark } from '../HeaderCheckMark'
import { isCustomColumn } from '../../utils/gridCallbacks'
import { GenericButton } from '../../../GenericButton'

const classes = createStyle((theme) => ({
	columnHeaderWrapper: {
		display: 'flex',
		height: '100%',
		flexGrow: 1,
		alignItems: 'center',
		gap: 4,
		overflow: 'hidden',
		'&:hover': { '& > :last-child': { background: theme.colors.list.hoveredBackground, visibility: 'visible' } },
		margin: `0 var(--genus-header-cell-padding)`,
	},
	columnHeaderWrapperLeftAlign: { '&:hover': { '& > :last-child': { position: 'static', visibility: 'visible' } } },
	columnHeaderClickable: {
		cursor: 'pointer',
		'&:active': { background: theme.colors.list.pressedBackground },
	},
	columnHeader: {
		display: 'flex',
		height: '100%',
		alignItems: 'center',
		flexGrow: 1,
		overflow: 'hidden',
	},
	columnHeaderAuto: { alignItems: 'end', margin: '0 0 8px 28px' },
	columnHeaderText: { overflow: 'hidden', textOverflow: 'ellipsis' },

	columnHeaderTiltedBase: {
		position: 'absolute',
		width: '100%',
		bottom: 0,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		paddingBottom: 4,
	},
	columnHeaderTiltedWrapper: {
		position: 'absolute',
		overflow: 'visible',
		left: 'calc(50% - 0.15em)',
	},
	columnHeaderTilted: { transformOrigin: 'left center', overflow: 'hidden', textOverflow: 'ellipsis' },

	textAlignCenter: { justifyContent: 'center' },
	textAlignRight: { justifyContent: 'flex-end' },
	iconRight: { marginLeft: '4px' },
	iconLeft: { marginRight: '4px' },
	menuButtonWrapper: {
		display: 'flex',
		alignSelf: 'stretch',
		visibility: 'hidden',
		position: 'absolute',
		height: '100%',
	},
	menuButton: {
		margin: '5px',
	},
	menuButtonWrapperActiveFilter: { position: 'static', visibility: 'visible' },
	fileDragColumnPadding: { paddingLeft: '24px' },
	menuButtonVisible: {
		'& > :last-child': { visibility: 'visible' },
	},
}))

export interface ITableColumnHeaderProps {
	textAlignment?: 'right' | 'left' | 'center'
	iconPlacement?: 'before' | 'after'
	screenTip?: string
	iconName?: string
	hideLabel?: boolean
	disableFilter?: boolean
	disableSorting?: boolean
	inPreviewMode?: boolean
	isVertical?: boolean
	disableMenu?: boolean
	multiSelect?: boolean
	cellStyle?: CSSProperties
	includesFileData?: boolean
	alwaysShowMenu?: boolean
}

export const ColumnHeader = (props: ITableColumnHeaderProps & IHeaderParams<TData, ITableContext>) => {
	const { tcvi } = useTranslation()

	const { hideLabel = false, textAlignment = 'left' } = props

	const [sorting, setSorting] = useState(props.column.getSort() ?? undefined)
	const [filterActive, setFilterActive] = useState(props.column.isFilterActive())

	const columnId = props.column.getColId()
	const _isCustomColumn = isCustomColumn(props.column.getColDef())
	const isLeftColumn = getIsLeftColumn(props.api, columnId, _isCustomColumn)

	const filterIsAllowed = props.column.isFilterAllowed() && !props.disableFilter

	const menuButtonAnchor = useRef<HTMLDivElement>(null)
	const mounted = useRef(true)

	const onClick = useCallback(
		(e: React.MouseEvent | React.TouchEvent | KeyboardEvent) => {
			if (props.disableSorting) {
				return
			}

			switch (sorting) {
				case e_SortOrder.asc:
					props.setSort(e_SortOrder.desc, e.shiftKey)
					break
				case e_SortOrder.desc:
					props.setSort(e_SortOrder.asc, e.shiftKey)
					break
				default:
					props.setSort(e_SortOrder.asc, e.shiftKey)
					break
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[props.setSort, sorting]
	)

	const onContextMenu = useCallback(
		(event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			if (!filterIsAllowed) {
				return
			}

			event?.preventDefault()

			const anchor = event?.currentTarget || menuButtonAnchor.current

			if (anchor) {
				props.showColumnMenu(anchor)
			}
		},
		[filterIsAllowed, props]
	)

	const onKeyDown = useCallback(
		(e: KeyboardEvent) => {
			if (e.key === 'Enter') {
				if (e.ctrlKey) {
					onContextMenu()

					return
				}

				onClick(e)
			}
		},
		[onClick, onContextMenu]
	)

	const onSortChange = useCallback(() => {
		const currentSorting = props.column.getSort()

		switch (currentSorting) {
			case e_SortOrder.asc:
				mounted.current && setSorting(e_SortOrder.asc)
				break
			case e_SortOrder.desc:
				mounted.current && setSorting(e_SortOrder.desc)
				break
			case null:
			default:
				mounted.current && setSorting(undefined)
				break
		}
	}, [props.column])

	const onFilterChange = useCallback(() => {
		const filterIsActive = props.column.isFilterActive()
		if (filterIsActive) {
			mounted.current && setFilterActive(true)
		} else {
			mounted.current && setFilterActive(false)
		}
	}, [props.column])

	useEffect(() => {
		props.eGridHeader.addEventListener('keydown', onKeyDown)
		props.column.addEventListener('sortChanged', onSortChange)
		props.column.addEventListener('filterChanged', onFilterChange)
		return () => {
			props.eGridHeader.removeEventListener('keydown', onKeyDown)
			props.column.removeEventListener('sortChanged', onSortChange)
			props.column.removeEventListener('filterChanged', onFilterChange)
		}
	}, [onFilterChange, onSortChange, onKeyDown, props.eGridHeader, props.column])

	useEffect(() => {
		return () => {
			mounted.current = false
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const isAutoGroup = columnId === 'ag-Grid-AutoColumn'
	const textAlignmentClassName = classNames(classes.columnHeader, isAutoGroup && classes.columnHeaderAuto, {
		[classes.textAlignCenter]: textAlignment === 'center',
		[classes.textAlignRight]: textAlignment === 'right',
	})

	const headerClassNames = classNames(
		classes.columnHeaderWrapper,
		textAlignment === 'left' && classes.columnHeaderWrapperLeftAlign,
		!props.disableSorting && classes.columnHeaderClickable,
		isLeftColumn && props.includesFileData && classes.fileDragColumnPadding
	)

	const SortArrow = (
		<>
			{sorting === e_SortOrder.desc && (
				<i className="Fluent-SortDown" title={tcvi('CONTROL:AG_ARIADROPZONECOLUMNCOMPONENTSORTDESCENDING')} />
			)}
			{sorting === e_SortOrder.asc && (
				<i className="Fluent-SortUp" title={tcvi('CONTROL:AG_ARIADROPZONECOLUMNCOMPONENTSORTASCENDING')} />
			)}
			{sorting === undefined && <i className="Fluent-Transparent" />}
		</>
	)

	return props.isVertical ? (
		<div
			style={{ position: 'relative', width: '100%', height: '100%' }}
			onClick={onClick}
			onTouchEnd={onClick}
			onContextMenu={!props.disableMenu ? onContextMenu : undefined}
			title={props.screenTip || props.displayName}
		>
			<div className={classes.columnHeaderTiltedBase} style={{ height: VERTICAL_HEADER_BASE_HEIGHT }}>
				{sorting === e_SortOrder.desc && <i className="Fluent-SortDown" />}
				{sorting === e_SortOrder.asc && <i className="Fluent-SortUp" />}
				{sorting === undefined && <i className="Fluent-Transparent" />}
			</div>

			<div className={classes.columnHeaderTiltedWrapper} style={{ bottom: VERTICAL_HEADER_BASE_HEIGHT }}>
				<div
					className={classes.columnHeaderTilted}
					style={{
						transform: `rotate(-${90}deg)`,
						width: `${MAX_HEADER_HEIGHT - VERTICAL_HEADER_BASE_HEIGHT - VERTICAL_HEADER_PADDING}px`,
					}}
				>
					<ColumnHeaderContent
						displayName={props.displayName}
						hideLabel={hideLabel}
						iconName={props.iconName as IIconNames}
						iconPlacement={props.iconPlacement}
					/>
				</div>
			</div>
		</div>
	) : (
		<div className={headerClassNames} data-pageelementid={columnId}>
			{props.multiSelect && isLeftColumn && (
				<HeaderCheckMark
					api={props.api}
					eGridHeader={props.eGridHeader}
					inPreviewMode={props.inPreviewMode}
					tableContext={props.context}
				/>
			)}
			<div
				className={textAlignmentClassName}
				style={props.cellStyle}
				title={props.screenTip || props.displayName}
				onClick={onClick}
				onTouchEnd={onClick}
				onContextMenu={!props.disableMenu ? onContextMenu : undefined}
			>
				{textAlignment === 'right' && SortArrow}
				<ColumnHeaderContent
					displayName={props.displayName}
					hideLabel={hideLabel}
					iconName={props.iconName as IIconNames}
					iconPlacement={props.iconPlacement}
				/>

				{textAlignment !== 'right' && SortArrow}
			</div>
			{filterIsAllowed && !props.disableMenu && (
				<div
					className={classNames(
						classes.menuButtonWrapper,
						filterActive && classes.menuButtonWrapperActiveFilter,
						props.alwaysShowMenu && classes.menuButtonVisible
					)}
					style={textAlignment === 'right' ? { left: 0 } : { right: 0 }}
					ref={menuButtonAnchor}
				>
					<GenericButton
						icon={filterActive ? 'Fluent-Filter' : 'Fluent-More'}
						screenTip={tcvi('GENERAL:MORE')}
						onClick={() => props.showColumnMenu(menuButtonAnchor.current as HTMLElement)}
						dataAttributes={{ 'data-cy': 'table-header-filter-btn' }}
						className={classes.menuButton}
					/>
				</div>
			)}
		</div>
	)
}
function getIsLeftColumn(gridApi: GridApi<TData>, columnId: string, isCustomColumn: boolean | undefined) {
	const displayedLeftColumns = gridApi.getDisplayedLeftColumns()
	if (displayedLeftColumns.length === 0) {
		return isCustomColumn
	}
	return gridApi.getDisplayedLeftColumns()[0].getColId() === columnId
}
