import type { CellData, TData, Value } from '../Table.types'
import type { IRowNode, ColDef } from '@ag-grid-community/core'
import type { FormatterTValue } from './colDefUtils'
import { getCellDataFromGroupNodeChild } from './colDefUtils'

export function formatGroupCellValue(
	data: TData | undefined,
	value: FormatterTValue,
	node: IRowNode<TData> | null,
	colDef: ColDef<TData, FormatterTValue> | ColDef<TData, CellData | string>
): string {
	if (data?.rowName) {
		return data.rowName
	}

	if (node?.aggData) {
		const hasAggregatedData = colDef.field ? !!node.aggData[colDef.field] : false
		if (hasAggregatedData && colDef.cellRendererParams?.formatCellValue) {
			const normalizedValue = typeof value === 'object' ? value?.value : value
			return colDef.cellRendererParams.formatCellValue(normalizedValue)
		}
		if (node.footer) {
			return ''
		}
	}
	const formattedValue = getCellDataFromGroupNodeChild(node)?.formattedValue

	if (formattedValue === '') {
		return getNullValue(node)
	}

	const _formattedValue = getStringFromValidValue(formattedValue)
	if (_formattedValue) {
		return _formattedValue
	}

	const normalizedValue = typeof value === 'object' ? value?.value : value
	const _value = getStringFromValidValue(normalizedValue)
	if (_value) {
		return _value + ' test'
	}

	return getNullValue(node)
}

function getNullValue(node: IRowNode<TData> | null) {
	if (!node) {
		return ''
	}
	if (node.footer) {
		return ''
	}
	if (!node.group) {
		return ''
	}
	return '---'
}

function getStringFromValidValue(value: Value) {
	if (value === undefined || value === null) {
		return null
	}
	const trimmedStringValue = value.toString().trim()
	if (trimmedStringValue) {
		return trimmedStringValue
	}
	return null
}
