import React, { useRef, useEffect } from 'react'
import clamp from 'lodash/clamp'
import clsx from 'clsx'
import { createStyle } from '../../theming'

const classes = createStyle({
	resizer: { position: 'relative' },
	resizeHorizontal: { overflowX: 'auto' },
	resizeVertical: { overflowY: 'auto' },
	resizeBothAxis: { overflow: 'auto' },
	sizer: {
		position: 'absolute',
		touchAction: 'none',
		left: 0,
		top: 0,
		right: 0,
		bottom: 0,
	},
	top: {
		height: 8,
		bottom: 'unset',
		cursor: 'row-resize',
	},
	bottom: {
		height: 8,
		top: 'unset',
		cursor: 'row-resize',
	},
	left: {
		width: 8,
		right: 'unset',
		cursor: 'col-resize',
	},
	right: {
		width: 8,
		left: 'unset',
		cursor: 'col-resize',
	},

	corner: {
		width: 20,
		height: 20,
		top: 'unset',
		left: 'unset',
		background:
			"url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+')",
		'background-position': 'bottom right',
		padding: '0 3px 3px 0',
		'background-repeat': 'no-repeat',
		'background-origin': 'content-box',
		'box-sizing': 'border-box',
		cursor: 'se-resize',
	},
})

interface IResizableOverlayProps {
	fullScreen?: boolean
	defaultSize?: { width?: number; height?: number }
	minSize?: { width: number; height: number }
	maxSize?: { width: number; height: number }
	enable?: { corner?: boolean; top?: boolean; right?: boolean; bottom?: boolean; left?: boolean }
	suppressPositioning?: boolean
	onResize?: (width: number, height: number) => void
	targetElement: React.RefObject<HTMLDivElement>
}

type Directions = 'corner' | 'top' | 'right' | 'bottom' | 'left'

type MousePos = { x: number; y: number }

const clampMousePosToWindow = (event: PointerEvent) => {
	const clampedX = clamp(event.clientX, 0, window.innerWidth - 1)
	const clampedY = clamp(event.clientY, 0, window.innerHeight - 1)

	const mousePos = {
		x: clampedX,
		y: clampedY,
	}

	return mousePos
}

const formatPixel = (number: number) => {
	return `${number}px`
}

export const ResizableOverlay = (props: IResizableOverlayProps) => {
	const { minSize = { width: 350, height: 200 }, maxSize = { width: 3000, height: 3000 } } = props
	const direction = useRef<Directions>()
	const flexDirection = useRef<'vertical' | 'horizontal'>()

	const defaultWidth = props.defaultSize?.width ?? 0
	const defaultHeight = props.defaultSize?.height ?? 0

	const initialWidth = useRef(defaultWidth)
	const width = useRef(defaultWidth)
	const height = useRef(defaultHeight)
	const initialHeight = useRef(defaultHeight)
	const initialLeft = useRef(0)
	const initialTop = useRef(0)
	const initialX = useRef(0)
	const initialY = useRef(0)
	const isResizing = useRef(false) //to prevent MouseMove from running after MouseUp

	useEffect(() => {
		const target = props.targetElement.current

		if (!target) {
			return
		}
		const dir = getComputedStyle(target).flexDirection
		if (dir.startsWith('column')) {
			flexDirection.current = 'vertical'
		} else if (dir.startsWith('row')) {
			flexDirection.current = 'horizontal'
		}
	}, [])

	useEffect(() => {
		//Update initial sizes and ref if changed from the outside when resizing is not active

		const shouldUpdateSizeVariables = (defaultWidth || defaultHeight) && !isResizing.current && !props.fullScreen

		if (shouldUpdateSizeVariables) {
			width.current = defaultWidth
			height.current = defaultHeight
			initialWidth.current = defaultWidth
			initialHeight.current = defaultHeight
		}
	}, [defaultWidth, defaultHeight, props.fullScreen])

	const completePointerOperation = (event: Event) => {
		isResizing.current = false
		setTimeout(() => {
			const pointerEvent = event as PointerEvent

			const sizerElement = pointerEvent.target as Element

			sizerElement.removeEventListener('pointerup', handlePointerUp)
			sizerElement.removeEventListener('pointermove', handlePointerMove)
			sizerElement.removeEventListener('pointercancel', handlePointerCancel)
		}, 0)
	}

	const handlePointerUp = (event: Event) => {
		completePointerOperation(event)

		event.preventDefault()
		event.stopPropagation()
	}

	const defaultTargetLeftOnResize = (style: CSSStyleDeclaration) => {
		const left = formatPixel(initialLeft.current)
		if (left !== style.getPropertyValue('--left')) {
			style.setProperty('--left', left)
		}
	}

	const resizeHorizontally = (proposedChange: number, updateLeft: boolean) => {
		const change = restrictChangeOnSizeParameters(initialWidth.current, minSize.width, maxSize.width, proposedChange)
		width.current = initialWidth.current + change

		if (!props.targetElement.current) {
			return
		}
		const style = props.targetElement.current.style

		defaultTargetLeftOnResize(style)

		if (formatPixel(initialLeft.current) !== style.getPropertyValue('--left')) {
			const left = formatPixel(initialLeft.current)
			style.setProperty('--left', left)
		}

		style.width = formatPixel(width.current)
		style.setProperty('--width', `${width.current}px`)

		if (flexDirection.current === 'horizontal') {
			updateFlexBasis(style, width.current)
		}
		if (updateLeft) {
			const newLeft = initialLeft.current - change
			style.setProperty('--left', formatPixel(newLeft))
		}
	}

	const resizeLeft = (mousePos: MousePos) => {
		const proposedChange = initialX.current - mousePos.x
		const availableGrowSpace = initialLeft.current
		const leftClampedChange = availableGrowSpace < proposedChange ? availableGrowSpace : proposedChange
		resizeHorizontally(leftClampedChange, !props.suppressPositioning)
	}

	const resizeRight = (mousePos: MousePos) => {
		const proposedChange = mousePos.x - initialX.current
		const availableGrowSpace = window.innerWidth - (initialLeft.current + initialWidth.current)
		const rightClampedChange = availableGrowSpace < proposedChange ? availableGrowSpace : proposedChange
		resizeHorizontally(rightClampedChange, false)
	}

	const defaultTargetTopOnResize = (style: CSSStyleDeclaration) => {
		const top = formatPixel(initialTop.current)
		if (top !== style.getPropertyValue('--top')) {
			style.setProperty('--top', top)
		}
	}

	const resizeVertically = (proposedChange: number, updateTop: boolean) => {
		const change = restrictChangeOnSizeParameters(initialHeight.current, minSize.height, maxSize.height, proposedChange)

		height.current = initialHeight.current + change

		if (!props.targetElement.current) {
			return
		}
		const style = props.targetElement.current.style

		defaultTargetTopOnResize(style)

		style.height = formatPixel(height.current)
		style.setProperty('--height', `${height.current}px`)

		if (flexDirection.current === 'vertical') {
			updateFlexBasis(style, height.current)
		}
		if (updateTop) {
			const newTop = initialTop.current - change
			style.setProperty('--top', formatPixel(newTop))
		}
	}

	const resizeTop = (mousePos: MousePos) => {
		const proposedChange = initialY.current - mousePos.y
		const availableGrowSpace = initialTop.current
		const topClampedChange = availableGrowSpace < proposedChange ? availableGrowSpace : proposedChange
		resizeVertically(topClampedChange, !props.suppressPositioning)
	}

	const resizeBottom = (mousePos: MousePos) => {
		const proposedChange = mousePos.y - initialY.current
		const availableGrowSpace = window.innerHeight - (initialTop.current + initialHeight.current)
		const bottomClampedChange = availableGrowSpace < proposedChange ? availableGrowSpace : proposedChange
		resizeVertically(bottomClampedChange, false)
	}

	const updateFlexBasis = (style: CSSStyleDeclaration, basis: number) => {
		style.flexBasis = formatPixel(basis)
	}

	const restrictChangeOnSizeParameters = (span: number, min: number, max: number, proposedChange: number) => {
		const lower = min - span
		const upper = max - span

		const change = clamp(proposedChange, lower, upper)
		return change
	}

	const determineOnResizeWidth = () => {
		return width.current < minSize.width || width.current > maxSize.width
			? props.targetElement.current?.clientWidth
			: width.current
	}

	const determineOnResizeHeight = () => {
		return height.current < minSize.height || height.current > maxSize.height
			? props.targetElement.current?.clientHeight
			: height.current
	}

	const handlePointerMove = (event: Event) => {
		event.preventDefault()
		event.stopPropagation()

		if (!isResizing.current) {
			return
		}

		const pointerEvent = event as PointerEvent
		const mousePos = clampMousePosToWindow(pointerEvent)

		if (direction.current === 'left') {
			resizeLeft(mousePos)
		}
		if (direction.current === 'right' || direction.current === 'corner') {
			resizeRight(mousePos)
		}
		if (direction.current === 'top') {
			resizeTop(mousePos)
		}
		if (direction.current === 'bottom' || direction.current === 'corner') {
			resizeBottom(mousePos)
		}

		const resizeWidth = determineOnResizeWidth()
		const resizeHeight = determineOnResizeHeight()

		if (resizeWidth === undefined || resizeHeight === undefined) {
			return
		}
		props.onResize?.(width.current, height.current)
	}

	const handlePointerCancel = (event: Event) => {
		completePointerOperation(event)
	}

	const startResize = (event: React.PointerEvent, dir: Directions) => {
		const sizerElement = event.target as Element
		if (!sizerElement) {
			return
		}

		direction.current = dir
		event.preventDefault()
		event.stopPropagation()

		sizerElement.setPointerCapture(event.pointerId)
		sizerElement.addEventListener('pointerup', handlePointerUp)
		sizerElement.addEventListener('pointermove', handlePointerMove)
		sizerElement.addEventListener('pointercancel', handlePointerCancel)

		const target = props.targetElement.current

		initialLeft.current = target?.offsetLeft ?? 0
		initialTop.current = target?.offsetTop ?? 0
		initialX.current = event.clientX
		initialY.current = event.clientY

		isResizing.current = true
		initialWidth.current = target?.clientWidth ?? minSize.width
		initialHeight.current = target?.clientHeight ?? minSize.height
	}

	return (
		<>
			{props.enable?.top && (
				<div onPointerDown={(e) => startResize(e, 'top')} className={clsx(classes.sizer, classes.top)} />
			)}
			{props.enable?.right && (
				<div onPointerDown={(e) => startResize(e, 'right')} className={clsx(classes.sizer, classes.right)} />
			)}
			{props.enable?.bottom && (
				<div onPointerDown={(e) => startResize(e, 'bottom')} className={clsx(classes.sizer, classes.bottom)} />
			)}
			{props.enable?.left && (
				<div onPointerDown={(e) => startResize(e, 'left')} className={clsx(classes.sizer, classes.left)} />
			)}
			{props.enable?.corner && (
				<div onPointerDown={(e) => startResize(e, 'corner')} className={clsx(classes.sizer, classes.corner)} />
			)}
		</>
	)
}
